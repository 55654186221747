import {update_cab_header} from './Utils';


update_cab_header().then((d) => {
    document.querySelector('.header-cab-points').classList.remove('hidden')

    const emails = document.querySelectorAll('input[name="email"]')
	emails.forEach((e) => {
		e.classList.add('disabled')
		e.value = d.info.email
		e.setAttribute('disabled', 1)
		e.removeAttribute('data-required')
	})
}).catch(() => {
    document.querySelector('.header-cab-points span').textContent = 0
    document.querySelector('.header-cab-points').classList.remove('hidden')
})
