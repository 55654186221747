import ProductsDisplay from "./modules/ProductsDisplay";
import {fetchDefaultPrices} from "./Utils";

fetchDefaultPrices().then(data => {
    ProductsDisplay.display(data.products, data.promotions, data.en);
    if (!document.querySelector('#big-products > *')) {
        document.querySelector('#big-products-section').remove()
    }
})

