import countryCookieManager from "./CountryCookieManager";
import axios from "axios";
import CartManager from "./CartManager";
import Config from "./Config";
import CountryCookieManager from "./CountryCookieManager";
import Api from "./Api";
import Uuid from "./Uuid";
// import "../cdek/widjet"

export default {
    pvzWidjet: undefined,
    cdekDelivery: {
        price: null,
        data: 'cdek_door',
    },
    dhlDelivery: {
        price: null,
        data: 'dhl_door',
    },
    kladrId: null,
    // геттеры элементов и свойств
    cityInput() {
        return document.querySelector('#id-city-kladr')
    },
    streetInput() {
        return document.querySelector('#id-street-kladr')
    },
    buildingInput() {
        return document.querySelector('#id-building-kladr')
    },
    zipValue() {
        return document.querySelector('input[name="zip"]').value
    },
    flatInput() {
        return document.querySelector('input[name="flat"]')
    },
    zipInput() {
        return document.querySelector('input[name="zip"]')
    },
    currentCountry() {
        return countryCookieManager.getCountryFromCookies()
    },
    currentBasketItems() {
        return JSON.parse(Cookies.get('basket'))
    },

    // методы
    setZip(zip) {
        if (!zip)
            return;
        const zipElement = document.querySelector('input[name="zip"]')
        zipElement.value = zip;
        var event = new Event('focusout');
        zipElement.dispatchEvent(event);
    },
    setKladrId(id) {
        this.kladrId = id;
        var ai = document.querySelector('input[name="additional_info"]')
        ai.value = id;
        var event = new Event('focusout');
        ai.dispatchEvent(event);
    },
    getKladrId() {
        try {
            return document.querySelector('input[name="additional_info"]').value;
        } catch {
            return this.kladrId ? this.kladrId : ''
        }
    },
    previousDeliveryRequestData: null,
    sendInputInfo(zip = null) {
        const data = {
            uuid: Uuid.getUUID(),
            city: this.cityInput().value,
            country: this.currentCountry(),
            street: this.streetInput().value,
            building: this.buildingInput().value,
            promo: CartManager.getPromoFromCookies(),
            zip: zip ? zip : this.zipValue(),
            cart: this.currentBasketItems(),

        }
        if (JSON.stringify(data) == JSON.stringify(this.previousDeliveryRequestData))
            return
        this.previousDeliveryRequestData = data;
        axios.post(Config.apiUrl + '/delivery-price',
            data,
        )
            .then((response => {
                this.deliveryRadioRenderControl(response.data)
            }))
            .catch(e => {
                console.log(e)
            })
    },

    initSendingAddressToBack() {
        this.flatInput().addEventListener('focusout', (e) => {
            var city = this.cityInput().value
            var street = this.streetInput().value
            var building = this.buildingInput().value
            var apartment = this.flatInput().value
            if (!(city && street && building && apartment))
                return
            var region = ""
            try {
                region = JSON.parse(this.getKladrId()).region
            } catch {
                console.log("can't parse json: " + this.getKladrId())
            }
            var city_kladr_id = ""
            try {
                city_kladr_id = JSON.parse(this.getKladrId()).city_kladr_id
            } catch {
                console.log("can't parse json: " + this.getKladrId())
            }
            console.log(this.getKladrId());
            Api.sendPreOrder({
                "region": region,
                "city": city,
                "city_kladr_id": city_kladr_id,
                "street": street,
                "building": building,
                "apartment": apartment,
                "zip": this.zipValue(),
                "delivery_company": CartManager.getDeliveryValue(),
            })
        })
    },
    addInputHandlers() {
        this.cityInput().addEventListener('focus', this.inputHandler);
        this.streetInput().addEventListener('focus', this.inputHandler);
        this.cityInput().addEventListener('focusout', () => this.sendInputInfo());
        this.streetInput().addEventListener('focusout', () => this.sendInputInfo());
        this.buildingInput().addEventListener('focusout', () => this.sendInputInfo());
        this.flatInput().addEventListener('focusout', () => this.sendInputInfo());
        this.zipInput().addEventListener('focusout', () => this.sendInputInfo());
        this.zipInput().addEventListener('input', () => this.zipOnChange());
        this.initSendingAddressToBack();
    },
    zipOnChange(e) {
        console.log("zip change")
        if (this.zipValue().length == 6) {
            this.sendInputInfo();
        }
    },
    inputHandler(e) {
        const input = e.target;
        const inputError = document.querySelector('.cart__delivery-error')
        if (inputError) {
            inputError.remove()
        }
        input.classList.remove('error')
    },
    deleteDeliveryRadio() {
        const deliveryRadioWrapper = document.querySelector('.order-form__radio-buttons_delivery');
        const deliveryRadioWrapperPvz = document.querySelector('.order-form__radio-buttons_delivery_pvz');
        deliveryRadioWrapper.innerHTML = ''
        deliveryRadioWrapperPvz.innerHTML = ''
        this.clearDeliveryInfo()
    },
    clearDeliveryInfo() {
        CartManager.setDeliveryPrice(null);
        CartManager.refreshTotalCartData();
    },
    deliveryRadioRenderControl(radioData) {
        const { cdek, dhl } = radioData;
        var previousCkecked = CartManager.getDeliveryValue()
        if (!previousCkecked)
            previousCkecked = 'dhl_door'
        this.deleteDeliveryRadio()

        if (this.currentCountry() === 'Россия') {
            if (!cdek.error) {
                this.renderDeliveryRadio(cdek.price, [cdek.term_min, cdek.term_max], 'CDEK');
                this.cdekDelivery.price = cdek.price;
            } else {
                this.renderDeliveryError('CDEK', cdek.error);
            }
            // this.addDeliveryRadioHandler(previousCkecked == 'cdek_door');
            this.addDeliveryRadioHandler(false);
            if (!dhl.error) {
                this.renderDeliveryRadio(dhl.price, [dhl.term_min, dhl.term_max], 'DHL');
                this.dhlDelivery.price = dhl.price;
            } else {
                this.renderDeliveryError('DHL', dhl.error);
            }
            this.addDeliveryRadioHandler(false);
            this.renderDeliveryRadioPvz();
            // this.addDeliveryRadioHandler(previousCkecked == 'dhl_door');
            this.addDeliveryRadioHandler(false);
        } else if (this.currentCountry() === 'Беларусь' || this.currentCountry() === 'Казахстан') {
            if (!cdek.error) {
                this.renderDeliveryRadio(cdek.price, [cdek.term_min, cdek.term_max], 'CDEK');
                this.cdekDelivery.price = cdek.price;
            } else {
                this.renderDeliveryError('CDEK', cdek.error);
            }
            // this.addDeliveryRadioHandler(previousCkecked == 'cdek_door');
            this.addDeliveryRadioHandler(false);
        } else {
            this.renderDeliveryRadio(dhl.price, [dhl.term_min, dhl.term_max], 'DHL', true);
            this.dhlDelivery.price = dhl.price;
            // this.addDeliveryRadioHandler(previousCkecked == 'dhl_door');
            this.addDeliveryRadioHandler();
        }
    },
    addDeliveryRadioHandler(checked = true) {
        const deliveryRadioHandler = [...document.querySelectorAll('input[name="delivery_type"]')];

        deliveryRadioHandler.forEach((deliveryRadio, index) => {
            deliveryRadio.addEventListener('change', this.deliveryRadioHandler.bind(this));
            console.log(index)
            if (index === 0) {
                deliveryRadio.dispatchEvent(new Event('change'));
                deliveryRadio.checked = checked;
            }
        })
    },
    deliveryRadioHandler(e) {
        if (e.target.id == "deliveryPvzRadio")
            return
        if (this.pvzWidjet) {
            // this.pvzWidjet.close();
            this.pvzWidjet = undefined;
            var container = document.getElementById("forpvz-container")
            container.innerHTML = `<p>Доставка до ПВЗ CDEK</p>
                            <span>Нажмите чтобы выбрать ПВЗ, узнать сроки доставки и цену.</span>`
        }
        const deliveryData = e.target.value;
        const deliveryPrice = this[deliveryData].price;
        const deliveryValue = this[deliveryData].data;

        CartManager.setDeliveryPrice(deliveryPrice);
        console.log(deliveryValue)
        CartManager.setDeliveryValue(deliveryValue)
        CartManager.refreshTotalCartData();
    },
    pvzSelected() {
        var container = document.getElementById("forpvz-container")
        container.innerHTML = `<p>Доставка до ПВЗ CDEK</p>
        <div id="forpvz" style="height:600px;">Пожалуйста подождите...</div>`
        var onReady = () => {
            var defaultSelect = document.querySelector('div[data-delivery-type="pvz"]');

            setInterval(() => {
                try {
                    var removedSelect = document.querySelector('div[data-delivery-type="courier"]')
                    removedSelect.remove()
                } catch { }
                try {
                    var pageReloadButton = document.querySelector('button[class="CDEK-widget__delivery-type__button CDEK-widget__delivery-type__button_pvz"]')
                    pageReloadButton.remove()
                } catch { }
                try {
                    var pageReloadButton = document.querySelector('button[class="CDEK-widget__delivery-type__button "]')
                    pageReloadButton.remove()
                } catch { }
            }, 100)
            // var selectMenus = document.getElementsByClassName('CDEK-widget__delivery-type');
            // for (const selectMenu of selectMenus) {
            //     selectMenu.innerHTML = ""
            // }
        }
        var onChoose = (choise) => {
            console.log("cdek_pvz")

            fetch(Config.apiUrl + '/cdek-pvz', {
                method: 'POST',
                headers: {
                    "content-type": "Application/Json",
                },
                body: JSON.stringify({
                    uuid: Uuid.getUUID(),
                    country: countryCookieManager.getCountryFromCookies(),
                    promo: CartManager.getPromoFromCookies(),
                    pvz_id: choise.id,
                    price: choise.price,
                    tarif: choise.tarif,
                    term: choise.term,
                    currency: choise.currency,
                    city: choise.cityName,
                    city_cdek_id: choise.city,
                    delivery_method: "cdek_pvz",
                }),
            }).then(r => r.json()).then((data) => {
                var container = document.getElementById("forpvz-container")
                container.innerHTML = `<p>Доставка до ПВЗ CDEK</p>
                                <span>Доставка до пункта выдачи заказов: ${choise.term} рабочих дня, не считая дня отправки</span>
                                <strong>${data.price} ₽</strong>`
                CartManager.setDeliveryPrice(data.price);
                CartManager.setDeliveryValue("cdek_pvz");
                CartManager.refreshTotalCartData();
            })
        }
        var city = ''
        var inp = this.cityInput();
        if (inp) {
            var value = inp.value;
            if (value) {
                var splited = value.split(' ', 2);
                if (splited) {
                    city = splited[1];
                } else {
                    city = value;
                }
            }
        }
        this.pvzWidjet = new ISDEKWidjet({
            showWarns: true,
            showErrors: true,
            showLogs: true,
            hideMessages: true,
            // servicepath: 'https://widget.cdek.ru/widget/scripts/service.php', //ссылка на файл service.php на вашем сайте
            // templatepath: 'https://widget.cdek.ru/widget/scripts/template.php',
            path: Config.cdekPhpUrl + '/scripts/',
            servicepath: Config.cdekPhpUrl + '/scripts/service.php', //ссылка на файл service.php на вашем сайте
            templatepath: Config.cdekPhpUrl + '/scripts/template.php',
            choose: true,  // TODO: get and set cdek price on server side
            popup: false,
            country: CountryCookieManager.getCountryFromCookies(),
            defaultCity: city,
            cityFrom: 'Новосибирск',
            link: "forpvz",
            hidedress: true,
            hidecash: false,
            hidedelt: false,
            detailAddress: false,
            region: false,
            // apikey: '',
            goods: [{
                length: 10,  // TODO: how to know
                width: 10,
                height: 10,
                weight: 1
            }],
            onReady: onReady,
            onChoose: onChoose,
            // onChooseProfile: onChooseProfile,
            // onCalculate: onCalculate
        });
    },
    renderDeliveryRadioPvz() {
        const deliveryRadioWrapper = document.querySelector('.order-form__radio-buttons_delivery_pvz');
        const deliveryRadioElement = `<label label style = "width:100%;max-width:100%;" class="order-form__radio" >
                    <input id="deliveryPvzRadio" type="radio" name="delivery_type" data-delivery-radio value="0" >
                        <div class="order-form__radio-box"></div>
                        <div id="forpvz-container" class="order-form__radio-text" style="width:100%;max-width:100%;">
                            <p>Доставка до ПВЗ CDEK</p>
                            <span>Нажмите чтобы выбрать ПВЗ, узнать сроки доставки и цену.</span>
                        </div>
                    </label>`
        deliveryRadioWrapper.insertAdjacentHTML('afterbegin', deliveryRadioElement)
        var pvz = document.getElementById("deliveryPvzRadio")
        pvz.addEventListener("change", () => this.pvzSelected())
    },
    renderDeliveryRadio(price, terms, name, isEn = false) {
        const deliveryRadioWrapper = document.querySelector('.order-form__radio-buttons_delivery');
        const deliveryValue = name === 'CDEK' ? 'cdekDelivery' : 'dhlDelivery';
        const deliveryRadioElement = `<label label class="order-form__radio" >
                    <input type="radio" name="delivery_type" data-delivery-radio value="${deliveryValue}" >
                        <div class="order-form__radio-box"></div>
                        <div class="order-form__radio-text">
                            <p>Доставка курьером ${name}</p>
                            <span>Доставка до вашего адреса: ${terms[0]}-${terms[1]} рабочих дня, не считая дня отправки</span>
                            <strong>${price ? price : "Бесплатно"} ${!price ? '' : isEn ? '$' : '₽'}</strong>
                        </div>
                    </label>`
        deliveryRadioWrapper.insertAdjacentHTML('afterbegin', deliveryRadioElement)
    },
    renderDeliveryError(name, error, isEn = false) {
        const deliveryRadioWrapper = document.querySelector('.order-form__radio-buttons_delivery');
        const deliveryValue = name === 'CDEK' ? 'cdekDelivery' : 'dhlDelivery';
        const deliveryRadioElement = `<label label class="order-form__radio" >
                    <input disabled="true" type="radio" name="delivery_type" data-delivery-radio value="${deliveryValue}" >
                        <div class="order-form__radio-box"></div>
                        <div class="order-form__radio-text">
                            <p>Доставка курьером ${name}</p>
                            <span>${error}</span>
                            <strong>Error</strong>
                        </div>
                    </label>`
        deliveryRadioWrapper.insertAdjacentHTML('afterbegin', deliveryRadioElement)
    },

    addCountryHandler() {
        document.addEventListener('locale-change', () => {
            this.deleteDeliveryRadio()
            this.reRenderDeliveryInputs();
            this.initInputsByLocale();
            console.log(`locale changed to ${CountryCookieManager.getCountryFromCookies()}`)
        })
    },
    initInputsByLocale() {
        if (this.currentCountry() === "Россия") {
            this.initRusInputs();
            return;
        }
        if (this.currentCountry() === "Беларусь") {
            this.initRusInputs();
            this.initBelInputs();
            return;
        }
        if (this.currentCountry() === "Казахстан") {
            this.initRusInputs();
            this.initKazInputs();
            return;
        }

        this.initOtherInputs();
    },
    initRusInputs() {
        // код с прошлого сайта

        //$.kladr.url = 'https://kladr-api.com/api.php';
        $.kladr.url = Config.apiUrl + '/kladr-emulation';
        $.kladr.setDefault.call(this, {
            parentInput: '.order-form',
            verify: true,
            token: '',
            labelFormat: (obj, query) => {
                if (obj.contentType !== 'building') {
                    this.deleteDeliveryRadio()
                }

                var label = '';

                var name = obj.name.toLowerCase();
                query = query.name.toLowerCase();

                var start = name.indexOf(query);
                start = start > 0 ? start : 0;

                if (obj.typeShort) {
                    label += obj.typeShort + '. ';
                }

                if (query.length < obj.name.length) {
                    label += obj.name.substr(0, start);
                    label += '<strong>' + obj.name.substr(start, query.length) + '</strong>';
                    label += obj.name.substr(start + query.length, obj.name.length - query.length - start);
                } else {
                    label += '<strong>' + obj.name + '</strong>';
                }

                if (obj.parents) {
                    for (var k = obj.parents.length - 1; k > -1; k--) {
                        var parent = obj.parents[k];
                        if (parent.name) {
                            if (label) label += '<small>, </small>';
                            label += '<small>' + parent.name + ' ' + parent.typeShort + '.</small>';

                        }
                    }
                }

                return label;

            },
            select: (data) => {
                let json = JSON.parse(JSON.stringify(data, null, 4));

                if (json["contentType"] == 'city') {
                    $('[name="cityType"]').val(json['type']);
                }
                if (json["contentType"] == 'street') {

                    $('[name="streetType"]').val(json['type']);
                }

                if (json["contentType"] == 'building') {

                    $('#zip').val(json['zip']);
                }
            }
        });



        $('#id-city-kladr').kladr({
            token: '',
            key: '',
            withParents: true,
            type: $.kladr.type.city,
            parentInput: $('.order-form'),
            change: (obj) => {
                this.setKladrId(obj.id)
                this.deleteDeliveryRadio()
                // checkAddress(obj.zip);
            },
        });
        document.getElementById('id-city-kladr').addEventListener("focusout", (e) => {
            e.target.setAttribute("data-kladr-id", this.getKladrId())
        })


        $('#id-street-kladr').kladr({
            token: '',
            key: '',
            type: $.kladr.type.street,
            parentInput: $('.order-form'),
            change: (obj) => {
                if (obj) {
                    console.log(obj)
                    this.sendInputInfo(obj.zip);
                    this.setZip(obj.zip)
                    this.setKladrId(obj.id)
                }
            }
        });
        document.getElementById('id-street-kladr').addEventListener("focusout", (e) => {
            console.log(this.getKladrId())
            e.target.setAttribute("data-kladr-id", this.getKladrId())
        })


        $('#id-building-kladr').kladr({
            token: '',
            key: '',
            type: $.kladr.type.building,
            parentInput: $('.order-form'),
            change: (obj) => {
                if (obj) {
                    console.log(obj)
                    this.sendInputInfo(obj.zip);
                    this.setZip(obj.zip)
                    this.setKladrId(obj.id)
                }
            }
        });
    },
    initBelInputs() {
        // $( "#id-city-kladr" ).autocomplete({
        //     source: 'new_php/get_city_bu.php',
        //     minLength: 1,
        // });
        $('#id-city-kladr').on("change", (e) => {
            e.stopPropagation(), e.preventDefault();
            this.deleteDeliveryRadio();
            this.sendInputInfo();
        });
    },
    initKazInputs() {
        // $( "#id-city-kladr" ).autocomplete({
        //     source: 'new_php/get_city_kaz.php',
        //     minLength: 1,
        // });
        $('#id-city-kladr').on("change", (e) => {
            e.stopPropagation(), e.preventDefault();
            this.deleteDeliveryRadio();
            this.sendInputInfo();
        });
    },
    initOtherInputs() {
        $('#id-city-kladr').on("change", (e) => {
            e.stopPropagation(), e.preventDefault();
            this.deleteDeliveryRadio()
            this.sendInputInfo();
        });
    },
    reRenderDeliveryInputs() {
        const deliveryInputsWrapper = document.querySelector('.order-form__delivery-inputs');

        const inputs = `
                        <label label class="order-form__label order-form__label_half" >
                                <input data-save-id="city" value="" id="id-city-kladr" name="city" data-order  placeholder="Город*" type="text" class="order-form__input _req order-form__input_delivery" data-text data-required="" autocomplete="off" >
                            </label>
                            <label class="order-form__label order-form__label_half">
                                <input data-save-id="street" value="" id="id-street-kladr" name="street" data-order  data-text placeholder="Улица*" type="text" class="order-form__input _req order-form__input_delivery" data-required="" autocomplete="off">
                            </label>
                            <label class="order-form__label order-form__label_half">
                                <input data-save-id="building" value="" id="id-building-kladr" name="build" data-order  placeholder="Дом" type="text" class="order-form__input _req order-form__input_delivery" autocomplete="off">
                            </label>
                            <label class="order-form__label order-form__label_half">
                                <input data-save-id="flat" id="id-apartment-field" type="text" class="order-form__input order-form__input_delivery" data-order  placeholder="Квартира" name="flat" data-flat>
                            </label>
                            <label class="order-form__label">
                                    <input data-save-id="zip" type="number" class="order-form__input" placeholder="Индекс" name="zip"
                                        data-order data-text>
                            </label>
                            <input hidden data-save-id="additional_info" type="text" name="additional_info" data-order>
        `
        deliveryInputsWrapper.innerHTML = '';
        deliveryInputsWrapper.innerHTML = inputs;
        this.addInputHandlers()
    },
    init() {
        this.reRenderDeliveryInputs();
        this.addCountryHandler();
        this.initInputsByLocale();
    }
}

