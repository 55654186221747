import CartManager from "./modules/CartManager";


document.addEventListener('DOMContentLoaded', async function () {
    const basketPopup = document.querySelector('.basket-popup');
    const cartPage = document.querySelector('.cart');

    //инициализируем менеджер корзины
    await CartManager.init()

    function setProductsQuantityInCookies(id, quantity) {
        const productId = parseInt(id);
        const basketItems = [...CartManager.getBasketItemsFromCookie()];
        const currentBasketItem = basketItems.find((basketItem) => basketItem.id == productId);
        currentBasketItem.quantity = quantity;

        //console.log(basketItems);
        Cookies.set('basket', basketItems);
    }

    if (basketPopup) {
        document.addEventListener('locale-change', () => {
            initBasketItems();
        })

        initBasketPopup();
        addCartPopupEventListeners();
        addToCartEventListener();

        function addCartPopupEventListeners() {
            const productElems = basketPopup.querySelectorAll('.items-list__item');

            productElems.forEach(productElem => {
                const plus = productElem.querySelector('.item-counter-plus');
                const minus = productElem.querySelector('.item-counter-minus');
                const input = productElem.querySelector('input');

                const deleteBtn = productElem.querySelector('.remove-item');

                //при вызове функции обновляем вложенные обработчики событий
                plus.removeEventListener('click', refreshCartPopup);
                minus.removeEventListener('click', refreshCartPopup);
                input.removeEventListener('blur', refreshCartPopup);
                deleteBtn.removeEventListener('click', deleteCartPopupItem);

                plus.addEventListener('click', refreshCartPopup);
                minus.addEventListener('click', refreshCartPopup);
                input.addEventListener('blur', refreshCartPopup);
                deleteBtn.addEventListener('click', deleteCartPopupItem);
            })
        }

        function addToCartEventListener() {

            const items = document.querySelectorAll('.js-card-order');
            items.forEach(item => {
                item.addEventListener('click', () => {
                    const itemId = item.dataset.id
                    try {
                        document.querySelector(`#sovp${itemId}`).className = "js-side-ov-panel b-side-ov-panel _opened"
                    } catch { }
                    var basket = CartManager.getBasketItemsFromCookie();
                    var basketItem = basket.find(item => item.id == itemId);
                    if (!basketItem) {
                        console.log("no basket item for some reason?!?!?!?!?");
                    }
                    basketItem.quantity = parseInt(basketItem.quantity) + 1;
                    setProductsQuantityInCookies(itemId, basketItem.quantity)
                    const cartItems = CartManager.getCurrentProductList()
                    const currentItem = cartItems.find(item => item.product_id == +itemId);

                    updateCartPopupView(currentItem);
                    updateCartPopupSum(CartManager.getTotalPrice())

                    const addToCart = new CustomEvent('add-to-cart', {
                        detail: {
                            item: currentItem,
                        },
                        bubbles: true
                    });
                    document.dispatchEvent(addToCart);
                    updateItemsQtyInCart(CartManager.getTotalQuantity());
                })
            })

        }
        addEventListener("add-to-cart-event-listeners", addToCartEventListener);

        function updateCartPopupView(currentItem) {
            const cartItemsList = document.querySelector('.items-list');
            const cartItems = [...cartItemsList.querySelectorAll('.items-list__name')];
            //check if item is already in cart
            const itemInCart = cartItems.find(cartItem => +cartItem.dataset.productId === +currentItem.product_id)
            if (itemInCart) {
                const itemElem = itemInCart.closest('.items-list__item');
                updatePopupItemData(itemElem, currentItem);
            } else {
                const newItem = renderPopupItem(currentItem);
                cartItemsList.append(newItem);
                refreshEventListenersInPopup();
            }
        }

        function refreshCartPopup() {

            const itemElem = this.closest('.items-list__item');

            const input = itemElem.querySelector('input');
            const operand = this.dataset.id;
            const isInputChange = changeInputValue(input, operand);

            if (isInputChange) {

                const itemName = itemElem.querySelector('.items-list__name');
                const itemId = itemName.dataset.productId;

                setProductsQuantityInCookies(itemId, input.value);

                const cartItems = CartManager.getCurrentProductList();
                const currentItem = cartItems.find(item => item.product_id === +itemId);

                updatePopupItemData(itemElem, currentItem);
                updateItemsQtyInCart(CartManager.getTotalQuantity());
                updateCartPopupSum(CartManager.getTotalPrice());
            }
        }

        function deleteCartPopupItem() {
            const itemsList = document.querySelector('.items-list');
            const item = this.closest('.items-list__item');
            const isLastItem = itemsList.children.length === 1;

            if (isLastItem) {
                clearPopupCart(item, itemsList);
            } else {
                sendDeleteRequest(item, itemsList);
            }

        }

        function cartIsEmpty() {
            return !CartManager.getTotalQuantity()
        }

        function clearPopupCart(itemElem, itemsList) {

            sendDeleteRequest(itemElem, itemsList);
            closeBasketPopup();
            setTimeout(() => itemsList.removeChild(itemElem), 500);

        }

        function sendDeleteRequest(itemElem, itemsList) {
            const itemName = itemElem.querySelector('.items-list__name');
            const product_id = itemName.dataset.productId


            setProductsQuantityInCookies(product_id, 0);
            itemElem.classList.add('delete');

            setTimeout(() => {
                itemsList.removeChild(itemElem);

                refreshEventListenersInPopup();
                updateItemsQtyInCart(CartManager.getTotalQuantity());
                updateCartPopupSum(CartManager.getTotalPrice());
            }, 500);



        }

        //helpers

        function renderPopupItem(item) {
            const itemEl = document.createElement('li');
            itemEl.classList.add('items-list__item');
	    if (!item.show_in_cart)
		itemEl.classList.add('hidden')
            itemEl.innerHTML = `
                                    <div class="items-list__inner">
                                         <a href="${item.details_url}" class="items-list__link">
                                            
                                            <img loading="lazy" src="${item.img}" alt="" class="items-list__img">
                                            <span class="items-list__info">
                                                    <p class="items-list__price">
                                                        ${CartManager.formatPrice(item.quantity * item.price[CartManager.getPriceValue()])}
                                                    </p>
                                                    <p class="items-list__name" data-product-id="${item.product_id}"> ${item.name} </p>
                                            </span>
                                        </a>
                                        <span class="item-counter items-list__counter">
                                            <button class="item-counter__btn item-counter__btn_left item-counter-minus minus" data-id="minus"></button>
                                            <label class="item-counter__label">
                                                <input type="number" class="item-counter__input" value="${item.quantity}">
                                            </label>
                                             <button class="item-counter__btn item-counter__btn_right item-counter-plus plus" data-id="plus"></button>
                                        </span>
                                    </div>            
                                <button class="items-list__remove remove-item">
                                    <svg class="items-list__remove-icon" width="18" height="18">
                                        <use xlink:href="#cross-icon"></use>
                                    </svg>
                                </button>`
            return itemEl;
        }

        function updatePopupItemData(itemElem, currentItem) {
            const itemQtyElem = itemElem.querySelector('.item-counter__input');
            const itemPriceElem = itemElem.querySelector('.items-list__price');
            const itemPrice = currentItem.price[CartManager.getPriceValue()] * currentItem.quantity;
            const itemQty = currentItem.quantity

            itemQtyElem.value = itemQty;
            itemQtyElem.setAttribute('value', itemQty);
            itemPriceElem.innerHTML = CartManager.formatPrice(itemPrice);

            return
        }

        function refreshEventListenersInPopup() {
            addCartPopupEventListeners();
        }

        function updateItemsQtyInCart(amount) {
            const cartAmountElems = document.querySelectorAll('.js-product-cart__quantity');
            cartAmountElems.forEach(elem => elem.textContent = amount);
        }

        function closeBasketPopup() {
            basketPopup.classList.remove('opened');
        }

        function openBasketPopup() {
            if (!cartIsEmpty()) {
                basketPopup.classList.add('opened')
            }
        }

        function initBasketItems() {
            const currentProductList = CartManager.getCurrentProductList();
            if (currentProductList.length !== 0) {
                currentProductList.forEach((currentProductItem) => {
                    updateCartPopupView(currentProductItem)
                })
            }
        }

        function initBasketPopup() {

            initBasketItems()
            updateItemsQtyInCart(CartManager.getTotalQuantity())
            if (!cartPage) {
                updateCartPopupSum(CartManager.getTotalPrice())

                const basketIcon = document.querySelector('.js-product-cart');
                const basketCloseBtn = document.querySelector('.basket-popup__close');

                //open popup on mouseover
                basketIcon.addEventListener('mouseover', () => {
                    const watchCursor = setTimeout(openBasketPopup, 500);
                    basketIcon.addEventListener('mouseover', () => clearTimeout(watchCursor))
                })
                //close popup on mouseleave && !mouseover on popup
                basketIcon.addEventListener('mouseleave', () => {
                    const watchCursor = setTimeout(closeBasketPopup, 500);
                    basketPopup.addEventListener('mouseover', () => clearTimeout(watchCursor))
                })
                //close popup on  popup mouseleave && !mouseover on icon
                basketPopup.addEventListener('mouseleave', () => {
                    const watchCursor = setTimeout(closeBasketPopup, 500);
                    basketIcon.addEventListener('mouseover', () => clearTimeout(watchCursor))
                })

                basketCloseBtn.addEventListener('click', closeBasketPopup)

            }
        }

        if (cartPage) {

            document.addEventListener('locale-change', () => {
                refreshCartsItems();
                CartManager.refreshTotalCartData();
                CartManager.changePaymentMethod();
            })
            document.addEventListener('promo', () => {
                refreshCartsItems()
            })
            initCart()

            function initCart() {
                CartManager.initPromo()
                changeProductQtyListeners()
                addCartEventListeners()
                initCartItems();
                CartManager.refreshTotalCartData();
            }

            function initCartItems() {
                const currentProductList = CartManager.getCurrentProductList();
                if (currentProductList.length !== 0) {
                    currentProductList.forEach((currentProductItem) => {
                        addNewCartItem(currentProductItem)
                    })
                }
            }


            function refreshCartsItems() {
                const cartItemsElements = [...document.querySelectorAll('.cart-list__item')];
                const cartItems = CartManager.getCurrentProductList();
                cartItemsElements.forEach((cartItemElement) => {
                    const cartItemElementId = cartItemElement.querySelector('.cart-list__name').dataset.productId
                    const currentItem = cartItems.find(item => item.product_id === +cartItemElementId);

                    refreshCartItem(cartItemElement, currentItem)
                })

            }

            function addCartEventListeners() {
                document.addEventListener('add-to-cart', (e) => {
                    const addedItem = e.detail;
                    const id = addedItem.item.product_id
                    const cartNameElement = [...document.querySelectorAll('.cart-list__name')].find((cartNameElement) => cartNameElement.dataset.productId == id)

                    if (cartNameElement) {
                        const cartElement = cartNameElement.closest('.cart-list__item')
                        refreshCartItem(cartElement, addedItem.item)
                    }
                    if (!cartNameElement) {
                        addNewCartItem(addedItem.item);
                    }
                    if (e._callback)
                        e._callback();
                    CartManager.refreshTotalCartData();

                })
            }

            function changeProductQtyListeners() {
                const productElems = cartPage.querySelectorAll('.cart-list__item');
                productElems.forEach(productElem => {
                    const plus = productElem.querySelector('.plus');
                    const minus = productElem.querySelector('.minus');
                    const input = productElem.querySelector('input');
                    const deleteBtn = productElem.querySelector('.remove-item');

                    //при вызове функции обновляем вложенные обработчики событий
                    plus.removeEventListener('click', refreshCart);
                    minus.removeEventListener('click', refreshCart);
                    input.removeEventListener('blur', refreshCart);
                    deleteBtn.removeEventListener('click', deleteCartItem);

                    plus.addEventListener('click', refreshCart);
                    minus.addEventListener('click', refreshCart);
                    input.addEventListener('blur', refreshCart);
                    deleteBtn.addEventListener('click', deleteCartItem);
                })
            }

            function refreshCart() {
                const itemElem = this.closest('.cart-list__item');
                const input = itemElem.querySelector('input');

                const operand = this.dataset.id;
                const isInputChange = changeInputValue(input, operand);

                if (isInputChange) {

                    const itemName = itemElem.querySelector('.cart-list__name');
                    const itemId = itemName.dataset.productId;

                    setProductsQuantityInCookies(itemId, input.value)

                    const cartItems = CartManager.getCurrentProductList()
                    const currentItem = cartItems.find(item => item.product_id === +itemId);

                    CartManager.refreshTotalCartData();
                    refreshCartItem(itemElem, currentItem);
                    updateItemsQtyInCart(CartManager.getTotalQuantity());
                    updateCartPopupSum(CartManager.getTotalPrice());
                }

            }

            function deleteCartItem() {

                const itemsList = document.querySelector('.cart-list');
                const item = this.closest('.cart-list__item');
                const isLastItem = itemsList.children.length === 1;

                if (isLastItem) {
                    deleteItem(item, itemsList, true);
                } else {
                    deleteItem(item, itemsList);
                }
            }
            //helpers

            function renderEmptyCartMessage() {
                cartPage.classList.add('empty');

                const message = `<div class="cart-empty">
                                    <h1 class="cart-empty__title">Ваша корзина пуста</h1>
                                    <p class="cart-empty__text">Выберите интересующий товар и нажмите кнопку «В корзину»</p>
                                    <a href="/" class="cart-empty__link button">На главную</a>
                                </div>`

                const cartContent = document.querySelector('.cart__content');
                cartContent.innerHTML = message;
            }

            function addNewCartItem(itemData) {
                const cartItemsList = document.querySelector('.cart-list');
                if (cartItemsList) {
                    const cartItems = [...cartItemsList.querySelectorAll('.cart-list__name')];
                    //check if item is already in cart
                    const itemInCart = cartItems.some(cartItem => +cartItem.dataset.productId === +itemData.product_id);
                    if (!itemInCart) {
                        const newItem = renderCartItem(itemData);
                        cartItemsList.append(newItem);
                        refreshCartEventListeners()
                    }
                }

            }

            function deleteItem(itemElem, itemsList, isLastItem = false) {

                const itemName = itemElem.querySelector('.cart-list__name');
                const product_id = itemName.dataset.productId

                itemElem.classList.add('delete');

                setTimeout(() => {
                    itemsList.removeChild(itemElem);
                    setProductsQuantityInCookies(product_id, 0)
                    CartManager.refreshTotalCartData()
                    refreshCartEventListeners();
                    updateItemsQtyInCart(CartManager.getTotalQuantity());

                }, 500);

                if (isLastItem) {
                    renderEmptyCartMessage()
                }
            }


            function refreshCartItem(itemElem, itemData) {
                const itemTotalPrice = itemData.quantity * itemData.price[CartManager.getPriceValue()];
                const itemPrice = itemData.price[CartManager.getPriceValue()];
                const itemSumElem = itemElem.querySelector('.cart-list__total-price');
                const itemCountElem = itemElem.querySelector('.item-counter__input')
                const itemPriceElem = itemElem.querySelector('.cart-list__price')

                itemSumElem.innerHTML = CartManager.formatPrice(itemTotalPrice)
                itemPriceElem.innerHTML = CartManager.formatPrice(itemPrice)
                itemCountElem.value = itemData.quantity
                itemCountElem.setAttribute('value', itemData.quantity)
                return
            }

            function refreshCartEventListeners() {
                changeProductQtyListeners();
            }

            function renderCartItem(itemData) {
                const newItem = document.createElement('li');
                newItem.classList.add('cart-list__item');
                newItem.innerHTML = ` <div  class="cart-list__link">
                                        <img loading="lazy" src="${itemData.img}" alt="" class="cart-list__img">
                                        <p class="cart-list__price">
                                            ${CartManager.formatPrice(itemData.price[CartManager.getPriceValue()])}
                                        </p>
                                        <p class="cart-list__name" data-product-id="${itemData.product_id}"> ${itemData.name} </p>
                                        <span class="item-counter cart-list__item-counter">
                                            <button class="item-counter__btn item-counter__btn_left item-counter-minus minus" data-id="minus"></button>
                                            <label class="item-counter__label">
                                                <input type="number" class="item-counter__input" value="${itemData.quantity}">
                                            </label>
                                            <button class="item-counter__btn item-counter__btn_right item-counter-plus plus" data-id="plus"></button>
                                        </span>
                                        <p class="cart-list__total-price">
                                            ${CartManager.formatPrice(itemData.quantity * itemData.price[CartManager.getPriceValue()])}
                                        </p>
                                    </div>
                                    <button class="cart-list__remove remove-item">
                                        <svg class="cart-list__remove-icon" width="18" height="18">
                                            <use xlink:href="#cross-icon"></use>
                                        </svg>
                                    </button>`

                return newItem;
            }



        }

        //global helpers

        function updateCartPopupSum(sum) {
            const cartSum = document.querySelector('.basket-popup__total');
            return cartSum.innerHTML = CartManager.formatPrice(sum);
        }

        function changeInputValue(input, operand) {
            let isInputChanged = false;

            if (operand === 'plus') {
                input.value = +input.value + 1;
                isInputChanged = true;
            } else if (operand === 'minus' && input.value > 1) {
                input.value = +input.value - 1;
                isInputChanged = true;
            } else if (event.type === 'blur') {
                input.value = input.value === '0' ? '1' : input.value;
                isInputChanged = true;
            }

            return isInputChanged
        }

    }

})

