const isPage = (pages) => {
  const pageName = window.location.pathname;
  for (let page of pages) {
    if (pageName.endsWith(page)) return true;
  }
  return false;
};

const pageContains = (pages) => {
  const pagePath = window.location.pathname;
  for (let page of pages) {
    if (pagePath.includes(page)) return true;
  }
  return false;
};

if (pageContains(["/instruct/"])) {
  document.querySelector('link[href="/css/index.css"]').remove();
}

require("./js/utm");

if (
  isPage([
    "/",
    "index.html",
    "website-profit.html",
    "eighteen-reasons.html",
    "chernaya-pyatnica.html",
  ])
) {
  require("./css/normalize.css");
  require("./css/main.css");
  require("./scss/main.scss");

  require("./js/preorder_popup");
  require("./js/new-js");
  require("./js/cart");
} else if (
  isPage(["zeolite.html", "z-celd.html", "main2.html", "main3.html", "main4.html"]) &&
  !pageContains(["/instruct/"])
) {
  require("./js/products");
  require("./js/content");
  require("./js/preorder_popup");
  require("./js/landing_promotions");
} else if (isPage(["/old_cart.html"])) {
  require("./js/cdek_pvzs");
  require("./js/old_cart");
} else if (isPage(["research.html"])) {
  require("./js/articles");
  require("./js/products");
  require("./js/content");
} else if (isPage(["create_rsb.html"])) {
  require("./js/create_rsb");
} else if (
  isPage(["face_mask.html", "hair_mask.html", "toothpaste.html", "instruction_urls.html"])
) {
  require("./css/normalize.css");
  require("./css/main.css");
  require("./scss/main.scss");

  require("./js/new-js");
  require("./js/cart");
} else if (isPage(["cooperation.html"])) {
  require("./css/normalize.css");
  require("./css/main.css");
  require("./scss/main.scss");

  require("./js/new-js");
  require("./js/cart");
  require("./js/cooperation");
} else if (isPage(["landing_sales.html"])) {
  require("./js/dubai_landing");
} else if (pageContains(["/contacts/"])) {
  require("./js/contacts");
} else if (isPage(["/reviews.html", "/reviews_inner.html"])) {
  require("./js/creviews");
} else if (isPage(["/reviews.html", "/reviews_inner.html", "landing_problems.html"])) {
  require("./js/preorder_popup");
} else if (isPage(["landing_onlinecourse.html", "landing_onlinecourse_ordered.html"])) {
  require("./js/landing_onlinecourse");
} else if (isPage(["guides.html"]) || pageContains(["/guide/"])) {
  require("./js/guides.js");
  require("./js/new-js");
  require("./js/cart");
} else if (isPage(["thanks.html"])) {
  require("./js/afterbye");
} else if (isPage(["order.html"])) {
  require("./js/order");
} else if (!isPage(["order.html"])) {
  require("./js/products");
  require("./js/content");
}

// start new
if (isPage(["landing-slim-fit.html"])) {
  require("./js/landing-slim-fit");
}
// start end

if (
  !isPage([
    "thanks.html",
    "create_rsb.html",
    "cooperation.html",
    "landing_sales.html",
    "/reviews.html",
    "/reviews_inner.html",
    "landing_onlinecourse.html",
    "landing_onlinecourse_ordered.html",
    "landing-slim-fit.html",
    "order.html",
  ]) &&
  !pageContains(["/contacts/"])
) {
  require("./js/cms");
}

if (
  !isPage(["thanks.html", "create_rsb.html", "/reviews.html", "/reviews_inner.html"]) &&
  !pageContains(["/contacts/", "order.html"])
) {
  require("./js/reviews");
}

if (
  isPage([
    "zeolite.html",
    "z-celd.html",
    "main2.html",
    "main3.html",
    "main4.html",
    "landing_problems.html",
    "face_mask.html",
    "hair_mask.html",
  ])
) {
  require("./js/add_product_popups");
}

if (
  isPage([
    "/",
    "index.html",
    "cooperation.html",
    "face_mask.html",
    "hair_mask.html",
    "main2.html",
    "main3.html",
    "main4.html",
    "page01.html",
    "page02.html",
    "page03.html",
    "page04.html",
    "page-2-1.html",
    "page-2-2.html",
    "page-2-3.html",
    "page-2-4.html",
    "page-delivery.html",
    "politic.html",
    "research.html",
    "zeolite.html",
    "z-celd.html",
    "guides.html",
    "old_cart.html",
  ])
) {
  require("./js/contacts_external");
}

if (
  !isPage([
    "thanks.html",
    "create_rsb.html",
    "old_cart.html",
    "instruction_urls.html",
    "order.html",
  ])
) {
  require("./js/registrationPopup");
}

if (isPage(["instruction_urls.html"])) {
  require("./js/instruction_urls");
}

if (isPage(["zeolite.html", "z-celd.html"])) {
  require("./js/zeolite_big_products");
}
