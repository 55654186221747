import countryCookieManager from "./CountryCookieManager";
import ProductsDisplay from "./ProductsDisplay";
import deliveryInputsManager from "./deliveryInputsManager";
import Config from "./Config";
import {fetchDefaultPrices} from "../Utils";

export default {
    deliveryPrice: null,
    deliveryValue: null,
    allProductsList: [],
    currency: {
        en: '$',
        ru: '₽'
    },
    isPromoValid: false,
    discount: null,
    setDeliveryPrice(deliveryPrice) {
        this.deliveryPrice = deliveryPrice
    },
    setDeliveryValue(deliveryValue) {
        this.deliveryValue = deliveryValue
    },
    getDeliveryValue() {
        return this.deliveryValue ? this.deliveryValue : ''
    },
    isCDEK() {
        const regEx = /sdek/g;
        const regExTest = regEx.test(this.deliveryValue);
        return regExTest ? 1 : 0
    },
    getTotalQuantity() {
        const currentProductList = [...this.getCurrentProductList()];
        const totalQuantity = currentProductList.reduce((accumulator, currentProductItem) => {
            return accumulator + currentProductItem.quantity;

        }, 0);
        return totalQuantity;
    },
    getTotalPrice() {
        const currentProductList = [...this.getCurrentProductList()];
        const totalPrice = currentProductList.reduce((accumulator, currentProductItem) => {
            return accumulator + currentProductItem.quantity * currentProductItem.price[this.getPriceValue()];
        }, 0);

        return parseInt(totalPrice);
    },
    getSum() {
        const currentProductList = [...this.getCurrentProductList()];
        const totalPrice = currentProductList.reduce((accumulator, currentProductItem) => {
            return accumulator + currentProductItem.quantity * currentProductItem.price[this.getCurrentLocale()];
        }, 0);

        return parseInt(totalPrice);
    },
    getDiscountPrice() {
        if (this.isPromoValid) {
            return this.getSum() - this.getTotalPrice();
        }
        return 0
    },
    getTotal() {
        return this.getTotalPrice() + this.getDeliveryPrice();
    },

    getPromoFromCookies() {
        return Cookies.get('promo') ? Cookies.get('promo') : ''
    },
    _setPromoInCookies(promo) {
        Cookies.set('promo', promo);
    },
    _promoHandler(e) {
        const promoButton = e.target;
        const promo = promoButton.previousElementSibling.value;
        if (!promo)
            return

        this.removePromoActiveClass();
        this.removePromoErrorClass();
        this._receiveProducts(promo)
            .then((data) => {
                this.isPromoValid = data.isPromoValid;
                const promoEvent = new Event('promo')
                document.dispatchEvent(promoEvent)
                if (this.isPromoValid) {
                    this.addPromoActiveClass()
                    this._setPromoInCookies(promo);
                } else {
                    this.addPromoErrorClass()
                    this._setPromoInCookies('');
                }
                this.refreshTotalCartData();
                deliveryInputsManager.sendInputInfo();
            })

    },
    _addPromoHandler() {
        const promoButton = document.querySelector('.cart__promo-button');
        promoButton.addEventListener('click', this._promoHandler.bind(this));

        document.addEventListener('locale-change', () => {
            promoButton.dispatchEvent(new Event('click'))

        })
    },
    getDeliveryPrice() {
        return this.deliveryPrice ? parseInt(this.deliveryPrice) : 0
    },
    getPriceValue() {
        if (this.isPromoValid) {
            return 'promo'
        }
        // do not change to 'return this.getCurrentLocale()', or u are not a good programmer
        if (this.getCurrentLocale() === 'en') {
            return 'en'
        }
        if (this.getCurrentLocale() === 'ru') {
            return 'ru'
        }
    },
    _receiveProducts(promo = null) {
        return new Promise((resolve, reject) => {
            fetchDefaultPrices().then(response => {
                const data = response;
                const { products: allProductsList, valid_promo: isPromoValid } = data;
                this.allProductsList = allProductsList;
                ProductsDisplay.display(allProductsList, data.promotions, data.en);
                var basket = this.getBasketItemsFromCookie();
                var in_basket_ids = basket.map(x => x.id);
                var allProductsIds = allProductsList.map(x => x.product_id);
                for (var i = basket.length - 1; i >= 0; i--) {
                    if (allProductsIds.indexOf(basket[i].id) != -1)
                        continue
                    basket.splice(i, 1)
                }
                for (const product of allProductsList) {
                    if (in_basket_ids.indexOf(product.product_id) != -1)
                        continue
                    basket.push({ "id": product.product_id, "quantity": 0 });
                }
                Cookies.set('basket', JSON.stringify(basket));
                this.isPromoValid = isPromoValid;
                resolve({ isPromoValid, allProductsList })
            })
            .catch(e => {
                reject(e);
                console.log(e);
            })
        })
    },
    getCurrentLocale() {
        const country = countryCookieManager.getCountryFromCookies();

        if (country === 'Россия' || country === 'Беларусь' || country === 'Казахстан') {
            return 'ru'
        }

        return 'en'
    },
    getCurrentProductList() {
        const allProducts = [...this.allProductsList];
        const currentProductItemsFromCookie = this.getBasketItemsFromCookie();
        const currentProductList = [];

        currentProductItemsFromCookie.forEach((currentProductItemFromCookie) => {
            const currentProductItem = allProducts.find(product => product.product_id == currentProductItemFromCookie.id && currentProductItemFromCookie.quantity > 0)
            if (currentProductItem !== undefined && currentProductItem) {
                currentProductItem.quantity = parseInt(currentProductItemFromCookie.quantity);
                currentProductList.push(currentProductItem);
            }
        })

        return currentProductList;
    },
    getBasketItemsFromCookie() {
        var cart = Cookies.get('basket')
        if (!cart)
            cart = '[]'
        return JSON.parse(cart)
    },
    changePaymentMethod() {
        const paymentRadioButtons = document.querySelectorAll('input[name="pay_method"]');
        if (paymentRadioButtons.length) {
            if (countryCookieManager.getCountryFromCookies() === 'Россия') {
                paymentRadioButtons[1].parentNode.classList.remove('visually-hidden')
                paymentRadioButtons[0].value = 'cfi_payment_card'
            } else {
                paymentRadioButtons[1].parentNode.classList.add('visually-hidden')
                paymentRadioButtons[0].value = 'cfi_apple_google'
            }
        } else {
            return
        }

    },
    refreshTotalCartData() {
        const cartSum = document.querySelector('.cart-sum');
        const cartTotalSum = document.querySelector('.cart-sum-total');
        const cartDiscount = document.querySelector('.cart-discount');
        const deliverySum = document.querySelector('.cart-delivery')

        cartSum.innerHTML = this.formatPrice(this.getSum());
        cartTotalSum.innerHTML = this.formatPrice(this.getTotal());
        cartDiscount.innerHTML = this.formatPrice(this.getDiscountPrice());
        if (deliverySum) {
            deliverySum.innerHTML = this.deliveryPrice === 0 ? 'Бесплатно' : this.formatPrice(this.getDeliveryPrice())
        }
    },
    formatPrice(price) {
        return new Intl.NumberFormat('ru-RU').format(price) + ' <span>' + this.currency[this.getCurrentLocale()] + '</span>'
    },
    addPromoActiveClass() {
        const promoElement = document.querySelector('.cart__promo-field');
        promoElement.classList.add('activated');
    },
    removePromoActiveClass() {
        const promoElement = document.querySelector('.cart__promo-field');
        promoElement.classList.remove('activated');
    },
    addPromoErrorClass() {
        const promoElement = document.querySelector('.cart__promo-field');
        promoElement.classList.add('error');
    },
    removePromoErrorClass() {
        const promoElement = document.querySelector('.cart__promo-field');
        promoElement.classList.remove('error');
    },
    renderPromo() {
        const promoElement = document.querySelector('.cart__promo-field');
        const promoInput = promoElement.querySelector('input');
        this.addPromoActiveClass()

        if (!promoInput.value) {
            promoInput.value = this.getPromoFromCookies()
        }
    },
    async init() {
        await this._receiveProducts(this.getPromoFromCookies());
        this.changePaymentMethod()
    },
    initPromo() {
        this._addPromoHandler()
        if (this.getPromoFromCookies()) {
            this._receiveProducts(this.getPromoFromCookies())
                .then((data) => {
                    this.isPromoValid = data.isPromoValid;
                    this.renderPromo();
                    this.refreshTotalCartData()
                })
        }
    }
}