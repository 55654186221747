import { v4 as uuidv4 } from 'uuid';


export default {
    getUUID() {
        var uuid = Cookies.get('uuid');
        if (!uuid) {
            uuid = uuidv4();
            Cookies.set('uuid', uuid);
        }
        return uuid;
    },
}