import {api, clearErrors, showError, showPopup, getGSessionIds, getCookie, getCountry, getUuid} from "./Utils"

console.log('guides.js')

$(document).ready(function() {
	document.querySelectorAll('input[name="phone"]').forEach((e) => {
		$(e).inputmask('+9{0,30}')
	})

	document.querySelector('#send-btn').addEventListener('click', (e) => {
		e.preventDefault()

		clearErrors()

		const names = document.querySelector('input[name="name"]').value.split(' ')
		const data = {
			email: document.querySelector('input[name="email"]').value,
			phone: document.querySelector('input[name="phone"]').value,
			first_name: names[0],
			last_name: names.slice(1).join(' '),
			uuid: getUuid(),
			country: getCountry(),
			utm_source: getCookie('utm_source'),
			utm_medium: getCookie('utm_medium'),
			utm_campaign: getCookie('utm_campaign'),
			utm_content: getCookie('utm_content'),
			utm_term: getCookie('utm_term'),
			gClientId: getCookie('gClientId'),
			gSessionId: getGSessionIds(),
			yandex_id: getCookie('_ym_uid'),
			chousen_guide: document.querySelector('.guide_title').textContent,
		}

		if (!data.first_name) {
			showError('input[name="name"]', 'Пожалуйста, введите имя')
			return
		}
		if (!data.phone || data.phone.endsWith('_')) {
			showError('input[name="phone"]', 'Введен некорректный номер телефона')
			return
		}
		if (!data.email) {
			showError('input[name="email"]', 'Пожалуйста, введите email')
			return
		}

		const setupPopup = showPopup()
		api('post', '/guides-order', data).then(() => {
			setupPopup('Успех', 'Отлично! Отправили гайд на ваш e-mail, проверяйте 😉')
		})
	})


});
