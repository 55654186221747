import { api } from "./Utils"

const create = (force) => {
    const container = document.querySelector('.container')
    const urlParams = new URLSearchParams(window.location.search)
	const contactId = urlParams.get('contact_id')

	api('post', '/create-rsb', {
	    force: force,
	    contact_id: contactId,
	    contact_name: urlParams.get('contact_name'),
		rfm: urlParams.get('rfm'),
		r_value: urlParams.get('R_value'),
		f_value: urlParams.get('F_value'),
		m_value: urlParams.get('M_value'),
		user: urlParams.get('user'),
		"status": urlParams.get('status'),
	}).then((d) => {
		if (d.detail) {
			console.error(d)
			container.innerHTML = `<h2>SERVER ERROR</h2><p>${JSON.stringify(d.detail)}</p>`

		} else if (d.created_at) {
			container.innerHTML = `<h2>WARNING</h2><p>Сделка с айди контакта <b>${contactId}</b> уже была создана <b>${d.created_at}</b> создать ещё одну?</p><br><a id="yes" href="#">создать</a><a href="${d.lead_url}">открыть созданную</a>`
			const yes = container.querySelector('#yes')
			yes.addEventListener('click', () => {
				container.innerHTML = '<img src="/static/img/loading.gif">'
				create(true)
			})

		} else if (d.lead_url) {
			container.innerHTML = `<h2>SUCCESS</h2><p>Сделка была создана успешно.</p><br><a href="${d.lead_url}">перейти в сделку</a>`

		} else {
			console.error(d)
			container.innerHTML = `<h2>UNKNOWN ERROR</h2><p>${JSON.stringify(d)}</p>`
		}
	}).catch((e) => {
		console.error(e)
		container.innerHTML = `<h2>FETCH ERROR</h2><p>${e}</p>`
	})

}

(() => {
    create(false)
})()
