import {makeElement, api, getCookie, getUuid} from "./Utils"


const showEmailRequest = () => {
    document.querySelector('#instructions').classList.add('hidden')
    document.querySelector('#email-request').classList.remove('hidden')
}

const showInstructions = () => {
    document.querySelector('#email-request').classList.add('hidden')
    document.querySelector('#instructions').classList.remove('hidden')
}

const disableCheckbox3000 = () => {
    const checkbox = document.querySelector('#checkbox-3000')
    checkbox.classList.add('hidden')
    checkbox.labels[0].classList.add('hidden')
    checkbox.checked = false

}


api('get', '/instruction-urls').then((d) => {
    const container = document.querySelector('#instructions')
    d.categories.forEach((category) => {
        const categoryElement = makeElement(`<div class="instruction-urls-category">`)
        container.appendChild(categoryElement)
        categoryElement.appendChild(makeElement(`<h2>${category.name}</h2>`))
        for (const url of category.urls) {
            categoryElement.appendChild(makeElement(`
              <a href="${url.url}" class="instruction-urls-instruct-url">
                <div class="left">
                  <img src="${url.image}">
                </div>
                <div class="right">
                  <p class="black">${url.name}</p>
                  <p class="orange">${url.description}</p>
                </div>
              </a>
            `))
        }
    })
})


document.querySelector('#instrict_btn').addEventListener('click', () => {
    const emailField = document.querySelector('#email')
    emailField.style.borderColor = 'rgba(0, 0, 0, 0.30)'
    if (!emailField.value) {
        emailField.style.borderColor = '#f00'
    } else {
        showInstructions()
    }

    if (!document.querySelector('#checkbox-3000').checked) {
        return
    }

    api('post', '/registration-popup-was-shown', {
        yandex_uid: getCookie('_ym_uid'),
    }).then(() => {})

    api('post', '/register', {
		uuid: getUuid(),
		email: emailField.value,
        send_promo_letters: document.querySelector('#checkbox-cfi').checked,
        source: 'instructions',
        yandex_id: getCookie('_ym_uid'),
	}).then((data) => {
        if (data.detail) {
            console.log(data)
        } else {
            console.log('contact created')
            ym(57109408,'reachGoal','register_lk')
        }
    })
})

document.querySelector('#email').addEventListener('keyup', () => {
    if (event.keyCode === 13) {
        document.querySelector('#instrict_btn').dispatchEvent(new Event('click'))
    }
})

api('get', `/user-info?yandex_uid=${getCookie('_ym_uid')}&uuid=${getUuid()}`).then((d) => {
    if (!d.logined && !d.yid_in_db) {
        showEmailRequest()
    } else {
        showInstructions()
    }

    // if (d.yid_in_db) {
    //     disableCheckbox3000()
    // }
})

