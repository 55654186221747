import Config from "./Config";
import CartManager from "./CartManager";
import CountryCookieManager from "./CountryCookieManager";
import Uuid from "./Uuid";

export default {
    sendPreOrder(data) {
        var country = CountryCookieManager.getCountryFromCookies()
        if (!country)
            country = 'Россия'

        data['uuid'] = Uuid.getUUID();
        data['country'] = country;
        data['cart'] = CartManager.getBasketItemsFromCookie();
        data['promo'] = CartManager.getPromoFromCookies();
        fetch(Config.apiUrl + '/pre-order', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .catch(e => console.log(e))
    },
}