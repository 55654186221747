import Config from "./modules/Config";
import {
  setCartItem,
  getCart,
  modifyCart,
  formatInt,
  getCookie,
  api,
  makeElement,
  getUuid,
  selectText,
  update_cab_header,
  showPopup,
  deleteCookie,
  reportError,
  getCountry,
  showCloudpayments,
} from "./Utils";

let selectedGoalId;

const extendPointsTime = (type) => {
  const setText = showPopup();
  api("post", "/prevent-points-burning", {
    uuid: getUuid(),
    country: getCountry(),
    service_type: type == "free" ? "unburn_points_5_days" : "unburn_points_30_days",
  }).then((d) => {
    if (d.detail) {
      console.error(d);
      return;
    }

    if (type == "free") {
      setText(
        '<h1 style="font-size: 100px;text-align: center;margin: 10px;">🎉</h1>',
        `<h1 style="font-size: 36px;text-align: center;margin: 10px;">Ваши баллы успешно продлены до ${d.new_burn_date}!</h1>`,
      );
    } else {
      $.fancybox.close();

      if (d.payment_info.acquiring == "Авангард" || d.acquiring == "Продамус") {
        window.location.assign(d.payment_info.url);
      } else if (d.payment_info.acquiring == "CloudPayments") {
        showCloudpayments(
          d.payment_info.public_id,
          d.payment_info.lead_id,
          d.payment_info.amount,
          d.payment_info.is_en,
          false,
        );
      } else {
        console.error(`wrong acquiring value`, d);
      }
    }
  });
};

// NEW
// const activateReviewsUrlPopup = (d) => {
//   let popup = document.querySelector("#reviews_url");
//   let urlInput = document.querySelector("#review-url-input");
//   let urlInputError = document.querySelector("#review-url-input-error");
//   let urlCheckbox = document.querySelector("#review-url-checkbox");
//   let urlButton = document.querySelector("#reviews-url-button");

//   urlButton.addEventListener("click", () => {
//     urlInput.classList.remove("red-border");
//     urlCheckbox.classList.remove("red-border");
//     urlInputError.textContent = "";

//     if (!urlInput.value) {
//       urlInput.classList.add("red-border");
//       return;
//     }

//     const urlText = "https://" + urlInput.value.replace(/^(https?:\/\/)?(www\.)?/, "");

//     try {
//       const _ = new URL(urlText);
//     } catch {
//       urlInput.classList.add("red-border");
//       urlInputError.textContent = `некорректная ссылка`;
//       return;
//     }

//     const url = new URL(urlText);

//     if (!["irecommend.ru", "instagram.com", "otzovik.com", "vk.com"].includes(url.host)) {
//       urlInput.classList.add("red-border");
//       urlInputError.textContent = `не допустимый хост ${url.host}`;
//       return;
//     }

//     if (!urlCheckbox.checked) {
//       urlCheckbox.classList.add("red-border");
//       return;
//     }

//     $.fancybox.close();

//     let successPopup = showPopup(true);

//     api("post", "/external-review", {
//       uuid: getUuid(),
//       url: urlText,
//     }).then((_) => {
//       successPopup(
//         "popap_bals personal_cab_content_in reviews-success-popup",
//         `
//                 <h3 class="info_popup_heading">Спасибо за обратную связь!</h3>
//                 <p>Когда отзыв пройдёт модерацию, мы отправим вам на почту письмо-подтверждение и начислим 10 000 бонусных баллов на счёт в личном кабинете.</p>
//                 <a class="btn_1 reviews-url-button" href="javascript:;" onclick="$.fancybox.close()">Понятно</a>
//               `,
//       );
//     });
//   });
// };

const fillLoyaltySystemBlock = (d) => {
  const dynamicContent = document.querySelector("#dynamic-content-block");
  const goalPricesBlock = document.querySelector("#goal-prices");

  const awailableAmount = d.info.goals
    .map((x) => x.amount < d.info.loyalty_balance)
    .reduce((s, x) => +s + +x);

  if (d.info.chousen_goal && d.info.chousen_goal.amount > d.info.loyalty_balance) {
    const goal = d.info.chousen_goal;
    console.log(goal);
    const amount = formatInt(goal.amount);
    const current_amount = formatInt(goal.current_amount);
    const delta = goal.amount > goal.current_amount ? goal.amount - goal.current_amount : 0;
    const progress = delta ? goal.current_amount / goal.amount : 1;
    const delta_amount = formatInt(delta);

    const headerText = goal.is_completed
      ? `Поздравляем! Вы можете получить<br>${goal.product.name} в подарок!`
      : `Совершите покупки на сумму ${amount} ₽, чтобы получить ${goal.product.name} в подарок при следующем заказе!`;
    const aboveButtonText = goal.is_completed
      ? `Получите свой подарок с любым заказом <span>${
          goal.receive_deadline ? "до " + goal.receive_deadline : ""
        }</span>`
      : `Чтобы получить подарок при следующем заказе, <span>совершите покупки ещё на ${delta_amount} ₽</span>`;

    dynamicContent.replaceWith(
      makeElement(`<div id="dynamic-content-block">
                <div class="state_block">
                  <div class="state_block_in">
                    <div class="present_ic_wrapper"><div class="present_ic"${
                      goal.is_completed ? "" : 'style="display: none;"'
                    }></div></div>
                    <div class="state_block_in_txt1">
                      <p style="position: relative;">${headerText}</p>
                      <a id="unselect-goal" href="javascript:;">Выбрать другой подарок</a>
                    </div>
                    <div class="state_block_in_flex">
                      <div class="state_block_in_flex_left">
                        <div class="flex_block1_in_txt3">
                          <span>Сумма покупок</span>
                          <p><b>${current_amount} ₽</b> </p>
                        </div>
                        <p class="state_block_in_flex_left_txt1">${aboveButtonText}</p>
                        <a data-gift_id="${goal.product.id}" href="javascript:;" class="btn_1${
        goal.is_completed ? " btn_1_present" : ""
      }">${goal.is_completed ? "Получить подарок" : "Перейти в корзину"}</a>
                      </div>
                      <div class="state_block_in_flex_right">
                        <div class="state_block_in_flex_right_img">
                          <img src="${goal.product_image_url}" alt="">
                          <img style="clip-path: polygon(0 ${100 - 100 * progress}%, 100% ${
        100 - 100 * progress
      }%, 100% 100%, 0% 100%);" class="overlay_img" src="${goal.product_image_url}" alt="">
                        </div>
                        <div class="scale_status">
                          <div class="scale_status_in" style="height: ${Math.max(
                            100 * progress,
                            2.9,
                          )}%">
                            <button class="perc_btn">${parseInt(100 * progress)}%</button>
                          </div>
                          <p class="percent1">100 %</p>
                          <p class="percent2">75 %</p>
                          <p class="percent3">50 %</p>
                          <p class="percent4">25 %</p>
                          <p class="percent5">0</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="buy_history" class="buy_history hidden">
                  <p class="buy_history_top">История покупок</p>
                </div>
                </div>`),
    );
    updateGiftProductButtons(d, false);
    document.querySelector("#unselect-goal").addEventListener("click", (e) => {
      api("post", "/chouse-product-goal", {
        uuid: getUuid(),
        goal_id: 0,
      }).then(() => {
        window.location.reload();
      });
    });
  } else {
    dynamicContent.replaceWith(
      makeElement(`
                <div id="dynamic-content-block" class="flex_block2_parent">
                  <p class="txt_block2_in1">Выберите продукт, который вы желаете получить в рамках нашей программы «Забери подарок»:
                  </p>
                  <div class="flex_block2_parent_in">
                    <div id="loyalty-goals" class="flex_block2"></div>
                  </div>
                  <div id="buy_history" class="buy_history hidden">
                    <p class="buy_history_top">История покупок</p>
                  </div>
                </div>
                `),
    );
    const goals = document.querySelector("#loyalty-goals");
    const selectPopupConfirm = document.querySelector("#select-popup-confirm");
    goals.innerHTML = "";
    d.info.goals.forEach((goal) => {
      const amount = formatInt(goal.amount);
      goals.appendChild(
        makeElement(`
                    <div class="flex_block2_in">
                      <div class="flex_block2_in_img">
                        <img src="${goal.product_image_url}" alt="">
                      </div>
                      <p class="flex_block2_in_name">${goal.product.name}</p>
                      <p class="flex_block2_in_name"><b>${formatInt(goal.amount)} Б</b></p>
                      <a data-gift_id="${goal.product.id}" id="goal-chouse-${
          goal.id
        }" data-fancybox="" data-src="#popap_choose" href="javascript:;" class="btn_1">Выбрать</a>
                    </div>
                    `),
      );
    });

    selectPopupConfirm.addEventListener("click", () => {
      api("post", "/chouse-product-goal", {
        uuid: getUuid(),
        goal_id: selectedGoalId,
      }).then(() => {
        window.location.reload();
      });
    });
    updateGiftProductButtons(d, true);
  }
};

const fillCopyRefUrlButtons = (d) => {
  document.querySelectorAll("#copy-ref-url").forEach((element) => {
    element.addEventListener("click", (e) => {
      e.preventDefault();

      if (element.classList.contains("btn_1")) {
        element.textContent = "Скопировано";
        element.classList.add("btn_1_present");
      }

      const refUrl = element.parentElement.parentElement.parentElement.querySelector("#ref-url");
      selectText(refUrl);
      navigator.clipboard.writeText(refUrl.innerText);

      try {
        ym(57109408, "reachGoal", "ref_link");
      } catch {
        console.error("can't send ym(ref_link)");
      }

      const copyAlt = document.createElement("div");
      copyAlt.classList.add("copy-alt-text");
      copyAlt.innerHTML = "Скопировано.";
      element.appendChild(copyAlt);
      setTimeout(() => {
        copyAlt.style.opacity = 1;
      }, 50);
      setTimeout(() => {
        copyAlt.style.opacity = 0;
      }, 1000);
      setTimeout(() => {
        copyAlt.remove();
      }, 1500);
    });
  });
};

const fillProgramsInfo = (d) => {
  const goalPricesBlock = document.querySelector("#goal-prices");
  const goalPrices = {};
  d.info.goals.forEach((goal) => {
    if (goalPrices[goal.amount]) {
      goalPrices[goal.amount].push(goal);
    } else {
      goalPrices[goal.amount] = [goal];
    }
  });
  goalPricesBlock.innetHTML = "";
  for (const amount in goalPrices) {
    const goals = goalPrices[amount];
    const showAmount = formatInt(amount);
    const delimetr = goals.length <= 1 ? "" : goals.length == 2 ? " или " : ", ";
    const showGoals = goals.map((g) => g.product.name).join(delimetr);
    goalPricesBlock.innerHTML += `<li>${showGoals} – ${showAmount} ₽</li>`;
  }
};

const fillBuyHistory = (d) => {
  const shortBuyHistory = document.querySelector("#short-buy-history");
  for (const op of d.info.awaiting) {
    shortBuyHistory.innerHTML += `<p class="bals_block_in2_left_txt2 gray"><span>${
      op.amount > 0 ? "+" : ""
    }${formatInt(op.amount)} Б</span> — ${op.front_description} <i> — Ждет получения</i></p>`;
  }
  if (d.info.awaiting.length < 2) {
    for (const op of d.info.balance_operations.slice(0, 2 - d.info.awaiting.length)) {
      if (op.operation_type == "fake_virtual") {
        shortBuyHistory.innerHTML += `<p class="bals_block_in2_left_txt2 gray"><span>${formatInt(
          op.amount,
        )} Б</span> — ${op.front_description} <i> — Заразервировано</i></p>`;
        continue;
      }

      let buyClasses = "";
      if (op.description == "Получен подарок") {
        buyClasses = "bals_block_in2_left_txt3";
      } else if (op.description == "Сгорание") {
        buyClasses = "bals_block_in2_left_txt3 red";
      } else {
        buyClasses = "bals_block_in2_left_txt2";
      }
      shortBuyHistory.innerHTML += `<p class="${buyClasses}"><span>${
        op.amount > 0 ? "+" : ""
      }${formatInt(op.amount)} Б</span> — ${op.front_description}</p>`;
    }
  }

  const buyHistoryPopup = document.querySelector("#buy-history");
  let buyHistory = "";
  if (d.info.balance_operations.length || d.info.awaiting) {
    buyHistory = "";
    d.info.awaiting.forEach((op) => {
      let productsPart = "";
      op.order.cart.forEach((product) => {
        productsPart += `<div class="his_flex_right_txt2${
          product.price_with_discounts ? "" : " gift-product"
        }">
                      <p>${product.name}</p>
                      <p>x${product.quantity}</p>
                      <p>${formatInt(product.price_with_discounts)} ₽ </p>
                    </div>`;
      });
      buyHistory += `<div class="popap_bals_his_flex">
                  <div class="popap_bals_his_flex_left">
                    <p class="gray">+${formatInt(op.amount)} Б </p>
                  </div>
                  <div class="popap_bals_his_flex_right" style="width: 100%;">
                    <p class="his_flex_right_txt1 gray">${
                      op.front_description
                    } <i> — Ждет получения</i></p>
                    ${productsPart}
                  </div>
                </div>`;
    });
    d.info.balance_operations.forEach((op) => {
      const orderSum = formatInt(op.amount);
      if (op.operation_type == "fake_virtual") {
        buyHistory += `<div class="popap_bals_his_flex">
                  <div class="popap_bals_his_flex_left">
                    <p class="gray">${formatInt(op.amount)} Б </p>
                  </div>
                  <div class="popap_bals_his_flex_right" style="width: 100%;">
                    <p class="his_flex_right_txt1 gray">${
                      op.front_description
                    } <i> — Заразервировано</i></p>
                  </div>
                </div>`;
      } else if (op.description == "Заказ") {
        let productsPart = "";
        op.order.cart.forEach((product) => {
          productsPart += `<div class="his_flex_right_txt2${
            product.price_with_discounts ? "" : " gift-product"
          }">
                      <p>${product.name}</p>
                      <p>x${product.quantity}</p>
                      <p>${formatInt(product.price_with_discounts)} ₽ </p>
                    </div>`;
        });
        buyHistory += `<div class="popap_bals_his_flex">
                  <div class="popap_bals_his_flex_left">
                    <p>+${formatInt(op.amount)} Б </p>
                  </div>
                  <div class="popap_bals_his_flex_right" style="width: 100%;">
                    <p class="his_flex_right_txt1">${op.front_description}</p>
                    ${productsPart}
                  </div>
                </div>`;
      } else {
        buyHistory += `<div class="popap_bals_his_flex">
                  <div class="popap_bals_his_flex_left">
                    <p${
                      op.amount > 0
                        ? ' class="green" '
                        : op.description == "Сгорание"
                        ? ' class="red" '
                        : ""
                    }><span>${op.amount > 0 ? "+" : ""}${formatInt(op.amount)} Б</span> </p>
                  </div>
                  <div class="popap_bals_his_flex_right" style="width: 100%;">
                    <p class="his_flex_right_txt1">${op.front_description}</p>
                  </div>
                </div>`;
      }
    });
  } else {
    buyHistory =
      '<p class="buy_history_empty">Вы ещё не совершили покупок в рамках программы «Забери подарок»</p>';
  }
  buyHistoryPopup.innerHTML = buyHistory;
};

const getCurrentPoints = (d) => {
  let gifts = getCart("gifts_cart", "session");
  return [d.info.loyalty_balance, ...gifts].reduce((s, g) => {
    const giftGoal = d.info.goals.find((goal) => goal.product.id == g.id);
    if (!giftGoal) {
      return s;
    }
    return s - giftGoal.amount * g.quantity;
  });
};

const updateGiftProductButtons = (d, many, refresh = false) => {
  const blockAfterBtn = document.querySelector("#dynamic-content-block .txt_block2_in1");
  if (!refresh && blockAfterBtn) {
    blockAfterBtn.after(
      makeElement(
        `<a id="get_gift_button" class="btn_1 btn_1_present hidden" href="/old_cart.html">Забрать подарок</a>`,
      ),
    );
  }

  if (many) {
    let gifts = getCart("gifts_cart", "session");
    const currentPoints = getCurrentPoints(d);

    if (blockAfterBtn) {
      if (gifts && gifts.reduce((s, x) => s + x.quantity, 0)) {
        document.querySelector("#get_gift_button").classList.remove("hidden");
      } else {
        document.querySelector("#get_gift_button").classList.add("hidden");
      }
    }

    document.querySelectorAll("a[data-gift_id]").forEach((btn) => {
      const giftId = btn.dataset.gift_id;
      const goal = d.info.goals.find((g) => g.product.id == giftId);
      const giftCartItem = gifts.find((i) => i.id == giftId);

      if (!refresh) {
        btn.addEventListener("click", (e) => {
          // for selecting goal
          selectedGoalId = goal.id;
          document.querySelector("#chouse-product-name").textContent = goal.product.name;

          // for geting gift
          if (goal.amount <= getCurrentPoints(d)) {
            setCartItem(giftId, 1, "gifts_cart", "session");
            updateGiftProductButtons(d, many, true);
          }
        });

        btn.after(
          makeElement(`
                  <div class="plus-minus-btns hidden">
                    <a href="javascript:;" class="minus">-</a>
                    <p></p>
                    <a href="javascript:;" class="plus">+</a>
                  </div>
                `),
        );
        btn.parentElement.querySelector(".plus").addEventListener("click", (e) => {
          if (goal.amount > getCurrentPoints(d)) {
            return;
          }

          modifyCart(giftId, 1, "gifts_cart", "session");
          updateGiftProductButtons(d, many, true);
        });
        btn.parentElement.querySelector(".minus").addEventListener("click", (e) => {
          modifyCart(giftId, -1, "gifts_cart", "session");
          updateGiftProductButtons(d, many, true);
        });
      }

      btn.style.fontSize = "16px";
      if (goal.amount > currentPoints && (!giftCartItem || giftCartItem.quantity == 0)) {
        btn.classList.remove("btn_1_present");
        btn.classList.remove("hidden");
        btn.parentElement.querySelector(".plus-minus-btns").classList.add("hidden");
        if (goal.amount <= d.info.loyalty_balance) {
          btn.removeAttribute("data-fancybox");
          btn.classList.add("btn_1_disabled");
          btn.textContent = "Недоступно";
        } else {
          btn.style.fontSize = "14px";
          btn.textContent = "Выбрать как цель";
          btn.setAttribute("data-fancybox", "");
          btn.classList.remove("btn_1_disabled");
        }
      } else if (giftCartItem && giftCartItem.quantity > 0) {
        btn.classList.add("hidden");
        btn.parentElement.querySelector(".plus-minus-btns").classList.remove("hidden");
        btn.parentElement.querySelector(".plus-minus-btns p").textContent = giftCartItem.quantity;

        if (goal.amount <= currentPoints) {
          btn.parentElement.querySelector(".plus").classList.remove("disabled");
        } else {
          btn.parentElement.querySelector(".plus").classList.add("disabled");
        }
      } else {
        btn.classList.remove("btn_1_disabled");
        btn.classList.remove("hidden");
        btn.classList.add("btn_1_present");
        btn.textContent = "Выбрать";
        btn.removeAttribute("data-fancybox");
        btn.parentElement.querySelector(".plus-minus-btns").classList.add("hidden");
      }
    });
  } else {
    document.querySelectorAll("a[data-gift_id]").forEach((btn) => {
      const giftId = btn.dataset.gift_id;
      if (!refresh) {
        btn.addEventListener("click", (e) => {
          e.preventDefault();
          if (d.info.chousen_goal.is_completed) {
            // no +- buttons needs here, cuz when client have 20K (can add second zeolite),
            // this function goes with many=true cuz MK cost 20K as 2Z
            setCartItem(giftId, 1, "gifts_cart", "session");
          }
          window.location.assign("/old_cart.html");
        });
      }
    });
  }
};

const addPasswordsPopup = (d) => {
  document.querySelector("body").appendChild(
    makeElement(`
          <div id="change-password-popup" class="hidden">
            <p class="reg_form_txt2">Придумайте ваш личный пароль</p>
            <form action="" class="reg_form_form">
              <input type="email" class="hidden" value="${d.info.email}">
              <div class="in_pt">
               <input id="password1" type="password" placeholder="Пароль">
               <span id="pw1Error" class="eror_data_txt"></span>
              </div>
              <div class="in_pt">
               <input id="password2" type="password" placeholder="Подтверждение пароля">
               <span id="pw2Error" class="eror_data_txt"></span>
              </div>
              <button id="passwords-btn" class="btn_form">Сохранить пароль</button>
            </form>
          </div>
    `),
  );

  document.querySelector("#passwords-btn").addEventListener("click", (e) => {
    e.preventDefault();

    const password1 = document.querySelector("#password1");
    const password2 = document.querySelector("#password2");
    const password1Error = document.querySelector("#pw1Error");
    const password2Error = document.querySelector("#pw2Error");

    password1Error.innerText = "";
    password2Error.innerText = "";

    password1.parentElement.classList.remove("in_pt_eror");
    password2.parentElement.classList.remove("in_pt_eror");

    if (!password1.value) {
      password1.parentElement.classList.add("in_pt_eror");
      password1Error.innerText = "Введите пароль";
      return;
    }

    if (password1.value != password2.value) {
      password2.parentElement.classList.add("in_pt_eror");
      password2Error.innerText = "Пароли не совпадают";
      return;
    }

    const data = {
      uuid: getUuid(),
      password: password1.value,
    };

    const setPopupData = showPopup();

    fetch(Config.apiUrl + "/setup-password", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((r) => r.json())
      .then((data) => {
        if (!data.success) {
          setPopupData("Ошибка", JSON.stringify(data));
        } else {
          setPopupData("Успешно", "Пароль сохранен");
        }
      });
  });

  if (!d.info.password_defined_by_user) {
    $.fancybox.close();
    $("#change-password-popup")
      .fancybox({
        clickSlide: false,
        touch: false,
        helpers: {
          overlay: { closeClick: false },
        },
      })
      .click();
  }
};

const pages = {
  "registration_email.html": () => {
    const btn = document.querySelector(".btn_form");
    const email = document.querySelector("#email");
    const password1 = document.querySelector("#password1");
    const password2 = document.querySelector("#password2");
    const checkbox = document.querySelector("#checkboxx");
    const emailError = document.querySelector("#emailError");
    const password1Error = document.querySelector("#pw1Error");
    const password2Error = document.querySelector("#pw2Error");

    checkbox.addEventListener("click", () => {
      checkbox.labels[0].style.color = "";
    });

    btn.addEventListener("click", (e) => {
      e.preventDefault();

      emailError.innerText = "";
      password1Error.innerText = "";
      password2Error.innerText = "";

      if (!checkbox.checked) {
        checkbox.labels[0].style.color = "#e50202";
        return;
      }

      email.parentElement.classList.remove("in_pt_eror");
      password1.parentElement.classList.remove("in_pt_eror");
      password2.parentElement.classList.remove("in_pt_eror");

      if (!/.+@.+\..+/.test(email.value.trim())) {
        email.parentElement.classList.add("in_pt_eror");
        emailError.innerText = "Некорректный e-mail";
        return;
      }

      if (!password1.value) {
        password1.parentElement.classList.add("in_pt_eror");
        password1Error.innerText = "Введите пароль";
        return;
      }

      if (password1.value != password2.value) {
        password2.parentElement.classList.add("in_pt_eror");
        password2Error.innerText = "Пароли не совпадают";
        return;
      }

      const data = {
        uuid: getUuid(),
        email: email.value.trim(),
        password: password1.value,
        send_promo_letters: true,
        source: "lk",
        yandex_id: getCookie("_ym_uid"),
      };

      fetch(Config.apiUrl + "/register", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((r) => r.json())
        .then((data) => {
          if (data.detail) {
            email.parentElement.classList.add("in_pt_eror");
            emailError.innerText = data.detail;
          } else {
            try {
              ym(57109408, "reachGoal", "register_lk");
            } catch {}
            window["rrApiOnReady"] = window["rrApiOnReady"] || [];
            window["rrApiOnReady"].push(function () {
              try {
                rrApi.setCustomer({
                  customerId: data.user_id,
                  email: email.value.trim(),
                  phone: getCookie("phone"),
                  isAgreedToReceiveMarketingMail: true,
                });
              } catch (e) {}
            });
            window.location.assign("pers_cab.html");
          }
        });
    });
  },
  "login.html": () => {
    const btn = document.querySelector(".btn_form");
    const login = document.querySelector('input[type="text"]');
    const pw = document.querySelector('input[type="password"]');
    const loginError = document.querySelector("#login-error");
    const passwordError = document.querySelector("#password-error");

    btn.addEventListener("click", (e) => {
      e.preventDefault();

      passwordError.parentElement.classList.remove("in_pt_eror");
      loginError.parentElement.classList.remove("in_pt_eror");

      const data = {
        uuid: getUuid(),
        email: login.value,
        phone: login.value,
        password: pw.value,
      };

      fetch(Config.apiUrl + "/login", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((r) => r.json())
        .then((data) => {
          if (data.password) {
            passwordError.parentElement.classList.add("in_pt_eror");
            passwordError.innerText = data.password;
          } else if (data.login) {
            loginError.parentElement.classList.add("in_pt_eror");
            loginError.innerText = data.login;
          } else {
            window["rrApiOnReady"] = window["rrApiOnReady"] || [];
            window["rrApiOnReady"].push(function () {
              try {
                rrApi.setCustomer({
                  customerId: data.user_id,
                  email: login.value,
                  isAgreedToReceiveMarketingMail: true,
                });
              } catch (e) {}
            });

            window.location.assign("pers_cab.html");
          }
        });
    });
  },
  "recovery_password.html": () => {
    const btn = document.querySelector(".btn_form");
    const email = document.querySelector("input");
    const error = document.querySelector("#error");

    btn.addEventListener("click", (e) => {
      e.preventDefault();

      error.parentElement.classList.remove("in_pt_eror");

      const data = {
        uuid: getUuid(),
        email: email.value.trim(),
      };

      fetch(Config.apiUrl + "/login-recovery", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((r) => r.json())
        .then((data) => {
          if (data.error) {
            error.parentElement.classList.add("in_pt_eror");
            error.innerText = data.error;
          } else {
            $("#sended-popup")
              .fancybox({
                afterClose: function () {
                  window.location.assign("login.html");
                },
              })
              .click();
          }
        });
    });
  },
  "pers_cab_options.html": () => {
    update_cab_header()
      .then((data) => {
        if (!data.success) return;

        const urlParams = new URLSearchParams(window.location.search);
        const detail = document.querySelector("#detail");
        if (urlParams.get("success")) detail.innerHTML = "Изменения сохранены";

        const firstName = document.querySelector("#first_name");
        const lastName = document.querySelector("#last_name");
        const surName = document.querySelector("#sur_name");
        const email = document.querySelector("#email");
        const phone = document.querySelector("#phone");
        const saveBtn = document.querySelector("#save");

        firstName.value = data.info.first_name;
        lastName.value = data.info.last_name;
        surName.value = data.info.sur_name;
        email.value = data.info.email;
        phone.value = data.info.phone;

        saveBtn.addEventListener("click", (e) => {
          e.preventDefault();

          const data = {
            uuid: getUuid(),
            first_name: firstName.value,
            last_name: lastName.value,
            sur_name: surName.value,
            email: email.value.trim(),
            phone: phone.value,
          };

          fetch(Config.apiUrl + "/update-contact", {
            method: "POST",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify(data),
          })
            .then((r) => r.json())
            .then((data) => {
              if (data.detail) {
                detail.innerText = data.error;
              } else {
                window.location.assign("?success=1");
              }
            });
        });
      })
      .catch((err) => {
        reportError(err).then((d) => {
          window.location.assign("login.html");
        });
      });
  },
  "pers_cab_change_password.html": () => {
    update_cab_header()
      .then(() => {
        const oldPassword = document.querySelector("#old_password");
        const newPassword1 = document.querySelector("#new_password1");
        const newPassword2 = document.querySelector("#new_password2");
        const saveBtn = document.querySelector("#save");

        saveBtn.addEventListener("click", (e) => {
          e.preventDefault();
          oldPassword.parentElement.classList.remove("in_pt_eror");
          newPassword2.parentElement.classList.remove("in_pt_eror");

          if (newPassword1.value && newPassword1.value != newPassword2.value) {
            newPassword2.parentElement.classList.add("in_pt_eror");
            return;
          }

          const data = {
            uuid: getUuid(),
            old_password: `${oldPassword.value}`,
            new_password: `${newPassword1.value}`,
          };

          fetch(Config.apiUrl + "/update-contact-password", {
            method: "POST",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify(data),
          })
            .then((r) => r.json())
            .then((data) => {
              if (!data.success) {
                oldPassword.parentElement.classList.add("in_pt_eror");
              } else {
                window.location.assign("login.html");
              }
            });
        });
      })
      .catch((err) => {
        reportError(err).then((d) => {
          window.location.assign("login.html");
        });
      });
  },
  "pers_cab.html": () => {
    update_cab_header()
      .then((d) => {
        addPasswordsPopup(d);
        fillBuyHistory(d);
        fillProgramsInfo(d);
        fillCopyRefUrlButtons(d);
        fillLoyaltySystemBlock(d);

        // NEW
        // activateReviewsUrlPopup(d);

        document.querySelector("#loyalty-extend-free").addEventListener("click", (e) => {
          if (!e.target.classList.contains("disabled")) {
            extendPointsTime("free");
          }
        });
        document.querySelector("#loyalty-extend-money").addEventListener("click", (e) => {
          if (!e.target.classList.contains("disabled")) {
            extendPointsTime("money");
          }
        });
      })
      .catch((err) => {
        reportError(err).then((d) => {
          window.location.assign("login.html");
        });
      });
  },
  "faq.html": () => {
    update_cab_header()
      .then((d) => {
        addPasswordsPopup(d);
        fillBuyHistory(d);
      })
      .catch((err) => {
        reportError(err).then((d) => {
          window.location.assign("login.html");
        });
      });
    api("get", "/faqs").then((faqs) => {
      const answersContainer = document.querySelector(".answers_blc");
      faqs.forEach((faq) => {
        answersContainer.innerHTML += `
                  <div class="answers_blc_in">
                    <div class="answers_blc_in_top">
                      <p>${faq.question}</p>
                    </div>
                    <div class="answers_blc_in_bot">
                      ${faq.answer}
                    </div>
                  </div>
                `;
      });

      $(".answers_blc_in_top").click(function () {
        $(this).toggleClass("answers_blc_in_top_show").next().slideToggle("fast");
        $(".answers_blc_in_top")
          .not(this)
          .removeClass("answers_blc_in_top_show")
          .next()
          .slideUp("fast");
      });
      /*===================*/
      $(".btn_read").click(function () {
        var btn = $(this);
        $(this)
          .parent()
          .find(".more_read")
          .slideToggle(300, function () {
            if ($(this).is(":hidden")) {
              $(btn).html("читать далее");
            } else {
              $(btn).html("свернуть");
            }
          });
        return false;
      });
    });
  },
};

document
  .querySelectorAll("img[href]")
  .forEach((e) =>
    e.addEventListener("click", () => window.location.assign(e.getAttribute("href"))),
  );
document.querySelectorAll('a[href="/api/contact-logout"]').forEach((e) => {
  e.href = `${Config.apiUrl}/contact-logout?uuid=${getCookie("uuid")}`;
  e.addEventListener("click", () => {
    sessionStorage.gifts_cart = [];
  });
});
document.querySelector('link[href="/css/index.css"]').remove();
for (const page of Object.keys(pages)) {
  if (window.location.pathname.endsWith(page)) {
    pages[page]();
    break;
  }
}

// NEW START
const initReviews = async () => {
  // const uuid = getUuid();

  // // **
  // const res = await fetch(`${Config.apiUrl}/contact-info?uuid=${uuid}`);
  // if (!res?.ok) {
  //   console.log("не удалось получить контакт");
  //   return;
  // }
  // const contact = await res.json();

  // const step =
  //   contact?.info?.review_stage && contact?.info?.review_stage > 3
  //     ? 3
  //     : contact?.info?.review_stage;

  // return [contact?.info?.review_stage, step, uuid];

  const uuid = getUuid();

  // **
  const res = await fetch(`${Config.apiUrl}/contact-info?uuid=${uuid}`);
  if (!res?.ok) {
    console.log("Failed to get contact");
    return;
  }
  const contact = await res.json();

  // **
  const rawStep = contact?.info?.review_stage;

  let currentStep =
    contact?.info?.review_stage && contact?.info?.review_stage > 3
      ? 3
      : contact?.info?.review_stage;

  let isSuccess = false;

  // **
  if (typeof rawStep !== "number" || typeof currentStep !== "number") {
    return;
  }

  // **
  if (rawStep < 4) {
    const reviewBlock = document.querySelector("#review-url-block");
    reviewBlock?.classList.remove("srOnly");
  }

  // **
  const postClientReview = async (url, stage) => {
    const res = await fetch(`${Config.apiUrl}/v2/client-reviews?uuid=${uuid}`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        uuid,
        url,
        stage,
      }),
    });

    return res;
  };

  // **
  const onReviewSubmit = async () => {
    const input = document.querySelector("#review-url-input");
    const checkbox = document.querySelector("#review-url-checkbox");

    if (!input?.value?.trim()?.length || !checkbox.checked) {
      return;
    }

    // **
    const reviewUrl = input.value;
    const res = await postClientReview(`${reviewUrl}`, currentStep + 1);

    if (!res?.ok) {
      console.log("Failed to send data");
      return;
    }

    // currentStep = currentStep > 3 ? currentStep : currentStep + 1;
    isSuccess = true;
    setHTML(currentStep);

    if (currentStep === 3) {
      const reviewsBlock = document.querySelector("#review-url-block");
      reviewsBlock?.classList.add("srOnly");
    }
  };

  // **
  const onNextBtnClick = async () => {
    if (currentStep === 3) {
      $.fancybox.close();
      return;
    }

    currentStep = currentStep + 1;
    isSuccess = false;
    setHTML(currentStep);
  };

  const setHTML = (step = 0) => {
    const content = {
      1: `
        <h3 class="info_popup_heading">Вставьте ссылку на опубликованный отзыв</h3>

        <p class="info_popup_step">Шаг 1</p>

        <div class="info_popup_descr">
            <span class="info_popup_descr_text">Оставьте отзыв на</span>
            <a
              class="info_popup_descr_text info_popup_descr_text_link"
              href="https://otzovik.com"
              target="_blank"
              rel="noindex nofollow">
              https://otzovik.com
            </a>
            <span class="info_popup_descr_text">или</span>
            <a
              class="info_popup_descr_text info_popup_descr_text_link"
              href="https://irecommend.ru"
              target="_blank"
              rel="noindex nofollow">
              https://irecommend.ru
            </a>
            <span class="info_popup_descr_text">и получите</span>
            <span class="info_popup_descr_text info_popup_descr_text_orange">5000 бонусных баллов.</span>
        </div>
      
        <div class="in_pt">
          <input class="reviews-url-input" id="review-url-input" type="text" placeholder="Ссылка на ваш отзыв">
          <p id="review-url-input-error" class="red"></p>
        </div>
      
        <div>
          <input class="new-checkbox" id="review-url-checkbox" type="checkbox" placeholder="Ссылка на ваш отзыв">
          <label class="new-checkbox-label" for="review-url-checkbox">Я даю согласие на репост и использование моего
            отзыва командой O-complex</label>
        </div>
      
        <button class="btn_1 reviews-url-button" id="reviews-url-button">Отправить ссылку</button>
        `,
      2: `
        <h3 class="info_popup_heading">Поднимаем ставки! 😊</h3>

        <p class="info_popup_step">Шаг 2</p>

        <div class="info_popup_descr">
            <span class="info_popup_descr_text">Оставьте отзыв на своей страничке в социальных сетях и мы начислим вам еще</span>
            <span class="info_popup_descr_text info_popup_descr_text_orange">6000 бонусов.</span>
        </div>
      
        <div class="in_pt">
          <input class="reviews-url-input" id="review-url-input" type="text" placeholder="Ссылка на ваш отзыв">
          <p id="review-url-input-error" class="red"></p>
        </div>
      
        <div>
          <input class="new-checkbox" id="review-url-checkbox" type="checkbox" placeholder="Ссылка на ваш отзыв">
          <label class="new-checkbox-label" for="review-url-checkbox">Я даю согласие на репост и использование моего
            отзыва командой O-complex</label>
        </div>
      
        <button class="btn_1 reviews-url-button" id="reviews-url-button">Отправить ссылку</button>
        `,
      3: `
       <h3 class="info_popup_heading">Взломайте следующий уровень</h3>

        <p class="info_popup_step">Шаг 3</p>

        <div class="info_popup_descr">
            <span class="info_popup_descr_text">Расскажите свою историю и приложите фотографии вашего результата</span>
            <span class="info_popup_descr_text_bold">До и После</span>
            <span class="info_popup_descr_text">(через социальные сети или облачное хранилище) и ваш баланс пополнится на</span>
            <span class="info_popup_descr_text info_popup_descr_text_orange">7000 бонусов.</span>
        </div>
      
        <div class="in_pt">
          <input class="reviews-url-input" id="review-url-input" type="text" placeholder="Ссылка на ваш отзыв">
          <p id="review-url-input-error" class="red"></p>
        </div>
      
        <div>
          <input class="new-checkbox" id="review-url-checkbox" type="checkbox" placeholder="Ссылка на ваш отзыв">
          <label class="new-checkbox-label" for="review-url-checkbox">Я даю согласие на репост и использование моего
            отзыва командой O-complex</label>
        </div>
      
        <button class="btn_1 reviews-url-button" id="reviews-url-button">Отправить ссылку</button>
        `,
      4: `
      <h3 class="info_popup_heading">И это не потолок! Вам точно есть, чем поделиться с миром.</h3>

        <p class="info_popup_step">Шаг 4</p>

        <div class="info_popup_descr">
          <p class="info_popup_descr_paragraph">
            <span class="info_popup_descr_text">Готовы к самому важному уровню?</span>
            <span class="info_popup_descr_text_bold">Запишите видеоотзыв и расскажете свою историю,</span>
            <span class="info_popup_descr_text">опубликуйте видео у себя в социальных сетях либо загрузите его на удобный для вас облачный диск.</span>
          </p>

          <p class="info_popup_descr_paragraph">
            <span class="info_popup_descr_text">Отправьте ссылку нам в окошко. В благодарность мы начислим вам</span>
            <span class="info_popup_descr_text_orange">10 000 бонусов,</span>
            <span class="info_popup_descr_text">чтобы еще больше поддержать ваше стремление к здоровой жизни❤️</span>
          </p>
        </div>
      
        <div class="in_pt">
          <input class="reviews-url-input" id="review-url-input" type="text" placeholder="Ссылка на ваш отзыв">
          <p id="review-url-input-error" class="red"></p>
        </div>
      
        <div>
          <input class="new-checkbox" id="review-url-checkbox" type="checkbox" placeholder="Ссылка на ваш отзыв">
          <label class="new-checkbox-label" for="review-url-checkbox">Я даю согласие на репост и использование моего
            отзыва командой O-complex</label>
        </div>
      
        <button class="btn_1 reviews-url-button" id="reviews-url-button">Отправить ссылку</button>
        `,
    };

    const gratitude = {
      1: `
        <h3 class="info_popup_heading">Спасибо за обратную связь!</h3>

        <div class="info_popup_descr">
            <p class="info_popup_descr_paragraph">
                <span class="info_popup_descr_text">
                 Когда отзыв пройдёт модерацию, мы отправим вам на почту письмо-подтверждение и начислим
                </span>
                 <span class="info_popup_descr_text info_popup_descr_text_orange">5 000 бонусных баллов</span>
                 <span class="info_popup_descr_text">на счёт в личном кабинете.</span>
            </p>

            <p class="info_popup_descr_paragraph">
                <span class="info_popup_descr_text info_popup_descr_text_bold">
                  А пока переходите к следующему шагу, чтобы получить больше баллов!
                </span>
            </p>
        </div>
      
        <button class="btn_1 reviews-url-button" id="reviews-url-button-next">Следующий шаг</button>
    `,
      2: `
        <h3 class="info_popup_heading">Спасибо за обратную связь!</h3>

        <div class="info_popup_descr">
            <p class="info_popup_descr_paragraph">
                <span class="info_popup_descr_text">
                 Когда отзыв пройдёт модерацию, мы отправим вам на почту письмо-подтверждение и начислим
                </span>
                 <span class="info_popup_descr_text info_popup_descr_text_orange">6 000 бонусных баллов</span>
                 <span class="info_popup_descr_text">на счёт в личном кабинете.</span>
            </p>

            <p class="info_popup_descr_paragraph">
                <span class="info_popup_descr_text info_popup_descr_text_bold">
                  А пока переходите к следующему шагу, чтобы взломать следующий уровень и получить больше баллов!
                </span>
            </p>
        </div>
      
        <button class="btn_1 reviews-url-button" id="reviews-url-button-next">Следующий шаг</button>
    `,
      3: `
        <h3 class="info_popup_heading">Спасибо за обратную связь!</h3>

        <div class="info_popup_descr">
            <p class="info_popup_descr_paragraph">
                <span class="info_popup_descr_text">
                 Когда отзыв пройдёт модерацию, мы отправим вам на почту письмо-подтверждение и начислим
                </span>
                 <span class="info_popup_descr_text info_popup_descr_text_orange">7 000 бонусных баллов</span>
            </p>

            <p class="info_popup_descr_paragraph">
                <span class="info_popup_descr_text info_popup_descr_text_bold">
                 А пока переходите к следующему шагу, чтобы получить максимум баллов за свой отзыв!
                </span>
            </p>
        </div>
      
        <button class="btn_1 reviews-url-button" id="reviews-url-button-next">Следующий шаг</button>
    `,
      4: `
        <h3 class="info_popup_heading">Ого, вы выполнили программу максимум! 😍</h3>

        <div class="info_popup_descr">
            <span class="info_popup_descr_text">
                Спасибо, что помогаете нам распространять важную мысль: “каждый человек способен избавиться от препятствий, мешающих ему жить полноценной жизнью” 
            </span>
        </div>
      
        <button class="btn_1 reviews-url-button" id="reviews-url-button-next">Закрыть</button>
    `,
    };

    const htmlToShow = isSuccess ? gratitude[step + 1] : content[step + 1];

    // **
    const reviewsModal = document.querySelector("#reviews_url_content");
    if (!reviewsModal) return;

    reviewsModal.innerHTML = "";
    reviewsModal.insertAdjacentHTML("afterbegin", htmlToShow);

    // **
    if (!isSuccess) {
      const input = document.querySelector("#review-url-input");
      const inputMessage = document.querySelector("#review-url-input-error");
      const checkbox = document.querySelector("#review-url-checkbox");
      const submitBtn = document.querySelector("#reviews-url-button");

      if (!input || !inputMessage || !checkbox || !submitBtn) {
        return;
      }

      // **
      checkbox.addEventListener("change", (e) => {
        if (e.currentTarget.checked) {
          checkbox.style.borderColor = "";
        }
      });

      input.addEventListener("input", (e) => {
        if (e.currentTarget.value.trim()) {
          input.style.borderColor = "";
          inputMessage.textContent = "";
        }
      });

      // **
      submitBtn.addEventListener("click", () => {
        checkbox.style.borderColor = "";
        input.style.borderColor = "";
        inputMessage.textContent = "";

        if (!input.value.trim().length) {
          input.style.borderColor = "#e50202";
          inputMessage.textContent = `пожалуйста, введите ссылку`;
        }

        if (!checkbox.checked) {
          checkbox.style.borderColor = "#e50202";
        }
      });

      submitBtn.addEventListener("click", onReviewSubmit);
    }

    // **
    if (isSuccess) {
      let nextBtn = document.querySelector("#reviews-url-button-next");
      nextBtn?.addEventListener("click", onNextBtnClick);
    }
  };

  setHTML(currentStep);
};
initReviews();

// **
// let [rawStep, currentStep, uuid] = await getCurrentStep();
// let isSuccess = false;

// // **
// if (typeof rawStep === "number" && rawStep < 4) {
//   const reviewBlock = document.querySelector("#review-url-block");
//   reviewBlock?.classList.remove("srOnly");
// }

// // **
// const postClientReview = async (url, stage) => {
//   const res = await fetch(`${Config.apiUrl}/v2/client-reviews?uuid=${uuid}`, {
//     method: "POST",
//     headers: {
//       "Content-type": "application/json",
//     },
//     body: JSON.stringify({
//       uuid,
//       url,
//       stage,
//     }),
//   });

//   return res;
// };

// // **
// const onReviewSubmit = async () => {
//   const input = document.querySelector("#review-url-input");
//   const checkbox = document.querySelector("#review-url-checkbox");

//   if (!input?.value?.trim()?.length || !checkbox.checked) {
//     return;
//   }

//   // **
//   const reviewUrl = input.value;
//   const res = await postClientReview(`${reviewUrl}`, currentStep + 1);

//   if (!res?.ok) {
//     console.log("Failed to send data");
//     return;
//   }

//   // currentStep = currentStep > 3 ? currentStep : currentStep + 1;
//   isSuccess = true;
//   setHTML(currentStep);

//   if (currentStep + 1 === 4) {
//     const reviewsBlock = document.querySelector("#review-url-block");
//     reviewsBlock?.classList.add("srOnly");
//   }
// };

// // **
// const onNextBtnClick = async () => {
//   if (currentStep === 3) {
//     $.fancybox.close();
//     return;
//   }

//   currentStep = currentStep + 1;
//   isSuccess = false;
//   setHTML(currentStep);
// };

// const setHTML = (step = 0) => {
//   const content = {
//     1: `
//         <h3 class="info_popup_heading">Вставьте ссылку на опубликованный отзыв</h3>

//         <p class="info_popup_step">Шаг 1</p>

//         <div class="info_popup_descr">
//             <span class="info_popup_descr_text">Оставьте отзыв на</span>
//             <a
//               class="info_popup_descr_text info_popup_descr_text_link"
//               href="https://otzovik.com"
//               target="_blank"
//               rel="noindex nofollow">
//               https://otzovik.com
//             </a>
//             <span class="info_popup_descr_text">или</span>
//             <a
//               class="info_popup_descr_text info_popup_descr_text_link"
//               href="https://irecommend.ru"
//               target="_blank"
//               rel="noindex nofollow">
//               https://irecommend.ru
//             </a>
//             <span class="info_popup_descr_text">и получите</span>
//             <span class="info_popup_descr_text info_popup_descr_text_orange">5000 бонусных баллов.</span>
//         </div>

//         <div class="in_pt">
//           <input class="reviews-url-input" id="review-url-input" type="text" placeholder="Ссылка на ваш отзыв">
//           <p id="review-url-input-error" class="red"></p>
//         </div>

//         <div>
//           <input class="new-checkbox" id="review-url-checkbox" type="checkbox" placeholder="Ссылка на ваш отзыв">
//           <label class="new-checkbox-label" for="review-url-checkbox">Я даю согласие на репост и использование моего
//             отзыва командой O-complex</label>
//         </div>

//         <button class="btn_1 reviews-url-button" id="reviews-url-button">Отправить ссылку</button>
//         `,
//     2: `
//         <h3 class="info_popup_heading">Поднимаем ставки! 😊</h3>

//         <p class="info_popup_step">Шаг 2</p>

//         <div class="info_popup_descr">
//             <span class="info_popup_descr_text">Оставьте отзыв на своей страничке в социальных сетях и мы начислим вам еще</span>
//             <span class="info_popup_descr_text info_popup_descr_text_orange">6000 бонусов.</span>
//         </div>

//         <div class="in_pt">
//           <input class="reviews-url-input" id="review-url-input" type="text" placeholder="Ссылка на ваш отзыв">
//           <p id="review-url-input-error" class="red"></p>
//         </div>

//         <div>
//           <input class="new-checkbox" id="review-url-checkbox" type="checkbox" placeholder="Ссылка на ваш отзыв">
//           <label class="new-checkbox-label" for="review-url-checkbox">Я даю согласие на репост и использование моего
//             отзыва командой O-complex</label>
//         </div>

//         <button class="btn_1 reviews-url-button" id="reviews-url-button">Отправить ссылку</button>
//         `,
//     3: `
//        <h3 class="info_popup_heading">Взломайте следующий уровень</h3>

//         <p class="info_popup_step">Шаг 3</p>

//         <div class="info_popup_descr">
//             <span class="info_popup_descr_text">Расскажите свою историю и приложите фотографии вашего результата</span>
//             <span class="info_popup_descr_text_bold">До и После</span>
//             <span class="info_popup_descr_text">(через социальные сети или облачное хранилище) и ваш баланс пополнится на</span>
//             <span class="info_popup_descr_text info_popup_descr_text_orange">7000 бонусов.</span>
//         </div>

//         <div class="in_pt">
//           <input class="reviews-url-input" id="review-url-input" type="text" placeholder="Ссылка на ваш отзыв">
//           <p id="review-url-input-error" class="red"></p>
//         </div>

//         <div>
//           <input class="new-checkbox" id="review-url-checkbox" type="checkbox" placeholder="Ссылка на ваш отзыв">
//           <label class="new-checkbox-label" for="review-url-checkbox">Я даю согласие на репост и использование моего
//             отзыва командой O-complex</label>
//         </div>

//         <button class="btn_1 reviews-url-button" id="reviews-url-button">Отправить ссылку</button>
//         `,
//     4: `
//       <h3 class="info_popup_heading">И это не потолок! Вам точно есть, чем поделиться с миром.</h3>

//         <p class="info_popup_step">Шаг 4</p>

//         <div class="info_popup_descr">
//           <p class="info_popup_descr_paragraph">
//             <span class="info_popup_descr_text">Готовы к самому важному уровню?</span>
//             <span class="info_popup_descr_text_bold">Запишите видеоотзыв и расскажете свою историю,</span>
//             <span class="info_popup_descr_text">опубликуйте видео у себя в социальных сетях либо загрузите его на удобный для вас облачный диск.</span>
//           </p>

//           <p class="info_popup_descr_paragraph">
//             <span class="info_popup_descr_text">Отправьте ссылку нам в окошко. В благодарность мы начислим вам</span>
//             <span class="info_popup_descr_text_orange">10 000 бонусов,</span>
//             <span class="info_popup_descr_text">чтобы еще больше поддержать ваше стремление к здоровой жизни❤️</span>
//           </p>
//         </div>

//         <div class="in_pt">
//           <input class="reviews-url-input" id="review-url-input" type="text" placeholder="Ссылка на ваш отзыв">
//           <p id="review-url-input-error" class="red"></p>
//         </div>

//         <div>
//           <input class="new-checkbox" id="review-url-checkbox" type="checkbox" placeholder="Ссылка на ваш отзыв">
//           <label class="new-checkbox-label" for="review-url-checkbox">Я даю согласие на репост и использование моего
//             отзыва командой O-complex</label>
//         </div>

//         <button class="btn_1 reviews-url-button" id="reviews-url-button">Отправить ссылку</button>
//         `,
//   };

//   const gratitude = {
//     1: `
//         <h3 class="info_popup_heading">Спасибо за обратную связь!</h3>

//         <div class="info_popup_descr">
//             <p class="info_popup_descr_paragraph">
//                 <span class="info_popup_descr_text">
//                  Когда отзыв пройдёт модерацию, мы отправим вам на почту письмо-подтверждение и начислим
//                 </span>
//                  <span class="info_popup_descr_text info_popup_descr_text_orange">5 000 бонусных баллов</span>
//                  <span class="info_popup_descr_text">на счёт в личном кабинете.</span>
//             </p>

//             <p class="info_popup_descr_paragraph">
//                 <span class="info_popup_descr_text info_popup_descr_text_bold">
//                   А пока переходите к следующему шагу, чтобы получить больше баллов!
//                 </span>
//             </p>
//         </div>

//         <button class="btn_1 reviews-url-button" id="reviews-url-button-next">Следующий шаг</button>
//     `,
//     2: `
//         <h3 class="info_popup_heading">Спасибо за обратную связь!</h3>

//         <div class="info_popup_descr">
//             <p class="info_popup_descr_paragraph">
//                 <span class="info_popup_descr_text">
//                  Когда отзыв пройдёт модерацию, мы отправим вам на почту письмо-подтверждение и начислим
//                 </span>
//                  <span class="info_popup_descr_text info_popup_descr_text_orange">6 000 бонусных баллов</span>
//                  <span class="info_popup_descr_text">на счёт в личном кабинете.</span>
//             </p>

//             <p class="info_popup_descr_paragraph">
//                 <span class="info_popup_descr_text info_popup_descr_text_bold">
//                   А пока переходите к следующему шагу, чтобы взломать следующий уровень и получить больше баллов!
//                 </span>
//             </p>
//         </div>

//         <button class="btn_1 reviews-url-button" id="reviews-url-button-next">Следующий шаг</button>
//     `,
//     3: `
//         <h3 class="info_popup_heading">Спасибо за обратную связь!</h3>

//         <div class="info_popup_descr">
//             <p class="info_popup_descr_paragraph">
//                 <span class="info_popup_descr_text">
//                  Когда отзыв пройдёт модерацию, мы отправим вам на почту письмо-подтверждение и начислим
//                 </span>
//                  <span class="info_popup_descr_text info_popup_descr_text_orange">7 000 бонусных баллов</span>
//             </p>

//             <p class="info_popup_descr_paragraph">
//                 <span class="info_popup_descr_text info_popup_descr_text_bold">
//                  А пока переходите к следующему шагу, чтобы получить максимум баллов за свой отзыв!
//                 </span>
//             </p>
//         </div>

//         <button class="btn_1 reviews-url-button" id="reviews-url-button-next">Следующий шаг</button>
//     `,
//     4: `
//         <h3 class="info_popup_heading">Ого, вы выполнили программу максимум! 😍</h3>

//         <div class="info_popup_descr">
//             <span class="info_popup_descr_text">
//                 Спасибо, что помогаете нам распространять важную мысль: “каждый человек способен избавиться от препятствий, мешающих ему жить полноценной жизнью”
//             </span>
//         </div>

//         <button class="btn_1 reviews-url-button" id="reviews-url-button-next">Закрыть</button>
//     `,
//   };

//   const htmlToShow = isSuccess ? gratitude[step + 1] : content[step + 1];

//   // **
//   const reviewsModal = document.querySelector("#reviews_url_content");
//   if (!reviewsModal) return;

//   reviewsModal.innerHTML = "";
//   reviewsModal.insertAdjacentHTML("afterbegin", htmlToShow);

//   // **
//   if (!isSuccess) {
//     const input = document.querySelector("#review-url-input");
//     const inputMessage = document.querySelector("#review-url-input-error");
//     const checkbox = document.querySelector("#review-url-checkbox");
//     const submitBtn = document.querySelector("#reviews-url-button");

//     if (!input || !inputMessage || !checkbox || !submitBtn) {
//       return;
//     }

//     // **
//     checkbox.addEventListener("change", (e) => {
//       if (e.currentTarget.checked) {
//         checkbox.style.borderColor = "";
//       }
//     });

//     input.addEventListener("input", (e) => {
//       if (e.currentTarget.value.trim()) {
//         input.style.borderColor = "";
//         inputMessage.textContent = "";
//       }
//     });

//     // **
//     submitBtn.addEventListener("click", () => {
//       checkbox.style.borderColor = "";
//       input.style.borderColor = "";
//       inputMessage.textContent = "";

//       if (!input.value.trim().length) {
//         input.style.borderColor = "#e50202";
//         inputMessage.textContent = `пожалуйста, введите ссылку`;
//       }

//       if (!checkbox.checked) {
//         checkbox.style.borderColor = "#e50202";
//       }
//     });

//     submitBtn.addEventListener("click", onReviewSubmit);
//   }

//   // **
//   if (isSuccess) {
//     let nextBtn = document.querySelector("#reviews-url-button-next");
//     nextBtn?.addEventListener("click", onNextBtnClick);
//   }
// };

// setHTML(currentStep);
// NEW END
