import Config from "./modules/Config";
import ProductsDisplay from "./modules/ProductsDisplay";
import {
  api,
  getIsEn,
  getGSessionIds,
  getCart,
  setCart,
  setCartItem,
  stripCart,
  addMorePopup,
  once,
  createTreshhold,
  makeElement,
  getCookie,
  setCookie,
  deleteCookie,
  getKladrId,
  getKladrIdParsed,
  setKladrId,
  getUuid,
  getCountry,
  showCloudpayments,
} from "./Utils";

(() => {
  const apiHost = Config.apiUrl;
  const urlParams = new URLSearchParams(window.location.search);
  var is_en = getIsEn();
  var deliveryMethod = "";
  var previousDeliveryData = {};
  var showedPromotions = {};
  var maxEnPricePopupShowed = false;
  var secondStepPromotionsToShow = [];
  var zeroDeliveryPrice = false;
  var zeroDeliveryPriceForced = false;
  var citySelectedFromList = false;
  var oldCityValue = "";
  var productIdsWithPrice = [];
  var selectedPvz;
  var selectedPvzInfo;
  var payType = "card";
  var noQrCodePay = false;
  var fromReview = sessionStorage.from_review ? true : false;
  var profitSum = 0;
  var showCart = [];
  var AddedRecommendationIds = [];
  var showForcedProductIds = [];
  var stepOneBtnPressed = false;
  var haveCompletedGoalDefined = false;
  var haveCompletedGoal = false;
  var hiddenProducts = [];
  var tags = [];

  const realDeliveryPriceField = document.querySelector(".js-checkout__delivery-price");
  const promoField = document.querySelector('input[name="coupon"]');
  const applyPromo = document.querySelector('span[class="b-checkout__coupon-btn"]');
  const firstPreorder = document.querySelector('button[data-goto-step_n="2"]');
  const secondPreorder = document.querySelector('button[data-goto-step_n="3"]');
  const finalButton = document.querySelector('div[class="b-checkout__submit-wraper"]').children[0];
  const countryField = document.querySelector('input[name="country"]');
  const firstNameField = document.querySelector('input[name="first_name"]');
  const lastNameField = document.querySelector('input[name="last_name"]');
  const emailField = document.querySelector('input[name="email"]');
  const phoneField = document.querySelector('input[name="phone"]');
  const cityField = document.querySelector('input[name="city"]');
  const streetField = document.querySelector('input[name="street"]');
  const buildingField = document.querySelector('input[name="dom"]');
  const apartmentField = document.querySelector('input[name="room"]');
  const zipField = document.querySelector('input[name="zip"]');
  const kindergiftField = document.querySelector('input[name="kids_gift"]');
  const paymentMethodsContainer = document.querySelector(".b-checkout__payments");
  const cityLabel = document.querySelector('label[for="id-city-kladr"]');
  const streetLabel = document.querySelector('label[for="id-street-kladr"]');
  const cfiAgreeField = document.querySelector("#cfi_agree");
  const cfiAgree2Field = document.querySelector("#cfi_agree_2");
  const pvzSelector = document.querySelector("#delivery_type_pvz");
  const doorSelector = document.querySelector("#delivery_type_door");
  const address2 = document.querySelector("#address2");
  const pvz2 = document.querySelector("#pvz2");
  const pvzSearch = document.querySelector("#pvz-search");
  const deliverySelectionBox = document.querySelector(".delivery-selection");
  const deliverySelectionPvz = document.querySelector("#delivery-selection-pvz");
  const pvzsList = document.querySelector(".pvzs-list");
  const pvzsListShowHide = document.querySelector(".pvzs-list-showhide");

  const updateCityLable = () => {
    if (is_en) {
      cityLabel.innerHTML = `Город*`;
    } else {
      cityLabel.innerHTML = `Город* - <b>выберите из списка</b>`;
    }
    if (is_en) {
      streetLabel.innerHTML = `Улица*`;
    } else {
      streetLabel.innerHTML = `Улица* - <b>выберите из списка</b>`;
    }
  };

  const rerenderPayMethods = () => {
    const country = getCountry();
    const test_group = getCookie("test_group");
    api(
      "get",
      `/what-acquiring-will-be-used?country_name=${country}&test_group=${test_group}`,
    ).then((d) => {
      document.querySelector("#avangard-text").classList.add("hidden");

      if (d.acquiring == "Продамус") {
        paymentMethodsContainer.innerHTML = `<li>
                    <input class="b-checkout__input-radio" id="cfi_payment_card" name="pay-method" type="radio" checked="">
                    <label for="cfi_payment_card"><span>Банковская карта</span>
                    </label>
                    <img style="heigpht: 21px;" src="static/img/content/paycards.png" alt="">
                </li>`;
        document.querySelector("#cfi_payment_card").addEventListener("click", () => {
          payType = "card";
        });
      } else if (d.acquiring == "Авангард") {
        document.querySelector("#avangard-text").classList.remove("hidden");
        paymentMethodsContainer.innerHTML = `<li>
                    <input class="b-checkout__input-radio" id="cfi_payment_card" name="pay-method" type="radio" checked="">
                    <label for="cfi_payment_card"><span>Банковская карта</span>
                    </label>
                    <img style="height: 21px;" src="/static/img/content/paycards.png" alt="">
                </li><li>
                    <input class="b-checkout__input-radio" id="cfi_payment_qr" name="pay-method" type="radio">
                    <label for="cfi_payment_qr"><span>Оплата по QR коду</span>
                    </label>
                    <img style="height: 30px;" src="/static/img/logo_sbp.png" alt="">
                    <img style="height: 30px;" src="/static/img/logo_mir.png" alt="">
                </li>`;
        document.querySelector("#cfi_payment_card").addEventListener("click", () => {
          payType = "card";
        });
        document.querySelector("#cfi_payment_qr").addEventListener("click", () => {
          payType = "qr";
        });
      } else {
        paymentMethodsContainer.innerHTML = "";
        payType = "card";
      }
    });
  };

  const setCountry = (newCountry) => {
    setCookie("country", newCountry);
    if (
      newCountry != "Россия" &&
      newCountry != "Беларусь" &&
      newCountry != "Казахстан" &&
      newCountry != "Кыргызстан" &&
      newCountry != "Армения"
    ) {
      is_en = true;
    } else {
      is_en = false;
    }

    updateCityLable();
    if (newCountry == "Россия") $(phoneField).inputmask("+7 (999) 999-9999");
    else if (newCountry == "Казахстан") $(phoneField).inputmask("+7 (999) 999-9{0,4}");
    else if (newCountry == "Беларусь")
      $(phoneField).inputmask("(+375-99-999-99-9{0,2}|+7 (999) 999-9{0,4})");
    else {
      $(phoneField).inputmask("+9{0,30}");
    }
    if (is_en) {
      zipField.setAttribute("data-required", "");
      zipField.parentElement.classList.remove("hidden");
      deliverySelectionPvz.classList.add("hidden");
    } else {
      zipField.removeAttribute("data-required");
      zipField.parentElement.classList.add("hidden");
      deliverySelectionPvz.classList.remove("hidden");
    }

    if (newCountry != "Россия" && newCountry != "Беларусь") {
      pvzSearch.classList.add("hidden");
    } else {
      pvzSearch.classList.remove("hidden");
    }

    rerenderPayMethods();
  };

  const updateCountry = (country = null) => {
    country = country || getCountry();
    countryField.value = country;
    const oldEn = is_en;
    setCountry(country);
    if (oldEn != is_en) {
      updatePrices().then(() => {});
    }
  };

  const saveFields = () => {
    setCookie("first_name", firstNameField.value);
    setCookie("last_name", lastNameField.value);
    setCookie("email", emailField.value);
    setCookie("phone", phoneField.value);
    setCookie("city", cityField.value);
    setCookie("street", streetField.value);
    setCookie("building", buildingField.value);
    setCookie("apartment", apartmentField.value);
    setCookie("delivery_method", deliveryMethod);
    setCookie("selected_pvz", JSON.stringify(selectedPvzInfo));
    setCookie("pvz_search", pvzSearch.value);
  };

  const restoreFields = () => {
    firstNameField.value = getCookie("first_name");
    lastNameField.value = getCookie("last_name");
    if (!emailField.value) {
      emailField.value = getCookie("email");
    }
    phoneField.value = getCookie("phone");
    cityField.value = getCookie("city");
    streetField.value = getCookie("street");
    buildingField.value = getCookie("building");
    apartmentField.value = getCookie("apartment");
    oldCityValue = cityField.value;

    let selected_pvz = getCookie("selected_pvz");
    try {
      selected_pvz = JSON.parse(selected_pvz);
      selectedPvz = selected_pvz.code;
      selectedPvzInfo = selected_pvz;
    } catch {}
    pvzSearch.value = getCookie("pvz_search");

    deliveryMethod = getCookie("delivery_method");
    if (is_en) {
      deliveryMethod.replace("cdek", "dhl");
    } else {
      deliveryMethod.replace("dhl", "cdek");
    }
  };

  const restoreStep = () => {
    if (localStorage.step_n == 2 || localStorage.step_n == 3) {
      firstPreorder.dispatchEvent(new Event("click"));
    }
    // else if (localStorage.step_n == 3) {
    //     secondPreorder.dispatchEvent(new Event('click'))
    // }
  };

  const updateDiscountLevel = (lvl_data, valid_promo = false) => {
    const lvl = document.querySelector(".checkhout-discount-levels");
    const lvlCopy = document.querySelector("#levels-copy");
    const lvlPreloader = document.querySelector(".discount-levels-preloader");
    if (lvl_data) {
      const lvlNumber = lvl_data.number;
      lvl.classList.remove("hidden");
      const lvls = document.querySelector(".discount-levels");
      lvls.className = `discount-levels discount-levels-${lvlNumber}`;
      const text = lvl.querySelector(".discount-levels-footer");
      text.innerHTML = lvl_data.text;

      const cartSum = document.querySelector(".b-checkout-cart__subtotal-value s");
      const cartSumDiscount = document.querySelector(
        ".b-checkout-cart__subtotal-value .js-checkout__total_products",
      );
      const profit = lvl.querySelector(".discount-levels-profit b span");
      profit.innerText = profitSum;
      if (profitSum) {
        profit.parentElement.classList.remove("discount-levels-no-profit");
      } else {
        profit.parentElement.classList.add("discount-levels-no-profit");
      }

      lvlCopy.classList.remove("hidden");
      lvlCopy.innerHTML = lvl.innerHTML;
    } else {
      lvl.classList.add("hidden");
      lvlCopy.classList.add("hidden");
    }
    lvlPreloader.classList.add("hidden");
  };

  const clearProducts = () => {
    const container = document.querySelector('div[class="b-checkout-cart__items"]');
    container.innerHTML = "";
    const popupContainer = document.querySelector(".popaps_more_container");
    popupContainer.innerHTML = "";
  };

  const hideProduct = (productId) => {
    if (!hiddenProducts.find((x) => x == productId)) {
      hiddenProducts.push(productId);
    }

    const cartLine = document.querySelector(`*[data-cart_line_id="${productId}"]`);
    if (cartLine) {
      cartLine.remove();
    }
  };

  const addProduct = (product, valid_promo = false, mergedIds = []) => {
    const cart = getCart();
    let already_in_cart = false;
    for (let i of cart) {
      if (i.id == product.product_id && i.quantity && i.quantity > 0) already_in_cart = true;
    }

    if (
      showForcedProductIds.indexOf(product.product_id) == -1 &&
      hiddenProducts.indexOf(product.product_id) >= 0
    )
      return;

    if (!product.show_in_cart && showForcedProductIds.indexOf(product.product_id) == -1) return;

    if (showCart.length) {
      let in_show_cart = false;
      for (let i of cart) {
        if (i.id == product.product_id) in_show_cart = true;
      }
      if (!in_show_cart) return;
    }

    const item = cart.find((item) => item.id == product.product_id);

    const container = document.querySelector('div[class="b-checkout-cart__items"]');
    const alreadyExistItem = container.querySelector(
      `div[data-cart_line_id="${product.product_id}"]`,
    );
    if (alreadyExistItem) {
      alreadyExistItem.querySelector(".js-checkout__quantity").value = item ? item.quantity : 0;
      return;
    }

    const productName = product.show_popup_in_cart
      ? `<a href="" data-fancybox data-src="#more_popap${product.product_id}" class="b-checkout-cart__item-line-cell b-checkout-cart__item-line-cell--name">
        <span>${product.name}<em class="pgr">${product.description}</em></span>
        </a>`
      : `<div class="b-checkout-cart__item-line-cell b-checkout-cart__item-line-cell--name">
        <span>${product.name}<em class="pgr">${product.description}</em></span>
        </div>`;

    var newItem;
    if (product.realisation_type == "promotion") {
      newItem =
        makeElement(`<div class="js-checkout-cart__item b-checkout-cart__item-line promotion-cart-item" data-cart_show_index="${
          product.show_index
        }" data-cart_line_id="${product.product_id}">
            <div class="b-checkout-cart__item-line-cell b-checkout-cart__item-line-cell--pic">
                <img class="b-checkout-cart__item-line-pic" class=""
                    src="${product.round_img}" alt="">
            </div>
            ${productName}
            <div
                class="b-checkout-cart__item-line-cell b-checkout-cart__item-line-cell--quantity">
                <div class="js-quantity b-quantity">
                    <div class="b-quantity__btn b-quantity__btn--down vhidden">-</div>
                    <input disabled class="js-checkout__quantity b-quantity__input" type="number"
                        value="${item ? item.quantity : 0}" min="0">
                    <div class="b-quantity__btn b-quantity__btn--up vhidden">+</div>
                </div>
            </div>
            <div class="b-checkout-cart__item-line-cell b-checkout-cart__item-line-cell--price">
                ${
                  valid_promo ? "<s>" + (is_en ? product.price.en : product.price.ru) + "</s>" : ""
                } <span class="js-checkout__price">${
          product.price.promo
        }</span> <span class="ruble">${is_en ? "$" : "₽"}</span>
            </div>
        </div>`);
    } else {
      newItem =
        makeElement(`<div class="js-checkout-cart__item b-checkout-cart__item-line" data-cart_show_index="${
          product.show_index
        }" data-cart_line_id="${product.product_id}">
            <div class="b-checkout-cart__item-line-cell b-checkout-cart__item-line-cell--pic">
                <img class="b-checkout-cart__item-line-pic" class=""
                    src="${product.round_img}" alt="">
            </div>
            ${productName}
            <div
                class="b-checkout-cart__item-line-cell b-checkout-cart__item-line-cell--quantity">
                <div class="js-quantity b-quantity">
                    <div class="b-quantity__btn b-quantity__btn--down">-</div>
                    <input class="js-checkout__quantity b-quantity__input" type="number"
                        value="${item ? item.quantity : 0}" min="0">
                    <div class="b-quantity__btn b-quantity__btn--up">+</div>
                </div>
            </div>
            <div class="b-checkout-cart__item-line-cell b-checkout-cart__item-line-cell--price">
                ${
                  valid_promo ? "<s>" + (is_en ? product.price.en : product.price.ru) + "</s>" : ""
                } <span class="js-checkout__price">${
          product.price.promo
        }</span> <span class="ruble">${is_en ? "$" : "₽"}</span>
            </div>
        </div>`);
    }

    const cartItems = document.querySelectorAll("*[data-cart_show_index]");
    let inserted = false;
    for (const item of cartItems) {
      if (item.dataset.cart_show_index > product.show_index) {
        item.before(newItem);
        inserted = true;
        break;
      }
    }
    if (!inserted) {
      container.appendChild(newItem);
    }

    if (mergedIds.indexOf(product.product_id) != -1) return;

    addMorePopup(product);
  };

  const updateSum = () => {
    document.querySelector(".js-checkout__quantity").dispatchEvent(new Event("change"));
    const cartItems = document.querySelectorAll(".js-checkout-cart__item");
    const finalCartContainer = document.querySelector('ul[class="b-checkout__totals-items"]');
    finalCartContainer.querySelectorAll("#final-cart-item").forEach((e) => {
      e.remove();
    });
    cartItems.forEach((e) => {
      const name = e.querySelector("span").innerHTML.split("<")[0];
      const price = e.querySelector(".js-checkout__price").innerText;
      const quantity = e.querySelector(".js-checkout__quantity").value;
      if (quantity && quantity > 0) e.style.backgroundColor = price ? "#edffef" : "#ffffed";
      else e.style.backgroundColor = "unset";

      if (quantity && quantity != 0) {
        console.log(e, price);
        const newLine = makeElement(`<li id="final-cart-item" class="b-checkout__totals-items-row">
                <span class="b-checkout__totals-items-row-key">${name} x ${quantity}</span>
                <span class="b-checkout__totals-items-val"><span class="js-checkout__subtotal">${
                  price * quantity
                }</span> <span class="ruble"></span></span>
                </li>`);
        finalCartContainer.insertBefore(newLine, finalCartContainer.firstChild);
      }
    });
    const currensies = [...document.querySelectorAll('span[class="ruble"]')];
    currensies.forEach((e) => {
      if (is_en) e.innerText = "$";
      else e.innerText = "₽";
    });
    dispatchEvent(new Event("update-total-cart-sum"));
  };

  const toggleDeliverySelectionBox = () => {
    if (is_en) deliverySelectionPvz.classList.add("hidden");
    else deliverySelectionPvz.classList.remove("hidden");
    if (cityField.value) deliverySelectionBox.classList.remove("hidden");
    else deliverySelectionBox.classList.add("hidden");
  };

  const updateCountriesList = () => {
    fetch(apiHost + "/countries")
      .then((r) => r.json())
      .then((d) => {
        const currentCountry = getCountry();
        const countryList = document.querySelector(
          'ul[class="js-form-dropdown__list b-form-dropdown__list"]',
        );
        countryList.innerHTML = ``;
        d.forEach((countryName) => {
          var e = makeElement(`<li>${countryName}</li>`);
          countryList.appendChild(e);
          e.addEventListener("click", () => {
            updateCountry(e.innerText);
            resetDeliveryMethod();
            cityField.value = "";
            toggleDeliverySelectionBox();
            streetField.value = "";
            buildingField.value = "";
            apartmentField.value = "";
            updateDelivery();
          });
          if (countryName == currentCountry) {
            const oldCity = cityField.value;
            const oldStreet = streetField.value;
            const oldBuilding = buildingField.value;
            const oldApartment = apartmentField.value;
            const oldDeliveryMethod = deliveryMethod;
            e.dispatchEvent(new Event("click"));
            setTimeout(() => {
              cityField.value = oldCity;
              streetField.value = oldStreet;
              buildingField.value = oldBuilding;
              apartmentField.value = oldApartment;
              deliveryMethod = oldDeliveryMethod;
              toggleDeliverySelectionBox();
            }, 300);
          }
        });
        updateSum();
      });
  };

  const updateCookieCart = () => {
    const cartItems = [...document.querySelectorAll(".js-checkout-cart__item")];
    const cart = cartItems.map((item) => {
      const quantity = item.querySelector(".js-checkout__quantity").value;
      const id = item.getAttribute("data-cart_line_id");
      return {
        id: parseInt(id),
        quantity: parseInt(quantity),
      };
    });
    setCookie("basket", JSON.stringify(cart));
  };

  // use only if you will update normal cart price after this
  const setCartSumWithoutDiscount = (sum, realSum) => {
    const total = document.querySelector(".b-checkout-cart__subtotal-value");
    total.querySelector("s").innerText = sum;
    if (sum != realSum) {
      total.querySelector("s").innerText = sum;
    } else {
      total.querySelector("s").innerText = "";
    }
  };

  const addPromotionProductIfItsNeeded = (data, cart) => {
    const products = data.products;
    const promotions = data.promotions;
    const promoItems = [...document.querySelectorAll(".promotion-cart-item")];
    promoItems.forEach((e) => {
      if (showForcedProductIds.indexOf(parseInt(e.dataset.cart_line_id)) == -1) {
        e.remove();
      }
    });

    tags = []

    zeroDeliveryPrice = false;
    const loyaltyGifts = getCart("gifts_cart", "session");
    for (const product of products) {
      if (product.realisation_type == "promotion") {
        for (const i in cart) {
          if (cart[i].id == product.product_id) {
            const gift = loyaltyGifts.find((x) => x.id == cart[i].id);
            const oldQuantity = cart[i].quantity
            if (gift) {
              cart[i].quantity = gift.quantity;
            } else {
              cart[i].quantity = 0;
            }

            const currentQuantity = cart[i].quantity
            if (currentQuantity != oldQuantity) {
              setCookie("basket", JSON.stringify(cart));
              updatePrices(null, false, false).then(() => {});
            }
          }
        }
      }
    }
    document.querySelector(".kids-gift").classList.remove("hidden");

    var deactivated = [];
    for (const promotion of promotions) {
      if (!promotion.activation_count) continue;
      for (const action of promotion.actions) {
        if (action.action == "deactivate_promocode") {
          const currentPromo = getCookie("promo");
          if (currentPromo != action.deactivate_promocode) {
            console.log(`deactivate promotion cuz of promocode_deactivation ${promotion.id}`);
            deactivated.push(promotion.id);
          }
        }
      }
    }

    for (const promotion of promotions) {
      if (!promotion.activation_count) continue;
      for (const action of promotion.actions) {
        if (action.action == "deactivate_promotion") {
          let dpid = parseInt(action.deactivate_promotion_id);
          if (deactivated.indexOf(promotion.id) == -1) {
            console.log(`deactivate promotion ${action.deactivate_promotion_id}`);
            deactivated.push(dpid);
          }
        }
      }
    }

    for (const promotion of promotions) {
      if (!promotion.activation_count || deactivated.indexOf(parseInt(promotion.id)) != -1)
        continue;
      for (const action of promotion.actions) {
        const value = action.const_product_amount;
        if (action.action == "add_product_exp") {
          if (!action.gift_product_id) continue;

          const item = cart.find((x) => x.id == action.gift_product_id);
          if (item) {
            item.quantity = Math.max(value * promotion.activation_count, item.quantity);
          } else {
            cart.push({ id: action.gift_product_id, quantity: value * promotion.activation_count });
          }
          setCookie("basket", JSON.stringify(cart));
          const product = products.find((p) => p.product_id == action.gift_product_id);
          showForcedProductIds.push(product.product_id);
          updatePrices(null, false, false).then(() => {});
        } else if (action.action == "add_product_const") {
          if (!action.gift_product_id) continue;

          const item = cart.find((x) => x.id == action.gift_product_id);
          if (item) {
            item.quantity = Math.max(value, item.quantity);
          } else {
            cart.push({ id: action.gift_product_id, quantity: value ? value : 0 });
          }
          setCookie("basket", JSON.stringify(cart));
          const product = products.find((p) => p.product_id == action.gift_product_id);
          showForcedProductIds.push(product.product_id);
          updatePrices(null, false, false).then(() => {});
        } else if (action.action == "hide_product") {
          setCartItem(action.gift_product_id, 0);
          hideProduct(action.gift_product_id);
        } else if (action.action == "zero_delivery_price") {
          zeroDeliveryPrice = true;
        } else if (action.action == "hide_kindergift") {
          document.querySelector(".kids-gift").classList.add("hidden");
        } else if (action.action == "deactivate_promocode") {
          const currentPromo = getCookie("promo");
          if (currentPromo != action.deactivate_promocode) continue;

          setCookie("promo", "");
          promoField.value = "";
          updatePrices(null, true);
          invalidPromoVisual();
        } else if (action.action == "add_tag" && action.tag) {
          tags.push(action.tag)
        } else {
          console.log(`wrong action '${action.action}'`);
        }
      }
    }
  };

  const addPromotionPopups = (data) => {
    const promotions = data.promotions;
    var promotionPopupsContainer = document.getElementById("promotion-popups");
    if (promotionPopupsContainer) {
      promotionPopupsContainer.innerHTML = "";
      for (const promotion of promotions) {
        const popUpStyle = document.createElement("style");
        popUpStyle.innerHTML = `.promotion-popup-style${promotion.id} {background-color: ${promotion.popups_color};border-radius: ${promotion.popups_radius}px;}`;
        document.head.appendChild(popUpStyle);
        const popUpBtnStyle = document.createElement("style");
        popUpBtnStyle.innerHTML = `.promotion-btn-style${promotion.id}:after {background: linear-gradient(90deg, ${promotion.popup_buttons_color1} 0.22%, ${promotion.popup_buttons_color2} 99.79%);};`;
        document.head.appendChild(popUpBtnStyle);
        const newPopup = document.createElement("div");
        newPopup.classList.add("new-popup");
        newPopup.classList.add(`promotion-popup-style${promotion.id}`);
        newPopup.id = `promotion-popup-${promotion.id}`;
        newPopup.innerHTML = `<img class="desktop" src="${
          promotion.cart_image_url
        }"><div class="body">${promotion.cart_text}
                    <img class="mobile" src="${
                      promotion.cart_image_url
                    }"><a class="order_btn promotion_btn promotion-btn-style${
          promotion.id
        }" onClick="$.fancybox.close(); window.scrollTo(0, 0);">${promotion.cart_button_text}</a>
                    ${
                      promotion.cart_show_continue_button
                        ? '<a class="order_btn close" onClick="$.fancybox.close()">Продолжить</a>'
                        : ""
                    }
                </div>`;
        promotionPopupsContainer.appendChild(newPopup);
        if (!showedPromotions[promotion.id] && promotion.show_cart_popup) {
          if (promotion.show_cart_condition == "time") {
            showedPromotions[promotion.id] = true;
            setTimeout(() => {
              const e = new Event("show-promotion-popup");
              e.id = newPopup.id;
              dispatchEvent(e);
            }, promotion.show_cart_after_seconds * 1000);
          } else {
            secondStepPromotionsToShow.push({ popupId: newPopup.id, promotionId: promotion.id });
          }
        }
      }
    }
  };

  const canRecommendProducts = () => {
    const cart = getCart();
    let isCart = false;
    for (const item of cart) {
      isCart = item.quantity || isCart;
    }
    return isCart;
  };

  const updateProductRecomendationsSlider = () => {
    if (!canRecommendProducts()) {
      return;
    }
    const slider = $(".product-recommendations-slider");
    try {
      slider.slick("unslick");
    } catch {}
    slider.slick({
      arrows: true,
      infinite: true,
      speed: 300,
      slidesToShow: 2,
      slidesToScroll: 1,
      adaptiveHeight: true,
    });
  };

  const updateProductRecomendations = (d) => {
    const recommendations = document.querySelector(".product-recommendations");
    if (!canRecommendProducts()) {
      recommendations.classList.add("hidden");
      return;
    }
    recommendations.classList.remove("hidden");
    const slider = recommendations.querySelector(".product-recommendations-slider");

    fetch(apiHost + "/product-recommendations", {
      method: "post",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        country: getCountry(),
        cart: getCart(),
      }),
    })
      .then((r) => r.json())
      .then((scores) => {
        scores = scores.sort((f, s) => f.score < s.score);

        slider.innerHTML = "";
        for (const score of scores) {
          const product = d.products.find((x) => x.product_id == score.id);
          if (!product) {
            continue;
          }

          const noDiscountPrice = is_en ? product.price.en : product.price.ru;
          const discountPrice = product.price.promo;

          const newProduct = document.createElement("div");
          newProduct.classList.add("recomended-product");
          newProduct.innerHTML = `
            <img src="${product.round_img}">
            <h6>${product.name}</h6>
            <p>${product.description}</p>
            <div class="recomended-product-price"><s>${
              noDiscountPrice == discountPrice ? "" : noDiscountPrice
            }</s> ${discountPrice} <span class="ruble">${is_en ? "$" : "₽"}</span></div>
            <button class="button">Добавить в заказ</button>
          `;
          const btn = newProduct.querySelector("button");
          btn.addEventListener("click", (e) => {
            e.preventDefault();
            ym(57109408, "reachGoal", "upsale");
            const clickEvent = new Event("click");
            document
              .querySelector(`div[data-cart_line_id="${product.product_id}"] .b-quantity__btn--up`)
              .dispatchEvent(clickEvent);
            AddedRecommendationIds.push(product.product_id);
            btn.parentElement.remove();
          });
          slider.appendChild(newProduct);
        }

        updateProductRecomendationsSlider();
      });
  };

  let fetchPricesCache = {}
  const fetchPrices = (data) => {
    const dataStr = JSON.stringify(data)
    if (fetchPricesCache[dataStr]) {
      return new Promise((resolve, reject) => {
        resolve(fetchPricesCache[dataStr])
      })
    }

    return new Promise((resolve, reject) => {
      fetch(apiHost + "/prices", {
        method: "post",
        headers: {
          "content-type": "application/json",
        },
        body: dataStr,
      })
        .then((r) => r.json())
        .then((d) => {
          fetchPricesCache[dataStr] = d
          resolve(d)
        })
      })
  }

  const updatePrices = (promocode = null, refresh = false, raisePromotions = true) => {
    promocode = promocode || getCookie("promo");
    setCookie("promo", promocode);
    return new Promise((resolve, reject) => {
      const cartItems = [...document.querySelectorAll(".js-checkout-cart__item")];
      const data = {
        uuid: getCookie('uuid'),
        cart: stripCart(getCart()),
        country: getCountry(),
        promo: promocode,
        kindergift: kindergiftField.checked,
        test_group: getCookie("test_group"),
      };
      fetchPrices(data)
        .then((d) => {
          productIdsWithPrice = [];
          for (const product of d.products) {
            if (product.price.promo) productIdsWithPrice.push(product.product_id);
          }

          const cart = getCart();
          for (const item of cart) {
            const product = d.products.find((x) => x.product_id == item.id);
            if (!product) {
              item.quantity = 0;
            }
          }
          setCookie("basket", JSON.stringify(cart));

          const countPrice = (product) => {
            const cartItem = cart.find((x) => x.id == product.product_id);
            if (!cartItem) {
              return 0;
            } else {
              return is_en
                ? product.price.en * cartItem.quantity
                : product.price.ru * cartItem.quantity;
            }
          };
          var isDiscounts = false;
          if (!refresh) {
            clearProducts();
            const formatedProducts = ProductsDisplay.transformProducts(d.products, is_en);
            const mergedIds = ProductsDisplay.fetchMergedIds(formatedProducts);
            d.products.forEach((p) => {
              const price = is_en ? p.price.en : p.price.ru;
              const promoPrice = p.price.promo;
              if (price > promoPrice) isDiscounts = true;

              if (
                p.realisation_type == "shop" ||
                (p.realisation_type == "promotion" &&
                  showForcedProductIds.indexOf(p.product_id) != -1)
              ) {
                if (
                  p.realisation_type == "shop" ||
                  (haveCompletedGoalDefined && haveCompletedGoal)
                ) {
                  addProduct(p, price > promoPrice, mergedIds);
                } else {
                  const await_and_add = () => {
                    if (haveCompletedGoalDefined) {
                      if (haveCompletedGoal) {
                        addProduct(p, price != promoPrice, mergedIds);
                      }
                    } else {
                      setTimeout(await_and_add, 100);
                    }
                  };
                  await_and_add();
                }
              }
            });
            addPromotionPopups(d);
            ProductsDisplay.displayMergedProducts(formatedProducts);
            dispatchEvent(new Event("cartUpdated"));
          } else {
            d.products.forEach((p) => {
              const item = cartItems.find(
                (i) => i.getAttribute("data-cart_line_id") == p.product_id,
              );
              if (!item) return;
              const priceContainer = item.querySelector(".b-checkout-cart__item-line-cell--price");
              const price = is_en ? p.price.en : p.price.ru;
              const promoPrice = p.price.promo;
              if (price > promoPrice) isDiscounts = true;
              if (price > promoPrice) {
                priceContainer.innerHTML = `<s>${price}</s> <span class="js-checkout__price">${promoPrice}</span> <span class="ruble">${
                  is_en ? "$" : "₽"
                }</span>`;
              } else {
                priceContainer.innerHTML = `<span class="js-checkout__price">${promoPrice}</span> <span class="ruble">${
                  is_en ? "$" : "₽"
                }</span>`;
              }
            });
          }
          if (raisePromotions) {
            addPromotionProductIfItsNeeded(d, cart);
          }
          setTimeout(() => {
            updateSum();
            const sum = document.querySelector(".js-checkout__total_products").innerText;
            const noDiscountsSum = d.products.map(countPrice).reduce((s, x) => s + x);
            setCartSumWithoutDiscount(noDiscountsSum, sum);
            profitSum = noDiscountsSum - sum;
            updateDiscountLevel(d.current_discount_level, d.valid_promo);
          }, 150);
          setTimeout(() => {
            const sum = document.querySelector(".js-checkout__total_products").innerText;
            if (sum >= 240 && is_en) {
              setCookie("basket", "[]");
              updatePrices(promocode, true).then((d) => {});
              showMaxSumRestriction();
            }
          }, 1000);
          resolve(d);
          dispatchEvent(new Event("products-loaded"));
          setTimeout(() => updateProductRecomendations(d), 100);
        });
    });
  };

  const showLoading = () => {
    document.querySelector("body").appendChild(
      makeElement(`
        <div class="full-screen-loading" style="z-index: 1000;left: 0;top: 0;width: 100vw;height: 100vh;position: fixed;background-color: #ffffff80;"><img style="position: relative;left: 50%;top: 50%;translate: -50% -50%;" src="/static/img/loading.gif"></div>
      `),
    );
  };

  const hideLoading = () => {
    document.querySelectorAll(".full-screen-loading").forEach((e) => e.remove());
  };

  const order = () => {
    const cart = getCart();
    var quantity = 0;
    for (const item of cart) {
      if (productIdsWithPrice.indexOf(item.id) != -1) quantity += item.quantity;
    }
    if (!quantity) {
      warningPopup("no-products-popup", "Добавьте хотя бы один товар.");
      return;
    }

    if (is_en) {
      for (const item of cart) {
        if (item.quantity > 0 && productIdsWithPrice.indexOf(item.id) == -1) {
          warningPopup(
            "no-gifts-for-usd",
            "К сожалению мы не можем отправлять подарки в вашу страну из-за томоженных ограничений 😢😢😢",
          );
          return;
        }
      }
    }

    const kladrId = getKladrIdParsed();
    const data = {
      uuid: getUuid(),
      country: getCountry(),
      mail: emailField.value,
      phone: phoneField.value,
      first_name: firstNameField.value,
      last_name: lastNameField.value,
      city: cityField.value,
      city_kladr_id: kladrId.city_kladr_id,
      region: kladrId.region,
      street: streetField.value,
      build: buildingField.value,
      flat: apartmentField.value,
      zip: is_en ? zipField.value : kladrId.zip,
      cart: stripCart(cart),
      pay_type: payType,
      promo: getCookie("promo"),
      delivery_method: deliveryMethod,
      kindergift: kindergiftField.checked,
      zero_delivery_price: zeroDeliveryPrice || zeroDeliveryPriceForced,
      test_group: getCookie("test_group"),
      pvz_address:
        deliveryMethod == "cdek_pvz" && selectedPvzInfo ? selectedPvzInfo.address : undefined,
      pvz_id: deliveryMethod == "cdek_pvz" ? selectedPvz : undefined,
      ref_uuid: getCookie("ref") || null,
      cart_step: 4,
      gift_products: getCart("gifts_cart", "session"),
      was_on_newsite_redirection: getCookie("was_on_newsite_redirection") || false,
      tags: tags,
    };

    showLoading();
    fetch(apiHost + "/order", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((r) => r.json())
      .then((d) => {
        hideLoading();

        if (!deliveryMethod) {
          warningPopup(
            "no-delivery-popup",
            "Доставка до указанного города не осуществляется. Пожалуйста, выберите город из списка. Для выбора нажмите на нужный вам город при появлении списка.",
          );
        } else if (d.detail) {
          warningPopup("no-delivery-popup", d.detail);
        } else if (d.acquiring == "Авангард" || d.acquiring == "Продамус") {
          window.location.assign(d.pay_url);
        } else if (d.acquiring == "CloudPayments") {
          showCloudpayments(d.public_id, d.lead_id, d.amount, d.is_en, false);
        } else {
          console.error(`wrong acquiring value`, d);
        }
      });
    ym(57109408, "reachGoal", "basket_gotopay");
  };

  const resetDeliveryMethod = () => {
    pvzSelector.checked = false;
    doorSelector.checked = false;
    deliveryMethod = "";
    pvz2.classList.add("hidden");
    address2.classList.add("hidden");
    streetField.removeAttribute("data-required", "");
    buildingField.removeAttribute("data-required", "");
    address2.classList.add("hidden");
    streetField.removeAttribute("data-required", "");
    buildingField.removeAttribute("data-required", "");
    saveFields();
    setTimeout(updateDelivery, 100);
  };

  const setDoorDeliveryType = () => {
    pvzSelector.checked = false;
    doorSelector.checked = true;
    deliveryMethod = is_en ? "dhl_door" : "cdek_door";
    pvz2.classList.add("hidden");
    address2.classList.remove("hidden");
    streetField.setAttribute("data-required", "");
    buildingField.setAttribute("data-required", "");

    saveFields();
    setTimeout(updateDelivery, 100);
  };

  const setPvzDeliveryType = () => {
    doorSelector.checked = false;
    pvzSelector.checked = true;
    deliveryMethod = "cdek_pvz";
    address2.classList.add("hidden");
    streetField.removeAttribute("data-required", "");
    buildingField.removeAttribute("data-required", "");
    pvz2.classList.remove("hidden");

    saveFields();
    setTimeout(updateDelivery, 100);
  };

  const updateDelivery = () => {
    if (!phoneField.value || phoneField.value.endsWith("_") || phoneField.value.endsWith("-")) {
      return;
    }

    const kladrId = getKladrIdParsed();

    var data = {
      uuid: getUuid(),
      phone: phoneField.value,
      country: getCountry(),
      city: cityField.value,
      city_fias_id: kladrId.city_fias,
      city_kladr_id: kladrId.city_kladr_id,
      city_cdek_id: kladrId.city_cdek_id,
      street: streetField.value,
      building: buildingField.value,
      promo: getCookie("promo"),
      zip: is_en ? zipField.value : kladrId.zip,
      cart: stripCart(getCart()),
      zero_price: zeroDeliveryPrice || zeroDeliveryPriceForced,
      pvz_id: deliveryMethod == "cdek_pvz" ? selectedPvz : undefined,
      region: kladrId.region,
      from_source: "cart",
      test_group: getCookie("test_group"),
      was_on_newsite_redirection: getCookie("was_on_newsite_redirection") || false,
      tags: tags,
    };
    const newDeliveryData = { data: data, method: deliveryMethod };
    if (JSON.stringify(previousDeliveryData) == JSON.stringify(newDeliveryData)) return;
    previousDeliveryData = newDeliveryData;

    const deliveryBox = document.querySelector('div[class="b-checkout__delivery"]');
    deliveryBox.innerHTML =
      '<img src="/static/img/loading.gif" style="transform: translate(-50%, 0); position: relative; left: 50%; top: 50%;">';
    fetch(apiHost + "/delivery-price", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((r) => {
        try {
          return r.json();
        } catch {
          return {};
        }
      })
      .then((d) => {
        let error;
        let info;
        if (deliveryMethod == "cdek_pvz") {
          error = d.cdek_pvz.error;
          info = d.cdek_pvz;
        } else if (deliveryMethod == "cdek_door") {
          error = d.cdek.error;
          info = d.cdek;
        } else if (deliveryMethod == "dhl_door") {
          error = d.dhl.error;
          info = d.dhl;
        } else {
          error = cityField.value ? "Выберите способ доставки" : "Введите город";
        }
        let price;
        let termMin;
        let termMax;
        if (!error) {
          price = parseInt(info.price);
          termMin = info.term_min;
          termMax = info.term_max;
          realDeliveryPriceField.value = price;

          if (price) {
            const deliveryPrice = document.querySelector("#final-delivery-price");
            deliveryPrice.innerHTML = `<span class="js-checkout__delivery-price">${price}</span> <span class="ruble"></span>`;
          } else {
            const deliveryPrice = document.querySelector("#final-delivery-price");
            deliveryPrice.innerHTML = `<span>Бесплатно</span><span style="display:none;" class="js-checkout__delivery-price">0</span> <span style="display:none;" class="ruble"></span>`;
          }
        }
        const deliveryCompanyLogo = document.querySelector("#delivery-company-logo");
        deliveryCompanyLogo.src = is_en
          ? "static/img/content/dhl-black.webp"
          : "static/img/content/cdek.png";

        if (error) {
          deliveryBox.innerHTML = `<img src="static/img/content/${
            is_en ? "dhl-black" : "cdek"
          }.webp" alt="" class="b-checkout__delivery-pic">
                    <div class="b-checkout__delivery-data">
                    <div class="b-checkout__delivery-data-row">
                    <span class="b-checkout__delivery-data-key">${
                      error == "Введите город" ? "Введите город" : "Ошибка"
                    }</span><span class="b-checkout__delivery-data-val">${
            error == "Введите город" ? "" : error
          }</span>
                    </div>
                </div>`;
        } else {
          const photoPart =
            deliveryMethod == "cdek_pvz" && selectedPvzInfo && selectedPvzInfo.images
              ? (() => {
                  const imgs = [];
                  for (const img of selectedPvzInfo.images) {
                    imgs.push(
                      `<a onload="$.fancybox(this, {modal: true})" href="${img.url}" class="cdek-photo-container" data-fancybox="gallery" data-caption="Фотографии"><img class="cdek-photo" src="${img.url}"></a>`,
                    );
                  }
                  const imgs_html = imgs.join("");
                  return `<div class="b-checkout__delivery-data-row">
                            <div class="cdek-card">
                                <b>Фотографии</b>
                                <div class="cdek-photos">${imgs_html}</div>
                            </div>
                        </div>
                    </div>`;
                })()
              : "";
          const pvzPart =
            deliveryMethod == "cdek_pvz" && selectedPvzInfo
              ? `<div class="b-checkout__delivery-data-row">
                        <div class="cdek-card">
                            <b>Адрес</b>
                            <div>
                                <img src="/static/img/cdek_address.svg">
                                <p>${selectedPvzInfo.address}</p>
                            </div>
                            <div>
                                <img src="/static/img/cdek_address.svg">
                                <p>Тип пункта - ${
                                  selectedPvzInfo.type == "POSTAMAT" ? "Постамат" : "Пункт выдачи"
                                }</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="b-checkout__delivery-data-row">
                        <div class="cdek-card">
                            <b>Режим работы</b>
                            <div>
                                <img src="/static/img/cdek_clock.svg">
                                <p>${selectedPvzInfo.worktime}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="b-checkout__delivery-data-row">
                        <div class="cdek-card">
                            <b>Контакты пункта выдачи</b>
                            <div>
                                <img src="/static/img/cdek_phone.svg">
                                <p><a href="tel:${selectedPvzInfo.phone}">${
                  selectedPvzInfo.phone
                }</a></p>
                            </div>
                        </div>
                    </div>
                </div>
                </div>${photoPart}`
              : "";
          deliveryBox.innerHTML = `
                    <br>
                    <div class="b-checkout__delivery-data">
                        <div class="b-checkout__delivery-data-row">
                            <div class="cdek-card">
                                <img src="static/img/content/${
                                  is_en ? "dhl-black" : "cdek"
                                }.webp" alt="" class="b-checkout__delivery-pic">
                                <div class="delivery-row">
                                    <span class="b-checkout__delivery-data-key">Стоимость доставки</span>${
                                      price
                                        ? `<span class="b-checkout__delivery-data-val">${price} <span class="ruble"></span></span>`
                                        : '<span class="b-checkout__delivery-data-val">Бесплатно</span>'
                                    }
                                </div>
                                <div class="delivery-row">
                                    <span class="b-checkout__delivery-data-key">Время на доставку</span><span class="b-checkout__delivery-data-val">${termMin} - ${termMax} рабочих дней</span>
                                </div>


                            </div>
                        </div>
                    ${pvzPart}
                </div>`;
        }

        deliveryBox.insertBefore(
          makeElement(
            '<p id="scroll-after-selected">Закажите больше и доставка будет <b>ДЕШЕВЛЕ 😊</b></p>',
          ),
          deliveryBox.firstChild,
        );

        try {
          updateSum();
        } catch {}
      });
  };

  const setAutocomplete = (selector, itype) => {
    $(selector).autocomplete({
      delay: 1,
      source: (request, response) => {
        const q = request.term;
        const country = getCountry();
        if (is_en) {
          response([{ label: q, value: q, id: "{}" }]);
          return;
        }
        if (itype == "city") {
          fetch(
            apiHost +
              `/kladr-emulation?query=${q}&contentType=${itype}&normal_jsn=true&country=${country}`,
          )
            .then((r) => r.json())
            .then((d) => {
              response(
                d.result.map((e) => {
                  var id = JSON.parse(e.id);
                  var region_type = id.region_type ? " " + id.region_type : "";
                  var label = e.name + ` (${id.region}${region_type})`;
                  return { label: label, value: e.name, id: e.id };
                }),
              );
            });
        } else if (itype == "street") {
          const kladrId = getKladrId();
          fetch(
            apiHost +
              `/kladr-emulation?query=${q}&contentType=${itype}&normal_jsn=true&country=${country}&regionId=${kladrId}&cityId=${kladrId}`,
          )
            .then((r) => r.json())
            .then((d) => {
              if (!d.result.length && q.length <= 2) {
                streetField.labels[0].innerHTML = "Улица*";
              }

              if (d.result.length) {
                streetField.labels[0].innerHTML = "Улица* - <b>выберите из списка</b>";
              }
              response(
                d.result.map((e) => {
                  return { label: e.name, value: e.name, id: e.id };
                }),
              );
            });
        } else if (itype == "building") {
          const kladrId = getKladrId();
          fetch(
            apiHost +
              `/kladr-emulation?query=${q}&contentType=${itype}&normal_jsn=true&country=${country}&regionId=${kladrId}&cityId=${kladrId}&streetId=${kladrId}`,
          )
            .then((r) => r.json())
            .then((d) => {
              response(
                d.result.map((e) => {
                  return { label: e.name, value: e.name, id: e.id };
                }),
              );
            });
        }
      },
      select: (event, ui) => {
        if (itype == "city") {
          oldCityValue = ui.item.value;
          citySelectedFromList = true;
          toggleDeliverySelectionBox();
          setTimeout(updatePvzs, 100);
        }
        setTimeout(saveFields, 100);
        const kladrId = JSON.parse(decodeURIComponent(ui.item.id));
        const pkladrId = getKladrIdParsed();
        if (
          kladrId.region != pkladrId.region ||
          kladrId.city_kladr_id != pkladrId.city_kladr_id ||
          kladrId.city_fias != pkladrId.city_fias ||
          kladrId.street
        )
          setKladrId(JSON.stringify(kladrId));
        setTimeout(updateDelivery, 100);
      },
    });
  };

  const closeWarningPopup = (id) => {
    try {
      document.querySelector(`#${id}`).remove();
    } catch {}
  };

  const warningPopup = (id, text) => {
    closeWarningPopup(id);
    const popup =
      makeElement(`<div id="${id}" class="swal2-container swal2-center swal2-backdrop-show" style="overflow-y: auto;">
            <div aria-labelledby="swal2-title" aria-describedby="swal2-content"
                class="swal2-popup swal2-modal swal2-icon-warning swal2-show" tabindex="-1" role="dialog" aria-live="assertive"
                aria-modal="true" style="display: flex;">
                <div class="swal2-header">
                    <ul class="swal2-progress-steps" style="display: none;"></ul>
                    <div class="swal2-icon swal2-warning swal2-icon-show"
                        style="display: flex; color: rgb(249, 148, 79); border-color: rgb(249, 148, 79);">
                        <div class="swal2-icon-content">!</div>
                    </div><img class="swal2-image" style="display: none;">
                    <h2 class="swal2-title" id="swal2-title" style="display: block;">Внимание!</h2><button type="button"
                        class="swal2-close" style="display: none;" aria-label="Close this dialog">×</button>
                </div>
                <div class="swal2-content">
                    <div id="swal2-content" class="swal2-html-container" style="display: block;">${text}</div><input class="swal2-input" style="display: none;"><input type="file"
                        class="swal2-file" style="display: none;">
                    <div class="swal2-range" style="display: none;"><input type="range"><output></output></div><select
                        class="swal2-select" style="display: none;"></select>
                    <div class="swal2-radio" style="display: none;"></div><label for="swal2-checkbox" class="swal2-checkbox"
                        style="display: none;"><input type="checkbox"><span class="swal2-label"></span></label><textarea
                        class="swal2-textarea" style="display: none;"></textarea>
                    <div class="swal2-validation-message" id="swal2-validation-message" style="display: none;"></div>
                </div>
                <div class="swal2-actions">
                    <div class="swal2-loader"></div><button type="button" class="swal2-confirm swal2-styled"
                        style="display: inline-block; background-color: rgb(249, 148, 79);" aria-label="">ОК</button><button
                        type="button" class="swal2-deny swal2-styled" style="display: none;" aria-label="">No</button><button
                        type="button" class="swal2-cancel swal2-styled" style="display: none;" aria-label="">Cancel</button>
                </div>
                <div class="swal2-footer" style="display: none;"></div>
                <div class="swal2-timer-progress-bar-container">
                    <div class="swal2-timer-progress-bar" style="display: none;"></div>
                </div>
            </div>
        </div>`);
    document.body.append(popup);

    const as = document.querySelector(`#${id}`);
    as.addEventListener("click", () => {
      closeWarningPopup(id);
    });
  };

  const confirmPopup = (id, text, onConfirm) => {
    closeWarningPopup(id);
    const popup =
      makeElement(`<div id="${id}" class="swal2-container swal2-center swal2-backdrop-show" style="overflow-y: auto;">
            <div aria-labelledby="swal2-title" aria-describedby="swal2-content"
                class="swal2-popup swal2-modal swal2-icon-warning swal2-show" tabindex="-1" role="dialog" aria-live="assertive"
                aria-modal="true" style="display: flex;">
                <div class="swal2-header">
                    <ul class="swal2-progress-steps" style="display: none;"></ul>
                    <div class="swal2-icon swal2-warning swal2-icon-show"
                        style="display: flex; color: rgb(249, 148, 79); border-color: rgb(249, 148, 79);">
                        <div class="swal2-icon-content">!</div>
                    </div><img class="swal2-image" style="display: none;">
                    <h2 class="swal2-title" id="swal2-title" style="display: block;">Внимание!</h2><button type="button"
                        class="swal2-close" style="display: none;" aria-label="Close this dialog">×</button>
                </div>
                <div class="swal2-content">
                    <div id="swal2-content" class="swal2-html-container" style="display: block;">${text}</div><input class="swal2-input" style="display: none;"><input type="file"
                        class="swal2-file" style="display: none;">
                    <div class="swal2-range" style="display: none;"><input type="range"><output></output></div><select
                        class="swal2-select" style="display: none;"></select>
                    <div class="swal2-radio" style="display: none;"></div><label for="swal2-checkbox" class="swal2-checkbox"
                        style="display: none;"><input type="checkbox"><span class="swal2-label"></span></label><textarea
                        class="swal2-textarea" style="display: none;"></textarea>
                    <div class="swal2-validation-message" id="swal2-validation-message" style="display: none;"></div>
                </div>
                <div class="swal2-actions">
                    <div class="swal2-loader"></div><button type="button" class="swal2-confirm swal2-styled"
                        style="display: inline-block; background-color: rgb(249, 148, 79);" aria-label="">Принимаю условия</button><button
                        type="button" class="swal2-deny swal2-styled" style="display: none;" aria-label="">No</button><button
                        type="button" class="swal2-cancel swal2-styled" aria-label="">Нет</button>
                </div>
                <div class="swal2-footer" style="display: none;"></div>
                <div class="swal2-timer-progress-bar-container">
                    <div class="swal2-timer-progress-bar" style="display: none;"></div>
                </div>
            </div>
        </div>`);
    document.body.append(popup);
    popup.querySelector(".swal2-confirm").addEventListener("click", () => {
      closeWarningPopup(id);
      onConfirm();
    });
    popup.querySelector(".swal2-cancel").addEventListener("click", () => {
      closeWarningPopup(id);
    });
  };

  const showNoPvzSelected = () => {
    warningPopup(
      "no-pvz-selected",
      "Пожалуйста, выберите конкретный пункт выдачи заказов или другой способ доставки.",
    );
  };

  const showMaxSumRestriction = () => {
    if (maxEnPricePopupShowed) return;
    maxEnPricePopupShowed = true;
    warningPopup(
      "max-sum-restriction",
      `Ограничение для вашей страны -
        заказ не более чем на 240$ в одной заявке`,
    );
  };

  const addItemFromUrlIfAny = () => {
    var add = urlParams.get("add");
    if (add) {
      setCartItem(add, 1);
      urlParams.delete("add");
    }

    for (const item of getCart("gifts_cart", "session")) {
      setCartItem(item.id, item.quantity);
      if (item.quantity) {
        showForcedProductIds.push(+item.id);
      }
    }
  };

  const validPromoVisual = () => {
    applyPromo.parentElement.querySelector(".agree_promo").remove();
    applyPromo.parentElement.insertBefore(
      makeElement(
        `<p class="agree_promo promo_succ" style="display: block;">Промокод применен</p>`,
      ),
      applyPromo,
    );
    promoField.className = "js-checkout__input b-checkout__input _filled valid";
  };

  const invalidPromoVisual = () => {
    applyPromo.parentElement.querySelector(".agree_promo").remove();
    applyPromo.parentElement.insertBefore(
      makeElement(`<p class="agree_promo promo_err" style="display: block;">Неверный промокод</p>`),
      applyPromo,
    );
    promoField.className = "js-checkout__input b-checkout__input _filled";
  };

  const loadPromo = () => {
    const promo = getCookie("promo");
    if (!promo) {
      return;
    }
    promoField.value = promo;
    setTimeout(() => {
      applyPromo.dispatchEvent(new Event("click"));
    }, 500);
  };

  var isUpdatedByYandexId = false;
  const updateInfoByYandexId = () => {
    return new Promise((res, rej) => {
      if (isUpdatedByYandexId) {
        res();
        return;
      }
      isUpdatedByYandexId = true;

      let yid = getCookie("_ym_uid");
      let currentTestGroup = getCookie("test_group");
      let currentRef = getCookie("ref");
      fetch(
        apiHost +
          `/info-by-yandex-id?yid=${yid}&current_test_group=${currentTestGroup}&current_ref=${currentRef}`,
      )
        .then((r) => r.json())
        .then((d) => {
          setCookie("test_group", d.test_group);
          if (d.ref) {
            setCookie("ref", d.ref);
          }
          rerenderPayMethods();
          updatePrices(null, true);
          res();
        });
    });
  };

  const preOrder = () => {
    saveFields();
    const kid = getKladrIdParsed();
    const cart = getCart();
    var data = {
      uuid: getUuid(),
      country: getCountry(),
      email: emailField.value,
      phone: phoneField.value,
      first_name: firstNameField.value,
      last_name: lastNameField.value,
      promo: promoField.value,
      city: cityField.value,
      street: streetField.value,
      building: buildingField.value,
      apartment: apartmentField.value,
      zip: is_en ? zipField.value : kid.zip,
      region: kid.region,
      cart: stripCart(cart),
      city_kladr_id: kid.city_kladr_id,
      city_fias_id: kid.city_fias,
      delivery_method: deliveryMethod,
      utm_source: getCookie("utm_source"),
      utm_medium: getCookie("utm_medium"),
      utm_campaign: getCookie("utm_campaign"),
      utm_content: getCookie("utm_content"),
      utm_term: getCookie("utm_term"),
      zero_delivery_price: zeroDeliveryPrice || zeroDeliveryPriceForced,
      gClientId: getCookie("gClientId"),
      gSessionId: getGSessionIds(),
      yandex_id: getCookie("_ym_uid"),
      from_site: true,
      test_group: getCookie("test_group"),
      pvz_address:
        deliveryMethod == "cdek_pvz" && selectedPvzInfo ? selectedPvzInfo.address : undefined,
      pvz_id: deliveryMethod == "cdek_pvz" ? selectedPvz : undefined,
      from_review: fromReview,
      ref_uuid: getCookie("ref") || null,
      recommended_product_added: !!AddedRecommendationIds.filter((x) => cart.find((i) => i.id == x))
        .length,
      cart_checkbox_agree: cfiAgree2Field.checked,
      cart_step: localStorage.step_n || 1,
      step_one_btn_pressed: stepOneBtnPressed,
      from_source: "cart",
      gift_products: getCart("gifts_cart", "session"),
      was_on_newsite_redirection: getCookie("was_on_newsite_redirection") || false,
      tags: tags,
    };

    if (!data.phone || data.phone.endsWith("_") || data.phone.endsWith("-")) return;

    updateInfoByYandexId().then(() => {
      data.test_group = getCookie("test_group");

      if (getCookie("enable_preorder") == "0") return;

      fetch(apiHost + "/pre-order", {
        method: "PUT",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((r) => r.json())
        .then((d) => {
          const prev_rand_lead_num = getCookie("lead_rand_num");
          if (prev_rand_lead_num != d.rand_lead_num) {
            setCookie("lead_rand_num", d.rand_lead_num);
            // dataLayer.push({ 'event': 'lead_generate' })
          }
        });
    });
  };

  const slowPreOrder = createTreshhold(preOrder, 1500);

  const onQuantityChangeSlowed = createTreshhold(() => {
    updateSum();
    updateCookieCart();
    updateDelivery();
    slowPreOrder();
    updatePrices(null, true);
  }, 300);

  const onQuantityChange = (e) => {
    const isAdd = e.target.className.indexOf("b-quantity__btn--up") >= 0 ? true : false;
    const item = e.target.parentElement.parentElement.parentElement;
    const itemPrice = parseInt(item.querySelector(".js-checkout__price").innerText);
    const sum = parseInt(document.querySelector(".js-checkout__total_products").innerText);
    const quantityField = item.querySelector(".js-checkout__quantity");
    const id = parseInt(item.getAttribute("data-cart_line_id"));
    const cart = getCart();
    const cartItem = cart ? cart.find((e) => e.id == id) : null;
    const quantity = cartItem ? cartItem.quantity : 0;
    const nextQuantity =
      quantity == quantityField.value
        ? isAdd
          ? quantity + 1
          : quantity - 1
        : parseInt(quantityField.value);

    if (is_en && isAdd && itemPrice * (nextQuantity - quantity) + sum > 240) {
      setTimeout(() => {
        quantityField.value = quantity;
        document.querySelector(".js-checkout__total_products").innerText = sum;
      }, 150);
      dispatchEvent(new Event("max-sum-restriction"));
    } else onQuantityChangeSlowed();
  };

  const onOrderDataChange = createTreshhold(preOrder, 300);

  const autoApplyPromo = createTreshhold(() => {
    updatePrices(promoField.value, true).then((d) => {
      if (d.valid_promo) {
        validPromoVisual();
      } else {
        invalidPromoVisual();
      }
    });
  }, 400);

  const updatePvzs = (q) => {
    const kladrId = getKladrIdParsed();
    const country = getCountry();
    const city = cityField.value;
    const region = kladrId.region;
    const qvar = q ? `&query=${q}` : "";
    return fetch(apiHost + `/pvzs?country=${country}&region=${region}&city=${city}${qvar}`)
      .then((r) => r.json())
      .then((d) => {
        const event = new Event("cdek-pvzs-updated");
        event.pvzs = d;
        dispatchEvent(event);

        if (!q) {
          pvzsListShowHide.classList.add("hidden");
          pvzsList.classList.add("hidden");
          pvzSearch.value = "";
          setCookie("pvz_search", "");
        }

        return d;
      });
  };

  const selectPvz = (pvz) => {
    selectedPvz = pvz.code;
    selectedPvzInfo = pvz;
    pvzSearch.value = pvz.address;
    const e = new Event("pvz-selected");
    e.pvz = pvz;
    dispatchEvent(e);
    setTimeout(updateDelivery, 450);
    if (scrollY > 200) {
      try {
        document.querySelector("#scroll-after-selected").scrollIntoView({ behavior: "smooth" });
      } catch {}
    }
    saveFields();
  };

  const zoomPvzMapInto = (lat, lon) => {
    const e = new Event("zoom-pvz-map");
    e.lat = lat;
    e.lon = lon;
    dispatchEvent(e);
  };

  const showPvzsList = () => {
    pvzsListShowHide.innerHTML = "Скрыть список ближайших ПВЗ";
    pvzsListShowHide.classList.remove("hidden");
    pvzsList.classList.remove("hidden");
  };

  const hidePvzsList = () => {
    pvzsListShowHide.innerHTML = "Показать список ближайших ПВЗ";
    pvzsList.classList.add("hidden");
  };

  const setNearestPvzs = (lat, lon) => {
    fetch(apiHost + `/nearest-pvzs?lat=${lat}&lon=${lon}`)
      .then((r) => r.json())
      .then((d) => {
        if (d && d.detail) {
          d = [];
        }
        const e = new Event("nearest-pvzs-updated");
        e.data = d;
        e.lat = lat;
        e.lon - lon;
        dispatchEvent(e);

        pvzsList.innerHTML = "";
        for (const pvz of d) {
          const newDiv = document.createElement("div");
          newDiv.innerHTML = `
                        <div><s><img src="/static/img/cdek_cdek.svg"><div class="select-pvz-btn" style="font-size: 14px;font-weight: 400;line-height: 16px;">Выбрать этот ПВЗ</div></s><p>${pvz.address}</p></div>
                    `;
          pvzsList.appendChild(newDiv);
          newDiv.addEventListener("click", () => {
            document.querySelectorAll(".select-pvz-btn").forEach((e) => {
              e.innerText = "Выбрать этот ПВЗ";
              e.backgroundColor = "#2cd35f";
              e.color = "white";
            });

            const txt = newDiv.querySelector(".select-pvz-btn");
            txt.innerText = "Выбрано✅";
            txt.backgroundColor = "white";
            txt.color = "black";
            let e = new Event("pvz-preselect");
            e.pvz_id = pvz.id;
            document.dispatchEvent(e);
            zoomPvzMapInto(pvz.latitude, pvz.longitude);
            document.querySelector("#map").scrollIntoView({ behavior: "smooth" });
          });
        }
        showPvzsList();
      });
  };

  const setPvzSearch = () => {
    $("#pvz-search").autocomplete({
      delay: 300,
      source: (request, response) => {
        const kladrId = getKladrIdParsed();
        fetch(
          apiHost +
            `/address-suggestion?country=${getCountry()}&city_fias=${kladrId.city_fias}&query=${
              request.term
            }&city=${cityField.value}`,
        )
          .then((r) => r.json())
          .then((d) => {
            response(
              d.map((addr) => {
                return {
                  label: addr.value,
                  value: addr.value,
                  lat: addr.data.geo_lat,
                  lon: addr.data.geo_lon,
                };
              }),
            );
          });
      },
      select: (event, ui) => {
        setNearestPvzs(ui.item.lat, ui.item.lon);
        setTimeout(saveFields, 100);
      },
    });
  };

  const setCartFromUrl = () => {
    try {
      const cart = urlParams.get("set_cart");
      showCart = [];
      for (const item of cart.split(",")) {
        const vars = item.split("-");
        showForcedProductIds.push(+vars[0]);
        showCart.push({ id: parseInt(vars[0]), quantity: parseInt(vars[1]) });
      }
      setCart(showCart);
    } catch {}
  };

  (() => {
    document.querySelector(".js-checkout").addEventListener("submit", (e) => {
      e.preventDefault();
      document
        .querySelector(
          '.js-checkout .js-checkout__step-body:not([style="display: none;"]) .button[type="button"]',
        )
        .click();
    });

    setCookie("enable_preorder", "0");
    setTimeout(() => {
      setCookie("enable_preorder", "1");
    }, 10000);

    setCartFromUrl();
    addItemFromUrlIfAny();
    loadPromo();

    addEventListener("cartUpdated2", once(updateCountriesList));

    setAutocomplete('input[name="city"]', "city");
    cityField.addEventListener("focusout", () => {
      setTimeout(() => {
        if (!citySelectedFromList && !is_en) {
          cityField.value = oldCityValue;
          warningPopup("no-city-selected-from-list", "Выберите город из списка.");
        }
      }, 200);
    });
    cityField.addEventListener("input", () => {
      citySelectedFromList = false;
    });
    setAutocomplete('input[name="street"]', "street");
    setAutocomplete('input[name="dom"]', "building");

    kindergiftField.addEventListener("click", () => {
      updatePrices(null, true);
      updateDelivery();
    });

    applyPromo.addEventListener("click", () => {
      updatePrices(promoField.value, true).then((d) => {
        if (d.valid_promo) validPromoVisual();
        else invalidPromoVisual();
      });
    });
    promoField.addEventListener("input", autoApplyPromo);

    addEventListener("cartUpdated", () => {
      const cartItems = [...document.querySelectorAll(".js-checkout-cart__item")];
      cartItems.forEach((item) => {
        const btnUp = item.querySelector(".b-quantity__btn--up");
        const btnDown = item.querySelector(".b-quantity__btn--down");
        const input = item.querySelector(".js-checkout__quantity");
        btnUp.addEventListener("click", (e) => {
          window["rrApiOnReady"] = window["rrApiOnReady"] || [];
          window["rrApiOnReady"].push(function () {
            try {
              rrApi.addToBasket(item.dataset.cart_line_id);
            } catch (ex) {}
            try {
              const price = item.querySelector(".js-checkout__price").textContent;
              _tmr.push({
                type: "reachGoal",
                id: 3384650,
                value: price,
                goal: "addToWishlist",
                params: { product_id: item.dataset.cart_line_id },
              });
              _tmr.push({
                type: "reachGoal",
                id: 3384650,
                value: price,
                goal: "addToCart",
                params: { product_id: item.dataset.cart_line_id },
              });
            } catch (e) {}
          });
          onQuantityChange(e);
        });
        btnDown.addEventListener("click", onQuantityChange);
        input.addEventListener("input", onQuantityChange);
      });
    });

    const inputs = [...document.querySelectorAll(".js-checkout__input")];
    inputs.forEach((e) => {
      setInterval(() => {
        if (e.value || e.name == "phone") {
          e.classList.add("_filled");
        } else {
          e.classList.remove("_filled");
        }
      }, 50);
    });
    inputs.forEach((e) => {
      e.dispatchEvent(new Event("change"));
    });

    addEventListener("go-to-step", (e) => {
      preOrder();
      updateDelivery();
      if (e.step == 2 && cfiAgreeField.checked) {
        secondStepPromotionsToShow.forEach((d) => {
          if (!showedPromotions[d.promotionId]) {
            showedPromotions[d.promotionId] = true;
            const e = new Event("show-promotion-popup");
            e.id = d.popupId;
            dispatchEvent(e);
          }
        });
      }
    });
    promoField.addEventListener("focusout", preOrder);
    firstNameField.addEventListener("input", onOrderDataChange);
    lastNameField.addEventListener("input", onOrderDataChange);
    emailField.addEventListener("input", onOrderDataChange);
    cityField.addEventListener("input", onOrderDataChange);
    streetField.addEventListener("input", onOrderDataChange);
    streetField.addEventListener("input", createTreshhold(updateDelivery, 300));
    buildingField.addEventListener("input", onOrderDataChange);
    apartmentField.addEventListener("input", onOrderDataChange);
    // lastNameField.addEventListener('input', createTreshhold(preOrder, 300))
    var last_phone = phoneField.value;
    setInterval(() => {
      if ($(phoneField).inputmask("unmaskedvalue").startsWith("89")) {
        $(phoneField).inputmask("setvalue", "+79");
      }
      if (phoneField.value.endsWith("_")) return;
      if (phoneField.value != last_phone) {
        last_phone = phoneField.value;
        onOrderDataChange();
      }
    }, 50);

    setTimeout(() => {
      if (is_en) cityField.addEventListener("input", createTreshhold(updateDelivery, 300));
    }, 1000);

    firstPreorder.addEventListener("click", (e) => {
      if (!cfiAgreeField.checked) {
        warningPopup(
          "no-cfi-agree",
          'Для продолжения необходимо согласиться с условиями "Договора-оферты".',
        );
        setTimeout(() => {
          const ev = new Event("force-go-to-step");
          ev.step_n = 1;
          dispatchEvent(ev);
        }, 500);
      } else {
        if (cfiAgree2Field.checked) {
          api("post", "/register", {
            uuid: getUuid(),
            email: emailField.value,
            phone: phoneField.value,
            first_name: firstNameField.value,
            last_name: lastNameField.value,
            send_promo_letters: cfiAgree2Field.checked,
            source: "cart",
            only_if_not_logined: true,
            yandex_id: getCookie("_ym_uid"),
          }).then((data) => {
            if (data.detail) {
              console.log(data);
            } else {
              console.log("contact created");
              ym(57109408, "reachGoal", "register_lk");
            }
          });

          api(
            "get",
            `/add-email-to-unisender-list?email=${emailField.value}&list_id=20405751`,
          ).then((d) => {
            console.log(d);
          });
        }

        if (e.clientX || e.clientY) {
          console.log("STEP ONE BTN PRESSED BY HAND");
          stepOneBtnPressed = true;
        } else {
          console.log("STEP ONE BTN AUTOPRESSED");
        }
        dispatchEvent(new Event("goto_lvl_2"));

        if (cityField.value) {
          if (deliveryMethod) {
            if (deliveryMethod.endsWith("door")) setDoorDeliveryType();
            if (deliveryMethod.endsWith("pvz")) {
              setPvzDeliveryType();
            }
          }

          updatePvzs().then(() => {
            if (selectedPvzInfo) {
              selectPvz(selectedPvzInfo);
            }
          });
        }
      }
    });

    secondPreorder.addEventListener("click", (e) => {
      if (deliveryMethod && !(deliveryMethod == "cdek_pvz" && !selectedPvz)) {
        updateSum();
        setTimeout(updateProductRecomendationsSlider, 250);
        dispatchEvent(new Event("goto_lvl_3"));
        setTimeout(() => {
          document.querySelector('div[data-step_n="3"]').scrollIntoView({ behavior: "smooth" });
        }, 500);
      } else {
        dispatchEvent(new Event("no-pvz-selected"));
        setTimeout(() => {
          const ev = new Event("force-go-to-step");
          ev.step_n = 2;
          dispatchEvent(ev);
        }, 500);
      }
    });

    doorSelector.addEventListener("click", setDoorDeliveryType);
    pvzSelector.addEventListener("click", setPvzDeliveryType);

    finalButton.onclick = (e) => {
      e.preventDefault();

      if (is_en) {
        confirmPopup(
          "en-additional-confirmations",
          `Внимание!
                    В соответствии с таможенной политикой страны в которую вы делаете заказ, в момент доставки заказа может возникнуть необходимость в уплате вами пошлины и налогов на импорт товаров. Размер пошлины устанавливает таможня страны получателя.
                    <br>
                    <br>
                    Оформляя заказ, вы соглашаетесь с условиями <a href="/static/pdf/oferta.pdf" target="_blank"><u>публичной оферты</u></a>
                    `,
          () => {
            order();
          },
        );
      } else {
        order();
      }
    };

    addEventListener("no-pvz-selected", showNoPvzSelected);
    addEventListener("max-sum-restriction", showMaxSumRestriction);

    setTimeout(() => {
      updatePrices().then((d) => {});
    }, 150);

    restoreFields();

    toggleDeliverySelectionBox();
    pvz2.classList.add("hidden");
    address2.classList.add("hidden");

    pvzSelector.addEventListener("click", setPvzDeliveryType);
    doorSelector.addEventListener("click", setDoorDeliveryType);

    setPvzSearch();

    addEventListener("pvz-preselected", (e) => {
      selectPvz(e.pvz);
    });

    pvzsListShowHide.addEventListener("click", () => {
      if (pvzsList.classList.contains("hidden")) {
        showPvzsList();
      } else {
        hidePvzsList();
      }
    });

    cityField.addEventListener("input", () => {
      if (is_en) toggleDeliverySelectionBox();
    });

    firstPreorder.addEventListener("click", () => {
      ym(57109408, "reachGoal", "cart1");
    });
    secondPreorder.addEventListener("click", () => {
      ym(57109408, "reachGoal", "cart2");
    });

    phoneField.addEventListener("click", () => {
      ym(57109408, "reachGoal", "number_click");
    });

    addEventListener("goto_lvl_2", () => {
      ym(57109408, "reachGoal", "basket_level2");
    });

    addEventListener("goto_lvl_3", () => {
      ym(57109408, "reachGoal", "basket_level3");
    });

    setTimeout(() => {
      haveCompletedGoal = true;
      haveCompletedGoalDefined = true;
    }, 2000);

    cfiAgreeField.addEventListener("click", () => {
      api(
        "get",
        `/update-external-send-promo-letters-everywhere?email=${emailField.value}&is_send=${
          cfiAgreeField.checked && cfiAgree2Field.checked
        }`,
      ).then((d) => {});
    });
    cfiAgree2Field.addEventListener("click", () => {
      api(
        "get",
        `/update-external-send-promo-letters-everywhere?email=${emailField.value}&is_send=${
          cfiAgreeField.checked && cfiAgree2Field.checked
        }`,
      ).then((d) => {});
    });

    setTimeout(() => {
      restoreStep();
    }, 1500);
  })();
})();
