import {fetchDefaultPrices, getCookie, getCountry, makeElement} from './Utils'

(() => {
    const container = document.querySelector('div[class="js-related-cards b-related-product-cards"]')
    if (!container)
        return

    const ids = {
        '/main.html': 3,
        '/zeolite.html': 3,
        '/z-celd.html': 3,
        '/page01.html': 3,
        '/page02.html': 3,
        '/page03.html': 3,
        '/page04.html': 3,
        '/main2.html': 4,
        '/page-2-1.html': 4,
        '/page-2-2.html': 4,
        '/page-2-3.html': 4,
        '/page-2-4.html': 4,
        '/main3.html': 13,
        '/main4.html': 5,
    }

    fetchDefaultPrices().then((d) => {
        container.innerHTML = ``
        d.products.forEach((p) => {
            if (!p.enabled || p.realisation_type != "shop" || p.group == "Набор" || !p.show_in_main_page)
                return

            container.appendChild(makeElement(`<div class="b-related-product-cards__item">
                <a href="${p.details_url}" class="b-related-product-card${p.group == "Девайс" ? ' b-related-product-card--device' : ''}">
                    <img loading="lazy" class="b-related-product-card__cat-logo"
                        data-src="static/img/general/o-${p.group == "Девайс" ? 'device' : 'complex'}.svg">
                    <div class="b-related-product-card__wrpic">
                        <img loading="lazy" class="b-related-product-card__pic" data-src="${p.img}">
                    </div>
                    <div class="b-related-product-card__body">
                        <div class="b-related-product-card__caption">${p.name}</div>
                        <p class="b-related-product-card__desc">${p.description}</p>
                    </div>
                    <div class="b-related-product-card__footer">
                        <div><span class="b-related-product-card__link">Подробнее →</span>
                        </div>
                    </div>
                </a>
                <div class="b-related-product-card-order">
                    <div class="js-card-order b-card-order">
                        <div class="b-card-order__lvl1">
                            <span class="js-card-order__button b-card-order__button${p.group == "Девайс" ? ' header__order-btn--device' : ''}" data-product-id="${p.product_id}">Заказать</span>
                        </div>
                        <div class="b-card-order__lvl2">
                            <div class="js-quantity b-quantity">
                                <div class="b-quantity__btn b-quantity__btn--down" data-product-id="${p.product_id}">-</div>
                                <input class="js-checkout__quantity b-quantity__input" type="number" data-product-id="${p.product_id}"
                                    value="1" min="1">
                                <div class="b-quantity__btn b-quantity__btn--up" data-product-id="${p.product_id}">+</div>
                            </div>
                        </div>
                    </div>
                    <div class="b-related-product-card-order__price">${d.en ? p.price.en : p.price.ru} <span class="ruble"></span>
                    </div>
                </div>
            </div>`))

            if (ids[window.location.pathname] == p.product_id) {
                console.log(p.name)
                const priceFields = document.querySelectorAll('.b-caption-with-order__price')
                for (const field of priceFields) {
                    field.innerText = `${d.en ? p.price.en : p.price.ru} ${d.en ? '$' : '₽'}`
                }

				document.querySelectorAll('.b-side-cart-added-notify__pic').forEach((e) => {
					e.src = p.side_img
				})

				const retailrocketProductIdActivate = () => {
					(window["rrApiOnReady"] = window["rrApiOnReady"] || []).push(function() {
						rrApi.view(p.product_id)
					})
				}
				retailrocketProductIdActivate()

				try {
					_tmr.push({ type: "reachGoal", id: 3384650, value: p.price.ru, goal: "viewProduct", params: { product_id: p.product_id}});
				} catch (e) {}
            }
        })

        const currensies = [...document.querySelectorAll('span[class="ruble"]')]
        currensies.forEach((e) => {
            e.innerText = d.en ? '$' : '₽'
        })

        const event = new Event('productsFetched')
        dispatchEvent(event)
    })

})()
