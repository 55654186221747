//Для закрытия попапа - навесить на закрывающий элемент класс 'popup-el-close' (их может быть несколько);
//На враппер при его наличии - добавить класс 'popup-el-wrap';

// пример инициализации:
// const popup = new Popup({
//     elemSelector: '.selector', //селектор попапа (именно попап, не его враппер)
//     openByElementSelectors: '.open-popup', //кнопки, открывающие попап. могут быть расположены в любом месте на странице
//     showOnPageLoad: true/false, // показывать ли попап сразу при зарузке страницы (например, cookies)
//     wrapper: true/false, // есть ли враппер у попапа
//     showOnce: false/true, // показывать попап только при первом посещении сайта (например, cookies)
// })


export default class Popup {

    constructor({
                    elemSelector,
                    openByElementSelector,
                    showOnPageLoad = false,
                    wrapper = false,
                    showOnce = false,
                }) {

        this.selector = `${elemSelector}`;
        this.elem = document.querySelector(`${elemSelector}`);
        this.showOnPageLoad = showOnPageLoad;
        this.showOnce = showOnce;

        this.hasWrapper = wrapper;
        this.wrapper = this.elem.parentElement;

        this.openButtons = document.querySelectorAll(`${openByElementSelector}`);
        this.closeButtons = this.elem.querySelectorAll('.popup-el-close');


        this.showOnce ? this.checkIfPopupAlreadyShown() : this.openOnPageLoad();
        this.addEventListeners();
    }

    openOnPageLoad() {
        this.showOnPageLoad ? this.open() : null;
    }

    open() {

        if (this.hasWrapper) {
            this.wrapper.classList.add('opened');
            console.log(this.wrapper)
        } else {
            this.elem.classList.add('opened');
        }

        if (!this.showOnPageLoad) {
            document.body.style.overflow = 'hidden';
        }

        const popupOpen = new CustomEvent('popup-open', {
            detail: this.elem,
            bubbles: true
        });

        this.elem.dispatchEvent(popupOpen);
    }

    close(setCookie = false) {

        if (this.hasWrapper) {
            this.closePopup(this.wrapper, setCookie)
        } else {
            this.closePopup(this.elem, setCookie);
        }

        document.body.style.overflow = 'visible';

        const popupClose = new CustomEvent('popup-close', {
            detail: this.elem,
            bubbles: true
        });

        this.elem.dispatchEvent(popupClose);
    }

    closePopup(elem, setCookie = false) {

        elem.classList.remove('opened');
        setCookie ? localStorage.setItem(`${this.selector}`, 'true') : null
    }

    checkIfPopupAlreadyShown() {
        const isCookies = localStorage.getItem(`${this.selector}`);
        isCookies ? this.elem.classList.add('shown') : this.openOnPageLoad();
    }

    addEventListeners() {

        if (this.openButtons.length) {

            this.openButtons.forEach(openButton => {
                openButton.addEventListener('click', () => this.open());
            })
        }

        this.closeButtons.forEach(closeButton => {
            closeButton.addEventListener('click', () => this.close(true));
        })

        if (this.hasWrapper) {

            document.addEventListener('click', (e) => {
                const target = e.target;
                if (target === this.wrapper) {
                    this.close(true)
                }
            })

        }
    }
}