import Config from "./modules/Config"
import {getGSessionIds} from "./Utils"

const apiHost = Config.apiUrl

const getCookie = (name) => {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2)
        return decodeURIComponent(parts.pop().split(';').shift())
    return ''
}

const getCountry = () => {
    var country = getCookie('country')
    if (country) {
        return country
    }
    return 'Россия'
}

const getUuid = () => {
    var uuid = getCookie('uuid')
    if (uuid)
        return uuid
    
    function uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }
    uuid = uuidv4()
    setCookie('uuid', uuid)
    return uuid
}


const updatePhoneMask = () => {
    const phones = document.querySelectorAll('input[name="phone"]').forEach((e) => {	
	const country = getCountry()
	if (country == 'Россия')
            $(e).inputmask('+7 (999) 999-9999')
	else if (country == 'Казахстан')
            $(e).inputmask('+7 (999) 999-9{0,4}')
	else if (country == 'Беларусь')
            $(e).inputmask('(+375-99-999-99-9{0,2}|+7 (999) 999-9{0,4})')
	else {
            $(e).inputmask('+9{0,30}')
	}
    })
}


(() => {
    const popup = document.createElement('div')
    popup.innerHTML = `<div class="in_pt">
                           <input id="name" type="text" placeholder="Ваше имя">
                       </div>
                       <div class="in_pt">
                           <input name="phone" id="phone" type="tel" placeholder="Ваш телефон">
                       </div>
                       <div class="btn_1 b-caption-with-button__btn send_preorder_btn" style="margin-top: 20px;">Отправить</div>
`
    popup.style.display = 'none'
    document.body.append(popup)
    const nameField = popup.querySelector('#name')
    const phoneField = popup.querySelector('#phone')
    const country = getCountry()
    setInterval(updatePhoneMask, 1000)
    
    const popupSuccess = document.createElement('div')
    const popupSuccessInner = document.createElement('div')
    popupSuccess.appendChild(popupSuccessInner)
    const openSuccessPopup = () => {
	popupSuccessInner.innerHTML = `<img src="/static/img/loading.gif" style="transform: translate(-50%, 0); position: relative; left: 50%; top: 50%;">`
	$.fancybox.open(popupSuccess)
    }
    popupSuccess.style.display = 'none'
    document.body.append(popupSuccess)

    const sendData = (data) => {
	openSuccessPopup()
	if (data.names) {
	    const names = data.names.split(' ')
	    data.first_name = names[0]
	    data.last_name = names.slice(1).join(' ')
	    delete data.names
	}
	const send_data = {
            uuid: getUuid(),
            country: getCountry(),
            utm_source: getCookie('utm_source'),
            utm_medium: getCookie('utm_medium'),
            utm_campaign: getCookie('utm_campaign'),
            utm_content: getCookie('utm_content'),
            utm_term: getCookie('utm_term'),
            gClientId: getCookie('gClientId'),
            gSessionId: getGSessionIds(),
            yandex_id: getCookie('_ym_uid'),
        }
	fetch(apiHost + '/pre-order', {
            method: 'PUT',
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({...send_data, ...data}),
        }).then(() => {
	    popupSuccessInner.innerHTML = '<h3>Наши менеджеры уже подкидывают монетку, чтобы узнать, кому повезёт с вами пообщаться ☺️ Счастливчик свяжется с вами в ближайшее время</h3>'
	})
    }

    const check = (inp) => {
	if (!inp.value || inp.value.endsWith("_") || inp.value.endsWith("-")) {
	    inp.classList.add("_danger")
	    return false
	}
	inp.classList.remove("_danger")
	return true
    }
    
    const btn = popup.querySelector('.send_preorder_btn')
    btn.addEventListener('click', () => {
	if (!check(nameField) || !check(phoneField))
	    return
	
	$.fancybox.close(popup)
	sendData({
	    names: nameField.value,
            phone: phoneField.value,
	    from_dubai: true,
	})
    })

    document.querySelectorAll('.btn_1-preorder').forEach((e) => {
	e.href = ''
	e.addEventListener('click', (event) => {
	    event.preventDefault()
	    $.fancybox.open(popup)
	})
    })

    document.querySelector('.btn_1-preorder2').addEventListener('click', (event) => {
	event.preventDefault()
	const name = document.querySelector('#page-name')
	const phone = document.querySelector('#page-phone')
	const msg = document.querySelector('#page-message')
	if (!check(name) || !check(phone) || !check(msg))
	    return
	
	$.fancybox.close(popup)
	sendData({
	    names: name.value,
            phone: phone.value,
	    comment: msg.value,
	    from_dubai: true,
	})
    })
})()
