export default {
    apiUrl: process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000' : '/api',
    maxEnSum: 240,
    pageProductIds: {
        'main.html': 3,
        'zeolite.html': 3,
        'z-celd.html': 3,
        'page01.html': 3,
        'page02.html': 3,
        'page03.html': 3,
        'page04.html': 3,
        'main2.html': 4,
        'page-2-1.html': 4,
        'page-2-2.html': 4,
        'page-2-3.html': 4,
        'page-2-4.html': 4,
        'main3.html': 13,
        'main4.html': 5,
    }
}
