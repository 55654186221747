export default {
    isCountryInCookies() {
        return this.getCountryFromCookies() ? true : false
    },
    setCountryInCookie(countryName) {
        Cookies.set('country', countryName)
        this._dispatchEvent()
    },
    _dispatchEvent() {
        const event = new CustomEvent('locale-change')
        document.dispatchEvent(event)
    },
    getCountryFromCookies() {
        return Cookies.get('country') ? Cookies.get('country') : null
    }
}
