import {api, getUuid, getCookie, setCookie} from "./Utils"
console.log("afterbufewoiwefjoefwi")



window["rrApiOnReady"] = window["rrApiOnReady"] || []
window["rrApiOnReady"].push(() => {
	rrApi.setProfile({
		"email": getCookie('email'),
		"phone": '+' + getCookie('phone').match(/[0-9]{0,14}/g).join(''),
		"isAgreedToReceiveMarketingMail": true,
	})
})

api('get', `/last-purchase?uuid=${getUuid()}`).then((data) => {
	if (data.lead_id) {
		window["rrApiOnReady"].push(() => {
			rrApi.order({
				"transaction": data.lead_id,
				"items": data.cart
			})
		})

		try {
			_tmr.push({
				type: "reachGoal",
				id: PIXEL_ID,
				value: data.cart.map(item => item.qnt * item.price).reduce((s, x) => s + x),
				goal: "purchase",
				params: {
					product_id: data.cart.map(item => item.id)
				}
			})
		} catch (e) {}
	}
})


document.cookie = 'basket='
setCookie('has_purchases', '1')




