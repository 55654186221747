import Config from "./modules/Config";

export const once = (func) => {
  var isCalled = false;
  return () => {
    if (!isCalled) {
      func();
      isCalled = true;
    }
  };
};

export const createTreshhold = (func, miliseconds) => {
  var last_start = Date.now();
  var timer = null;
  return () => {
    const now = Date.now();
    if (now - last_start < miliseconds) {
      if (timer) {
        clearTimeout(timer);
      }
    }
    last_start = now;
    timer = setTimeout(func, miliseconds);
  };
};

export const makeElement = (html) => {
  var temp = document.createElement("div");
  temp.innerHTML = html;
  const imgElements = temp.getElementsByTagName("img");
  for (let i = 0; i < imgElements.length; i++) {
    const img = imgElements[i];
    if (img.loading === "lazy") {
      img.setAttribute("src", img.getAttribute("data-src"));
    }
  }
  return temp.children[0];
};

const sessionSave = (name, jsn) => {
  sessionStorage.setItem(name, JSON.stringify(jsn));
};

const sessionGet = (name) => {
  const item = sessionStorage.getItem(name);
  if (item) return JSON.parse(item);
  return null;
};

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return decodeURIComponent(parts.pop().split(";").shift());
  return "";
};

export const setCookie = (name, value) => {
  value = encodeURIComponent(value);
  const days = 365;
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

export const deleteCookie = (name) => {
  document.cookie = name + "=";
};

export const getKladrId = () => {
  return getCookie("kladrId");
};

export const getKladrIdParsed = () => {
  const kid = getCookie("kladrId");
  return kid ? JSON.parse(kid) : {};
};

export const setKladrId = (kladrId) => {
  setCookie("kladrId", kladrId);
};

export const getUuid = () => {
  var uuid = getCookie("uuid");
  if (uuid) return uuid;

  function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
    );
  }
  uuid = uuidv4();
  setCookie("uuid", uuid);
  return uuid;
};

export const getCountry = () => {
  var country = getCookie("country");
  if (country) {
    return country;
  }
  return "Россия";
};

export const selectText = (element) => {
  var doc = document,
    text = element,
    range,
    selection;

  if (doc.body.createTextRange) {
    range = document.body.createTextRange();
    range.moveToElementText(text);
    range.select();
  } else if (window.getSelection) {
    selection = window.getSelection();
    range = document.createRange();
    range.selectNodeContents(text);
    selection.removeAllRanges();
    selection.addRange(range);
  }
};

const set_value = (selector, value) => {
  document.querySelectorAll(selector).forEach((e) => (e.innerHTML = value));
};

const hide_if = (selector, should_hide) => {
  document
    .querySelectorAll(selector)
    .forEach((e) => (should_hide ? e.classList.add("hidden") : e.classList.remove("hidden")));
};

export const formatInt = (num) => {
  return new Intl.NumberFormat("ru-RU", { maximumSignificantDigits: 10 }).format(num);
};

export const update_cab_header = () => {
  return new Promise((resolve, reject) => {
    fetch(Config.apiUrl + `/contact-info?uuid=${getUuid()}`)
      .then((r) => r.json())
      .then((data) => {
        set_value("#points-per-ref", formatInt(data.points_per_ref));
        set_value("#points-per-reg", formatInt(data.points_per_reg));
        set_value("#points-per-review", formatInt(data.points_per_review));

        if (!data.success) {
          return reject(data);
        }

        if (data.info.first_name) {
          set_value("#contact-name", `, ${data.info.first_name}`);
        }

        const dbb = data.info.loyalty_days_before_burn;

        set_value("#balance", formatInt(data.info.loyalty_balance));
        set_value("#burn-date", data.info.loyalty_balance_burn_date);
        set_value("#burn-days", data.info.loyalty_days_before_burn);
        set_value(
          "#burn-days-with-days",
          dbb +
            (dbb > 10 && dbb < 20
              ? " дней"
              : dbb % 10 == 1
              ? " день"
              : dbb % 10 > 1 && dbb % 10 < 5
              ? " дня"
              : " дней"),
        );

        set_value("#max-days-before-burn", data.info.loyalty_days_before_burn_from_start);
        set_value("#loyalty_gift_burn_after_days", data.loyalty_gift_burn_after_days);
        set_value("#loyalty_balance_burn_after_days", data.loyalty_balance_burn_after_days);
        set_value("#ref-url", `o-complex.com/ref/${data.info.ref_uuid}`);
        hide_if("#no-burn-hide", !data.info.loyalty_balance_burn_date);
        hide_if("#loyalty-extend", data.info.loyalty_days_before_burn > 3);

        for (let limit of data.info.service_limits) {
          if (limit.remains < 1) {
            let btn = null;
            if (limit.service_type == "unburn_points_5_days") {
              btn = document.querySelector("#loyalty-extend-free");
            }
            if (limit.service_type == "unburn_points_30_days") {
              btn = document.querySelector("#loyalty-extend-money");
            }
            if (btn) {
              btn.classList.add("disabled");
            }
          }
        }

        document.querySelectorAll("#burn-line").forEach((e) => {
          let widthValue =
            (100 * data.info.loyalty_days_before_burn) /
            data.info.loyalty_days_before_burn_from_start;
          if (widthValue < 0) {
            widthValue = 0;
          } else if (widthValue > 100) {
            widthValue = 100;
          }
          e.style.width = widthValue + "%";
          e.dataset.width_value = widthValue;
        });

        return resolve(data);
      });
  });
};

export const getCart = (cookie = "basket", source = "cookie") => {
  let cartRaw;
  if (source == "cookie") {
    cartRaw = getCookie(cookie);
  } else if (source == "session") {
    cartRaw = sessionStorage[cookie];
  } else if (source == "local") {
    cartRaw = localStorage[cookie];
  } else {
    console.error(`wrong source: ${source}`);
  }
  var cart = [];
  if (cartRaw) cart = JSON.parse(cartRaw);
  return cart;
};

export const stripCart = (cart) => {
  var c = [];
  for (let item of cart) {
    if (item.quantity) {
      c.push(item)
    }
  }
  return c
}

export const setCart = (newCart, cookie = "basket", source = "cookie") => {
  if (source == "cookie") {
    setCookie(cookie, JSON.stringify(newCart));
  } else if (source == "session") {
    sessionStorage[cookie] = JSON.stringify(newCart);
  } else if (source == "local") {
    localStorage[cookie] = JSON.stringify(newCart);
  } else {
    console.error(`wrong source: ${source}`);
  }
};

// save updated cart and return updated cart
export const setCartItem = (productId, quantity, cookie = "basket", source = "cookie") => {
  const cart = getCart(cookie, source);
  let alreadyInCart = false;
  for (const item of cart) {
    if (item.id != productId) {
      continue;
    }

    item["quantity"] = quantity;
    alreadyInCart = true;
  }
  if (!alreadyInCart) {
    cart.push({ id: productId, quantity: quantity });
  }
  setCart(cart, cookie, source);
  return cart;
};

// save updated cart and return updated cart
export const modifyCart = (productId, quantityDelta, cookie = "basket", source = "cookie") => {
  const cart = getCart(cookie, source);
  let alreadyInCart = false;
  for (const item of cart) {
    if (item.id != productId) {
      continue;
    }

    item.quantity += quantityDelta;
    alreadyInCart = true;
  }
  if (!alreadyInCart) {
    cart.push({ id: productId, quantity: quantityDelta >= 0 ? quantityDelta : 0 });
  }
  setCart(cart, cookie, source);
  return cart;
};

export const isEn = () => {
  const country = getCountry();
  return (
    country != "Россия" &&
    country != "Беларусь" &&
    country != "Казахстан" &&
    country != "Кыргызстан" &&
    country != "Армения"
  );
};

export const addMorePopup = (product, fromReview = false, addOrderBtn = false) => {
  const pid = `${fromReview ? "review_" : ""}more_popap${product.product_id}`;
  if (document.querySelector("#" + pid)) return;

  const is_en = isEn();
  let popupContainer = document.querySelector(".popaps_more_container");
  if (!popupContainer) {
    popupContainer = document.createElement("div");
    popupContainer.classList.add(".popaps_more_container");
    popupContainer.style.display = "none";
    document.body.appendChild(popupContainer);
  }

  const orderBtn = `
        <div class="block_special_offers_in_txt_btn">
        <a class="js-card-order btn_order" onclick="rrApi.addToBasket(${product.product_id});_tmr.push({ type: "reachGoal", id: 3384650, value: ${product.price.ru}, goal: "addToWishlist", params: { product_id: ${product.product_id}}});_tmr.push({ type: "reachGoal", id: 3384650, value: ${product.price.ru}, goal: "addToCart", params: { product_id: ${product.product_id}}});" href="/old_cart.html?add=${product.product_id}">Заказать</a>
        </div>
    `;
  popupContainer.appendChild(
    makeElement(`<div class="more_popap" id="${pid}" style="">
    <div class="more_popap_in">
        <div class="more_popap_in_flex">
        <div class="block_special_offers_in_txt">
            <h4>${product.name} ${product.description}</h4>
            <div class="more_popap_in_info_in">
            <h4>Состав набора:</h4>
            ${product.set_content}
            </div>
            <div class="block_special_offers_in_txt_prise">
            <h5>${product.price.promo} <span>${is_en ? "$" : "₽"}</span></h5>
            <p>${is_en ? product.price.en : product.price.ru} <span>${is_en ? "$" : "₽"}</span></p>
            </div>
            ${fromReview || addOrderBtn ? orderBtn : ""}
        </div>
        <div class="more_popap_in_flex_img">
            <img loading="lazy" data-src="${product.img}" alt="">
        </div>
        </div>
        <div class="more_popap_in_info">
        ${product.html_description}
        </div>
    </div></div>`),
  );
};

let savedPrices;
export const fetchDefaultPrices = () => {
  return new Promise((resolve, reject) => {
    if (savedPrices) {
      resolve(savedPrices);
      return;
    }

    fetch(Config.apiUrl + "/prices", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        uuid: getCookie('uuid'),
        country: getCountry(),
      }),
    })
      .then((r) => r.json())
      .then((d) => {
        savedPrices = d;
        resolve(d);
      });
  });
};

export const isPage = (pages) => {
  const pageName = window.location.pathname;
  for (let page of pages) {
    if (pageName.endsWith(page)) return true;
  }
  return false;
};

export const pageContains = (pages) => {
  const pagePath = window.location.pathname;
  for (let page of pages) {
    if (pagePath.includes(page)) return true;
  }
  return false;
};

export const getGSessionIds = () => {
  const sIdsStr = getCookie("gSessionId");
  let sIds = [];
  try {
    sIds = JSON.parse(sIdsStr) || [];
  } catch (e) {}
  return sIds.join("|");
};

export const getIsEn = () => {
  const country = getCountry();
  if (
    country != "Россия" &&
    country != "Беларусь" &&
    country != "Казахстан" &&
    country != "Кыргызстан" &&
    country != "Армения"
  ) {
    return true;
  }
  return false;
};

// return function where can be seted popup title and text (fancybox required)
export const showPopup = (custom = false) => {
  const popupSuccess = document.createElement("div");
  const popupSuccessInner = document.createElement("div");
  popupSuccessInner.innerHTML = `<img src="/static/img/loading.gif" style="transform: translate(-50%, 0); position: relative; left: 50%; top: 50%;">`;
  popupSuccess.appendChild(popupSuccessInner);
  document.body.append(popupSuccess);
  $.fancybox.close();
  $.fancybox.open(popupSuccess);

  let interval;
  interval = setInterval(() => {
    if (popupSuccess.style.display == "none") {
      popupSuccess.remove();
      clearInterval(interval);
    }
  }, 500);

  if (custom) {
    return (popupClassAttr, html) => {
      for (let cls of popupClassAttr.split(" ")) {
        popupSuccess.classList.add(cls);
      }
      popupSuccessInner.innerHTML = html;
    };
  } else {
    return (title, text) => {
      popupSuccessInner.innerHTML = `<h2>${title}</h2><p>${text}</p>`;
    };
  }
};

export const clearErrors = () => {
  document.querySelectorAll("._danger").forEach((e) => e.classList.remove("_danger"));
  document.querySelectorAll(".error").forEach((e) => e.remove());
};

export const showErrorEl = (input, txt) => {
  input.classList.add("_danger");
  const errorElement = document.createElement("p");
  errorElement.classList.add("error");
  errorElement.textContent = txt;
  input.after(errorElement);
};

export const showError = (inputSelector, txt) => {
  const input = document.querySelector(inputSelector);
  input.classList.add("_danger");
  const errorElement = document.createElement("p");
  errorElement.classList.add("error");
  errorElement.textContent = txt;
  input.after(errorElement);
};

export const updatePhoneMask = () => {
  try {
    document.querySelectorAll('input[name="phone"]').forEach((e) => {
      const country = getCountry();
      if (country == "Россия") $(e).inputmask("+7 (999) 999-9999");
      else if (country == "Казахстан") $(e).inputmask("+7 (999) 999-9{0,4}");
      else if (country == "Беларусь") $(e).inputmask("(+375-99-999-99-9{0,2}|+7 (999) 999-9{0,4})");
      else {
        $(e).inputmask("+9{0,30}");
      }
    });
  } catch {}
};

export const api = (method, path, data = null) => {
  return new Promise((resolve, reject) => {
    fetch(Config.apiUrl + path, {
      method: method,
      headers: {
        "content-type": "application/json",
      },
      body: ["get", "head"].indexOf(method.toLowerCase()) == -1 ? JSON.stringify(data) : undefined,
    })
      .then((r) => r.json())
      .then((d) => {
        resolve(d);
      });
  });
};

export const getDefaultPreorderData = () => {
  return {
    uuid: getUuid(),
    country: getCountry(),
    utm_source: getCookie("utm_source"),
    utm_medium: getCookie("utm_medium"),
    utm_campaign: getCookie("utm_campaign"),
    utm_content: getCookie("utm_content"),
    utm_term: getCookie("utm_term"),
    gClientId: getCookie("gClientId"),
    gSessionId: getGSessionIds(),
    yandex_id: getCookie("_ym_uid"),
  };
};

export const checkInput = (inp) => {
  if (!inp.value || (inp.getAttribute("name") == "phone" && inp.value.endsWith("_"))) {
    return false;
  }
  return true;
};

export const showCloudpayments = (publicId, leadId, amount, isEn, from_url = false) => {
  var widget = new cp.CloudPayments({
    language: "ru-RU",
  });

  let email = "";
  try {
    email = emailField.value;
  } catch {
    console.warn("no email field found");
  }

  widget.pay(
    "charge",
    {
      //options
      publicId: publicId, //id из личного кабинета
      description: "Оплата товаров в o-complex.com", //назначение
      amount: amount, // сумма
      currency: isEn ? "USD" : "RUB", //валюта
      accountId: email, //идентификатор плательщика (необязательно)
      invoiceId: `${leadId}`, //номер заказа  (необязательно)
      skin: "mini", //дизайн виджета (необязательно)
      autoClose: 5, //время в секундах до авто-закрытия виджета (необязательный)
      configuration: {
        common: {
          successRedirectUrl: "https://o-complex.com/thanks.html", // адреса для перенаправления
          failRedirectUrl: "https://o-complex.com/error.html", // при оплате по Tinkoff Pay
        },
      },
      data: {
        from_url: from_url,
      },
    },
    {
      onSuccess: function (options) {
        // success
        window.location.assign("https://o-complex.com/thanks.html");
      },
      onFail: function (reason, options) {
        // fail
        console.error("PAYMENT START FAILED", reason, options);
        // window.location.assign("https://o-complex.com/error.html")
      },
      onComplete: function (paymentResult, options) {
        //Вызывается как только виджет получает от api.cloudpayments ответ с результатом транзакции.
        console.log("PAYMENT COMPLETE", paymentResult, options);
      },
    },
  );
};

export const findParent = (el, q) => {
  while (1) {
    el = el.parentElement;
    if (el) {
      if (el.matches(q)) {
        return el;
      }
    } else {
      break;
    }
  }
  return null;
};

export const reportError = (err) => {
  return new Promise((res, rej) => {
    console.error(err);
    let errText;
    try {
      errText = JSON.stringify(err);
    } catch {
      errText = err;
    }
    api("post", "/log-error", {
      text: `${window.location.pathname}: ${errText}`,
      cookie: decodeURIComponent(document.cookie),
    }).then((d) => {
      res(d);
    });
  });
};
