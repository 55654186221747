import Config from "./modules/Config";
import { createTreshhold, getGSessionIds, getCookie, getUuid } from "./Utils";

const getOrderData = () => {
  const names = document.querySelector("#name").value.split(" ");
  return {
    uuid: getUuid(),
    country: "Россия",
    email: document.querySelector("#email").value,
    phone: document.querySelector("#phone").value,
    first_name: names[0],
    last_name: names.slice(1).join(" "),
    address: document.querySelector("#address").value,
    vk_url: document.querySelector("#vk_url")?.value,
    cart: [
      { id: 68, quantity: 1 },
      { id: 66, quantity: 1 },
      { id: 62, quantity: 1 },
    ],
    utm_source: getCookie("utm_source"),
    utm_medium: getCookie("utm_medium"),
    utm_campaign: getCookie("utm_campaign"),
    utm_content: getCookie("utm_content"),
    utm_term: getCookie("utm_term"),
    gClientId: getCookie("gClientId"),
    gSessionId: getGSessionIds(),
    yandex_id: getCookie("_ym_uid"),
    ref_id: getCookie("ref") || null,
    from_source: "onlinecourse",
    zero_delivery_price: true,
  };
};

const setInputError = (inputId, text) => {
  const err = document.querySelector(`.input-error[data-for="${inputId}"]`);
  err.classList.remove("hidden");
  err.textContent = text;
};

const clearErrors = () => {
  document.querySelectorAll(".input-error").forEach((e) => {
    e.textContent = "";
    e.classList.add("hidden");
  });
};

const preOrder = () => {
  const data = getOrderData();
  if (!data.phone) return;

  fetch(Config.apiUrl + "/pre-order", {
    method: "PUT",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

const order = () => {
  clearErrors();
  var data = getOrderData();
  if (!data.first_name) {
    setInputError("name", "Пожалуйста, введите имя");
    return;
  }
  if (!data.phone || data.phone.endsWith("_")) {
    setInputError("phone", "Введен некорректный номер телефона");
    return;
  }
  if (!data.email) {
    setInputError("email", "Пожалуйста, введите email");
    return;
  }
  if (!data.address) {
    setInputError("address", "Пожалуйста, введите адрес");
    return;
  }
  if (!data.vk_url) {
    setInputError("vk_url", "Пожалуйста, укажите страницу VK");
    return;
  }

  fetch(Config.apiUrl + "/online-course-order", {
    method: "PUT",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((r) => r.json())
    .then((d) => {
      if (d.detail) {
        setInputError("address", d.detaul);
      } else {
        window.location.assign(d.pay_url);
      }
    });
};

$(document).ready(function () {
  // document.querySelectorAll(".header_flex .menu_in a").forEach((e, i) => {
  // e.addEventListener("click", (event) => {
  //   event.preventDefault();
  //   if (i == 0) {
  //     $(".block_1").get(0).scrollIntoView({ behavior: "smooth" });
  //   } else if (i == 1) {
  //     $(".block_2").get(0).scrollIntoView({ behavior: "smooth" });
  //   } else if (i == 2) {
  //     $(".block_3").get(0).scrollIntoView({ behavior: "smooth" });
  //   } else if (i == 3) {
  //     $(".block_7").get(0).scrollIntoView({ behavior: "smooth" });
  //   }
  // });
  // });

  $("#phone").inputmask("+7 (999) 999-99-99");

  document.querySelector("#order-btn").addEventListener("click", (e) => {
    e.preventDefault();
    order();
  });

  document.querySelector("#name").addEventListener("input", createTreshhold(preOrder, 1000));
  document.querySelector("#phone").addEventListener("input", createTreshhold(preOrder, 1000));
  document.querySelector("#email").addEventListener("input", createTreshhold(preOrder, 1000));
  document.querySelector("#address").addEventListener("input", createTreshhold(preOrder, 1000));
  document.querySelector("#vk_url").addEventListener("input", createTreshhold(preOrder, 1000));

  $("#menu-btn").click(function () {
    $(".menu").slideToggle(300);
    if ($(this).hasClass("not-active")) {
      $(this).addClass("is-active").removeClass("not-active");
    } else {
      setTimeout(function () {
        $(".is-active").addClass("not-active").removeClass("is-active");
      }, 250);
    }
  });

  /*===================*/
  $(window).scroll(function () {
    if ($(this).scrollTop() > 3) {
      $("header").addClass("header");
    } else {
      $("header").removeClass("header");
    }
  });

  /*===================*/
  function myFunction() {
    // TMP FOR SLIM FIT COURSE
    return;

    if ($(window).width() < 700) {
      $(".block_1_flex").addClass("block_1_flex_slider");
    } else {
      $(".block_1_flex").removeClass("block_1_flex_slider");
    }
  }
  myFunction();
  $(window).resize(function () {
    myFunction();
  });

  /*===================*/
  $(".block_1_flex_slider").slick({
    dots: true,
    infinite: true,
    speed: 300,
    autoplay: false,
    arrows: false,
    slidesToShow: 1,
  });
  /*===================*/
  $(".block_3_flex_left_inner_top").click(function () {
    $(this).toggleClass("block_3_flex_left_inner_top_show").next().slideToggle("fast");
    $(".block_3_flex_left_inner_top")
      .not(this)
      .removeClass("block_3_flex_left_inner_top_show")
      .next()
      .slideUp("fast");
  });
  /*===================*/
  $(".read_more").click(function () {
    var btn = $(this);
    $(this)
      .parent()
      .find(".block_6_flex_in_txt_show")
      .slideToggle(300, function () {
        if ($(this).is(":hidden")) {
          $(btn).html("Полностью →");
        } else {
          $(btn).html("скрыть");
        }
      });
    return false;
  });
  /*===================*/
  $(".btn_show_block").click(function () {
    var btn = $(this);
    $(this)
      .parent()
      .find(".block_6_flex_show")
      .slideToggle(300, function () {
        if ($(this).is(":hidden")) {
          $(btn).html("Показать ещё");
        } else {
          $(btn).html("скрыть");
        }
      });
    return false;
  });
  /*===================*/
  $("input,textarea").focus(function () {
    $(this).data("placeholder", $(this).attr("placeholder"));
    $(this).attr("placeholder", "");
  });
  $("input,textarea").blur(function () {
    $(this).attr("placeholder", $(this).data("placeholder"));
  });
  /*==============*/
}); /*$(document).ready(function()*/
