import {api, getCookie, getUuid} from "./Utils"

(() => {
const setCookie = (name, value) => {
    value = encodeURIComponent(value)
    const days = 365
    var expires = ""
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/"
}

const deleteCookie = (name) => {
    document.cookie = name + '=';
}

const setUtm = (params, name) => {
    let value = params.get(name)
    if (!value)
	value = sessionStorage.getItem(name)
    if (value) {
	setCookie(name, value)
	sessionStorage.setItem(name, value)
    } else {
	setCookie(name, "")
    }
}

const timeOnSite = () => {
  setInterval(() => {
    sessionStorage.timeOnSite = sessionStorage.timeOnSite ? parseInt(sessionStorage.timeOnSite) + 1 : 1
    if (sessionStorage.timeOnSite == 180) {  // 3min
      console.log('3min')
      ym(57109408,'reachGoal','3min')
    }
    if (sessionStorage.timeOnSite == 120) {  // 2min
      console.log('2min')
      ym(57109408, 'reachGoal', '2min')
    }
  }, 1000)
}
timeOnSite()

const params = new URLSearchParams(window.location.search)
setUtm(params, 'utm_source')
setUtm(params, 'utm_medium')
setUtm(params, 'utm_campaign')
setUtm(params, 'utm_content')
setUtm(params, 'utm_term')

if (params.get('ref') && params.get('ref') != getCookie('ref')) {
    setCookie('ref', params.get('ref'))
    api('post', '/ref-info-by-yandex-id', {
        yid: getCookie('_ym_uid'),
        ref: getCookie('ref'),
    }).then((d) => {console.log('save-ref:', d)})
}

const promo = params.get('promo')
if (promo) {
  setCookie('promo', promo)
}

try {
  const gClientId = (() => {
    var match = document.cookie.match('(?:^|;)\\s*_ga=([^;]*)');
    var raw = (match) ? decodeURIComponent(match[1]) : null;
    if (raw) {
      match = raw.match(/(\d+\.\d+)$/);
    }
    var gacid = (match) ? match[1] : null;
    if (gacid) {
      return gacid;
    }
  })()
  setCookie('gClientId', gClientId)
} catch (e) {
    console.log(e)
}

try {
    api('get', `/user-info?yandex_uid=${getCookie('_ym_uid')}&uuid=${getUuid()}`).then((d) => {
        if (!d.logined) {
            ym(57109408,'reachGoal','have_lk')
        }
    })
} catch (e) {
    console.log(e)
}
})()
