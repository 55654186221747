import Config from "./modules/Config"
import {makeElement} from "./Utils"

(() => {
    const urlParams = new URLSearchParams(window.location.search)
    var category = urlParams.get('category')
    const apiHost = Config.apiUrl
    fetch(apiHost + '/categorized-reviews')
	.then(r => r.json())
	.then(d => {
	    document.querySelector('link[href="/css/index.css"]').remove()
        console.log(d)
        if (category) {
            const c = d.categories.find(e => e.id == category)
            const reviewsSlider = document.querySelector('.reviews_slider')
            const rname = document.querySelector('.reviews_name')
            rname.innerText = c.name
            reviewsSlider.innerHTML = ''

            for (const r of c.reviews) {
              reviewsSlider.appendChild(makeElement(`
                <div class="reviews_slider_inner">
                  <div class="reviews_slider_inner_in">
                    <img loading="lazy" data-src="${r.image}" alt="">
                  </div>
                </div>
              `))
            }

            setTimeout(() => {
            $('.reviews_slider').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
		autoplay:false,
		dots: false,
		autoplaySpeed:2000
            })}, 150)
        } else {
            const reviews = document.querySelector('.reviews_link_flex')
            reviews.innerHTML = ''
            d.categories.forEach(c => {
                reviews.appendChild(makeElement(`
                  <a href="/reviews_inner.html?category=${c.id}" class="reviews_link_flex_in">
                    <span class="reviews_link_flex_in_inner">
                      <b>${c.name}</b>
                      <span>${c.description || ''}</span>
                    </span>
                  </a>
                `))
            })

        }
	})
})()
