import {makeElement, api, getCookie, getUuid} from "./Utils"

const addEmailFieldInFooter = () => {
    const callBtn = document.querySelector('footer .phone-call-button')
    if (!callBtn) {
        return
    }

    callBtn.after(makeElement(`
      <div class="footer-email-field-container">
        <div class="message-field">Подписаться на рассылку</div>
        <div class="field">
          <input id="footer-email-field" type="email" placeholder="E-mail">
          <a id="footer-email-field-btn" class="send-btn"><img id="footer-email-send-button-img" src="/static/img/send_bt.svg"></a>
        </div>
      </div>
    `))

    const emailField = document.querySelector('#footer-email-field')
    const sendButtonImage = document.querySelector('#footer-email-send-button-img')
    let notSendYet = false

    document.querySelector('#footer-email-field-btn').addEventListener('click', () => {
        if (notSendYet) {
            return
        }
        notSendYet = true

        sendButtonImage.src = '/static/img/loading.gif'
        if (emailField.value) {
            emailField.classList.remove('red-border')
        } else {
            emailField.classList.add('red-border')
            sendButtonImage.src = '/static/img/send_bt.svg'
            notSendYet = false
            return
        }

        api('get', `/add-email-to-unisender-list?email=${emailField.value}&list_id=20406063`).then((d) => {
            if (d.detail) {
                emailField.classList.add('red-border')
                sendButtonImage.src = '/static/img/send_bt.svg'
                notSendYet = false
                return
            } else {
                sendButtonImage.src = '/static/img/check_ic.svg'
            }
        })

    })
    emailField.addEventListener('keyup', () => {
        if (event.keyCode === 13) {
            document.querySelector('#footer-email-field-btn').dispatchEvent(new Event('click'))
        }
    })
}

const closePopupForever = (popup) => {
    api('post', '/registration-popup-was-shown', {
        yandex_uid: getCookie('_ym_uid'),
    }).then(() => {})

    popup.classList.add('invisible')
    setTimeout(() => {
        popup.remove()
    }, 500)
}

const initPupup = () => {
  document.body.appendChild(makeElement(`
      <div class="registration-popup invisible" id="registration-popup">
        <a id="registration-popup-close-button" class="close-btn"></a>
        <div class="registration-popup-content">
          <div class="text">
            <p>Вам всегда так везет? 😍 Дарим 3000 бонусов!</p>
            <p>Оставьте ваш email и мы начислим бонусы в подарок за регистрацию в личном кабинете.</p>
          </div>
          <div class="fields">
            <div id="registration-popup-message" class="message-field"></div>
            <div class="field">
              <input id="registration-popup-email" type="email" placeholder="E-mail">
              <a id="registration-popup-send-button" class="send-btn"><img id="registration-popup-send-button-img" src="/static/img/send_bt.svg"></a>
            </div>
            <div class="field">
              <input id="registration-popup-cfi" type="checkbox" checked>
              <label for="registration-popup-cfi">Я согласен с "<a style="cursor:pointer; text-decoration:underline; color:#000;" href="/private_policy.pdf" target="_blank">политикой конфиденциальности</a>".</label>
            </div>
          </div>
        </div>
      </div>
    `))

    let popup = document.querySelector('#registration-popup')
    let emailField = document.querySelector('#registration-popup-email')
    let cfiField = document.querySelector('#registration-popup-cfi')
    let messageField = document.querySelector('#registration-popup-message')
    let sendButtonImage = document.querySelector('#registration-popup-send-button-img')
    let nowSending = false

    setTimeout(() => {
        popup.classList.remove('invisible')
    }, 100)

    document.querySelector('#registration-popup-close-button').addEventListener('click', () => {
        closePopupForever(popup)
    })

    document.querySelector('#registration-popup-send-button').addEventListener('click', () => {
        const setMessage = (text, msgType) => {
            messageField.textContent = text
            messageField.classList.remove('green')
            messageField.classList.remove('red')
            if (msgType == 'success') {
                nowSending = false
                sendButtonImage.src = '/static/img/send_bt.svg'
                messageField.classList.add('green')
            } else if (msgType == 'error') {
                nowSending = false
                sendButtonImage.src = '/static/img/send_bt.svg'
                messageField.classList.add('red')
            }
        }

        if (nowSending) {
            return
        }
        nowSending = true
        sendButtonImage.src = '/static/img/loading.gif'

        setMessage('', null)

        if (!emailField.value) {
            setMessage('Введите E-mail', 'error')
            return
        }

        if (!cfiField.checked) {
            setMessage('Для продолжения необходимо согласиться с политикой конфиденциальности', 'error')
            return
        }

        api('post', '/register', {
            uuid: getUuid(),
            email: emailField.value,
            send_promo_letters: true,
            source: 'popup',
            yandex_id: getCookie('_ym_uid'),
        }).then((d) => {
            if (d.detail) {
                setMessage(d.detail, 'error')
            } else {
                setMessage('Вы успешно зарегистрированы. Проверьте вашу почту', 'success')
                setTimeout(() => {
                    closePopupForever(popup)
                }, 5000)
                ym(57109408,'reachGoal','register_lk')
            }
        })
        // TODO: handle success
    })
    emailField.addEventListener('keyup', () => {
        if (event.keyCode === 13) {
            document.querySelector('#registration-popup-send-button').dispatchEvent(new Event('click'))
        }
    })
}


addEmailFieldInFooter()
api('get', `/should-show-registration-popup?yandex_uid=${getCookie('_ym_uid')}&uuid=${getUuid()}`).then((d) => {
    if (d.show) {
        initPupup()
    }
})
