import Config from "./modules/Config"


const cityZoom = 10
const streetZoom = 15

var map = null
var pvzs = []
var mapReady = false
var pvzsUpdated = false
var selectedPvz = null
var notReloadedAfterPvzSelection = false
var pvzGeoobjects = null


const reloadPvzs = (nearest=null, lat=null, lon=null) => {
	console.log('reloadPvzs')
	if (nearest == []) {
		nearest = null
	}
	const search = document.querySelector('#pvz-search')
	map.geoObjects.removeAll()
	pvzGeoobjects = new ymaps.GeoObjectCollection()
	let nearestPvzsCol = new ymaps.GeoObjectCollection()
	let selectedMarker

	for (const pvz of pvzs) {
		const selected = search.value == pvz.code || selectedPvz && pvz.id == selectedPvz.id
		const marker = new ymaps.Placemark([pvz.latitude, pvz.longitude], {
			balloonContent: selected ? 'Выбрано✅' : `Адрес: ${pvz.address}<br>Часы работы: ${pvz.worktime}<br>Тип: ${pvz.type == 'PVZ' ? 'Пункт выдачи' : 'Постамат'}<br><input id="select-pvz-${pvz.id}" class="select-pvz-btn" type="button" value="Выбрать этот ПВЗ" onclick="try {let e} catch {}; e=new Event('pvz-preselect');e.pvz_id=${pvz.id};document.dispatchEvent(e)">`,
		}, {
			iconLayout: 'default#image',
			iconImageHref: selected ? '/static/img/selected_pvz.svg' : pvz.type == 'PVZ' ? '/static/img/pvz.svg' : '/static/img/postamat.svg',
			iconImageSize: [28, 36],
			iconImageOffset: [-14, -36],
		})

		if (selected)
			selectedMarker = marker

		if (nearest && nearest.find(x => x.id == pvz.id)) {
			nearestPvzsCol.add(marker)
		} else {
			pvzGeoobjects.add(marker)
		}
	}
	map.geoObjects.add(pvzGeoobjects)
	map.geoObjects.add(nearestPvzsCol)
	console.log(pvzGeoobjects)
	if (notReloadedAfterPvzSelection && selectedPvz) {
		map.setCenter([selectedPvz.latitude, selectedPvz.longitude], streetZoom)
	} else if (nearest && nearestPvzsCol.getLength()) {
		map.setBounds(nearestPvzsCol.getBounds(), {
			checkZoomRange: true,
			zoomMargin: 10
		})
	} else if (pvzGeoobjects.getLength()) {
		map.setBounds(pvzGeoobjects.getBounds(), {
			checkZoomRange: true,
			zoomMargin: 10,
		})
	}

	if (selectedMarker) {
		selectedMarker.balloon.open()
	}
}

const getMapCenter = () => pvzs.length ? [
	pvzs.map(x => x.latitude).reduce((s, x) => s + x) / pvzs.length,
	pvzs.map(x => x.longitude).reduce((s, x) => s + x) / pvzs.length,
] : [0, 0]

const getMapZoom = () => cityZoom

document.addEventListener('pvz-preselect', (ev) => {
	const e = new Event('pvz-preselected')
	for (const pvz of pvzs) {
		if (pvz.id == ev.pvz_id) {
			e.pvz = pvz
			dispatchEvent(e)
			break
		}
	}
})

addEventListener('nearest-pvzs-updated', (e) => {
	console.log(`pvzs.length = ${pvzs.length}; e.data = ${JSON.stringify(e.data)}`)
	if (pvzs.length < 3) {
		const event = new Event('cdek-pvzs-updated')
		event.pvzs = e.data
		dispatchEvent(event)
	}
	reloadPvzs(e.data, e.lat, e.lon)
})

addEventListener('pvz-map-search', (e) => {
	ymaps.geocode(e.q, {
		results: 1
	}).then(function (res) {
		var firstGeoObject = res.geoObjects.get(0);
		var bounds = firstGeoObject.properties.get('boundedBy');

		// Zoom to the bounding box of the geocoded street
		map.setBounds(bounds, {
			checkZoomRange: true,
			zoomMargin: 13
		});
	});
})


addEventListener('pvz-selected', (e) => {
	selectedPvz = e.pvz
	notReloadedAfterPvzSelection = true
	let interval
	interval = setInterval(() => {
		if (map) {
			setTimeout(reloadPvzs, 100)
			clearInterval(interval)
		}
	}, 200)
})


addEventListener('zoom-pvz-map', (e) => {
	map.setCenter([e.lat, e.lon], streetZoom)
})


function init() {
	mapReady = true
}
ymaps.ready(init)

addEventListener('cdek-pvzs-updated', (e) => {
	pvzs = e.pvzs
	pvzsUpdated = true
})

setInterval(() => {
	if (pvzsUpdated && mapReady && !map) {
		document.querySelectorAll('#map').forEach(e => e.innerHTML = '')
		try {
			map = new ymaps.Map('map', {
				center: getMapCenter(),
				zoom: getMapZoom(),
			})

			map.controls.remove(map.controls.get('trafficControl'));
			map.controls.remove(map.controls.get('searchControl'));
		} catch (e) {console.error(e)}
	}
	if (!pvzsUpdated || !map || document.querySelector('#pvz2').classList.contains('hidden')) {
		return
	}

	pvzsUpdated = false
	reloadPvzs()
	map.setCenter(getMapCenter(), getMapZoom())
}, 250)
