import {makeElement, findParent, showPopup, getDefaultPreorderData, clearErrors, showError, showErrorEl, api, updatePhoneMask, getGSessionIds, getCookie, getCountry, getUuid, isPage, checkInput} from "./Utils"
import Config from "./modules/Config"

const submitForm = (form, act) => {
	let btn = form.querySelector('#realsubmit[type="submit"]')
	if (btn) {
		btn.remove()
	}

	btn = makeElement(`<input data-act="${act}" id="realsubmit" class="hidden" type="submit">`)
	form.appendChild(btn)
	btn.click()
}

(() => {
    const popup = document.createElement('div')
    popup.setAttribute('id', 'preorder-popup')
    popup.classList.add('preorder-popup')
    popup.innerHTML = `
                       <h1>Перезвоните мне</h1>
                       <p>Заполните форму и наш оператор свяжется с вами, чтобы проконсультировать вас по возникшим вопросам</p>
                       <div class="form">
                         <div class="b-checkout__inputs-group-item">
                             <input id="name" name="name" type="text" class="js-checkout__input b-checkout__input">
                             <label for="name" class="b-checkout__input-label">Имя</label>
                         </div>
                         <div class="b-checkout__inputs-group-item">
                             <input id="phone" name="phone" type="text" class="js-checkout__input b-checkout__input">
                             <label for="phone" class="b-checkout__input-label">Телефон</label>
                         </div>
                         <div class="btn_1 b-caption-with-button__btn send_preorder_btn" style="margin-top: 20px;">Отправить заявку</div>
                             <input id="preorder-popup-cfi" type="checkbox" checked="">
                             <label for="preorder-popup-cfi">Согласен с "<a style="cursor:pointer; text-decoration:underline; color:#000;" href="/private_policy.pdf" target="_blank">политикой конфиденциальности</a>".</label>
                       </div>
    `
    popup.style.display = 'none'
    document.body.append(popup)
    const nameField = popup.querySelector('#name')
    const phoneField = popup.querySelector('#phone')
    const cfiCheckbox = popup.querySelector('#preorder-popup-cfi')
    const country = getCountry()
    setInterval(updatePhoneMask, 1000)

    const popupSuccess = document.createElement('div')
    const popupSuccessInner = document.createElement('div')
    popupSuccess.appendChild(popupSuccessInner)
    const openSuccessPopup = () => {
		popupSuccessInner.innerHTML = `<img src="/static/img/loading.gif" style="transform: translate(-50%, 0); position: relative; left: 50%; top: 50%;">`
		$.fancybox.open(popupSuccess)
    }
    popupSuccess.style.display = 'none'
    document.body.append(popupSuccess)

    const sendData = (data) => {
		openSuccessPopup()
		if (data.names) {
			const names = data.names.split(' ')
			data.first_name = names[0]
			data.last_name = names.slice(1).join(' ')
			delete data.names
		}
		const send_data = {
			uuid: getUuid(),
			country: getCountry(),
			utm_source: getCookie('utm_source'),
			utm_medium: getCookie('utm_medium'),
			utm_campaign: getCookie('utm_campaign'),
			utm_content: getCookie('utm_content'),
			utm_term: getCookie('utm_term'),
			gClientId: getCookie('gClientId'),
			gSessionId: getGSessionIds(),
			yandex_id: getCookie('_ym_uid'),
		}
		fetch(Config.apiUrl + '/pre-order', {
			method: 'PUT',
			headers: {
				"content-type": "application/json"
			},
			body: JSON.stringify({...send_data, ...data}),
		}).then(() => {
			window["rrApiOnReady"] = window["rrApiOnReady"] || []
			window["rrApiOnReady"].push(function() {
				rrApi.setProfile({
					"phone": '+' + data.phone.match(/[0-9]{0,14}/g).join(''),
					"isAgreedToReceiveMarketingMail": true
				})
			})
			popupSuccessInner.innerHTML = '<h3>Наши менеджеры уже подкидывают монетку, чтобы узнать, кому повезёт с вами пообщаться ☺️ Счастливчик свяжется с вами в ближайшее время</h3>'
		})
    }

    const check = (inp) => {
		if (!inp.value || inp.value.endsWith("_") || inp.value.endsWith("-")) {
			inp.classList.add("_danger")
			return false
		}
		inp.classList.remove("_danger")
		return true
    }

    const btn = popup.querySelector('.send_preorder_btn')
    btn.addEventListener('click', () => {
        cfiCheckbox.style.borderColor = 'black'
        phoneField.style.borderColor = 'rgba(197, 197, 197, 0.4)'
        nameField.style.borderColor = 'rgba(197, 197, 197, 0.4)'
        if (!cfiCheckbox.checked) {
            cfiCheckbox.style.borderColor = 'red'
            return
        }
        if (!phoneField.value) {
            phoneField.style.borderColor = 'red'
            return
        }
        if (!nameField.value) {
            nameField.style.borderColor = 'red'
            return
        }


		$.fancybox.close(popup)
		sendData({
			names: nameField.value,
            phone: phoneField.value,
			from_source: 'write_your_problem',
		})
    })


    if (isPage(['/', '/index.html'])) {
		const simptoms_names = document.querySelector('#simptoms_names')
		const simptoms_phone = document.querySelector('#simptoms_phone')
		const simptoms_button = document.querySelector('#simptoms_btn')
		const simptoms = []
		for (let i=1; i<9; i++) {
			simptoms.push(document.querySelector(`#check_simpt${i}`))
		}
		simptoms_button.href = 'javascript:void(0)'
		simptoms_button.addEventListener('click', () => {
			const simptoms_div = document.querySelector('.block_simptoms_in_check')
			simptoms_div.classList.remove('block_simptoms_in_check_danger')
			const simptoms_value = simptoms.map(e => e.checked ? e.labels[0].textContent : '').filter(x => x).join('\n')
			if (!check(simptoms_names) || !check(simptoms_phone))
				return
			if (!simptoms_value) {
				simptoms_div.classList.add('block_simptoms_in_check_danger')
				return
			}

			sendData({
				names: simptoms_names.value,
				phone: simptoms_phone.value,
				simptoms: simptoms_value,
				from_source: 'write_your_problem',
			})
		})
    }

    if (isPage(['/landing_problems.html', '/index.html', '/', '/zeolite.html', '/z-celd.html'])) {
		const o_doctor_names = document.querySelector('#o_doctor_names')
		const o_doctor_phone = document.querySelector('#o_doctor_phone')
		const o_doctor_message = document.querySelector('#o_doctor_message')
		const o_doctor_button = document.querySelector('#o_doctor_btn')
		o_doctor_button.href = 'javascript:void(0)'
		console.log(o_doctor_button)
		o_doctor_button.addEventListener('click', () => {
			if (!check(o_doctor_names) || !check(o_doctor_phone) || !check(o_doctor_message))
				return
			sendData({
				names: o_doctor_names.value,
				phone: o_doctor_phone.value,
				comment: o_doctor_message.value,
				from_source: 'write_your_problem',
			})
		})
    }

	document.querySelectorAll('.promotion-cms-popup-content').forEach((form) => {
		form.addEventListener('submit', (e) => {
			e.preventDefault()

			const popupId = e.target.getAttribute('data-popup-id')

			clearErrors()
			let isErrors = false
			form.querySelectorAll('input').forEach((inp) => {
				if (inp.type == 'text') {
					if (!inp.value) {
						showErrorEl(inp, "заполните поле ^")
						isErrors = true
					}
				}
			})

			if (isErrors) {
				return
			}

			if (e.submitter.dataset.act == 'submit') {
				let data = getDefaultPreorderData()
				const inputs = document.querySelectorAll(`input[data-preorder-from-cms-popup-id="${popupId}"]`)
				for (const inp of inputs) {
					data[inp.getAttribute('name')] = inp.value
				}

				const setupPopup = showPopup()
				api('PUT', '/pre-order', data).then(() => {
					window["rrApiOnReady"] = window["rrApiOnReady"] || []
					window["rrApiOnReady"].push(function() {
						rrApi.setProfile({
							"phone": '+' + data.phone.match(/[0-9]{0,14}/g).join(''),
							"isAgreedToReceiveMarketingMail": true
						})
					})
					setupPopup('Успех', 'Наши менеджеры уже подкидывают монетку, чтобы узнать, кому повезёт с вами пообщаться ☺️ Счастливчик свяжется с вами в ближайшее время')
				})

			} else if (e.submitter.dataset.act == 'unisender-add') {
				const inputs = Array.from(form.querySelectorAll('input'))
				const emails = inputs.filter(x => x.name.toLowerCase() == 'email')
				const email = emails[0]
				const radios = inputs.filter(x => (x.name.toLowerCase() == 'unisender-list-m' || x.name.toLowerCase() == 'unisender-list-d') && x.checked)
				const selectedRadio = radios[0]

				const setupPopup = showPopup()
				api('get', `/add-email-to-unisender-list?email=${email.value}&list_id=${selectedRadio.value}`).then((d) => {
					setupPopup('Успех', 'Вы успешно подписались на рассылку.')
					console.log(d)
				})
			} else {
				console.error('unknown submit act', e)
			}

			return
		})
	})

    document.querySelectorAll('a[data-submit]').forEach((btn) => {
        btn.addEventListener('click', () => {
			submitForm(findParent(btn, 'form'),  'submit')
        })
    })

    document.querySelectorAll('a[data-unisender-add]').forEach((btn) => {
        btn.addEventListener('click', () => {
			submitForm(findParent(btn, 'form'), 'unisender-add')
        })
    })

    document.querySelectorAll('a[data-close-popup]').forEach((btn) => {
        btn.addEventListener('click', () => {
            $.fancybox.close()
        })
    })

	document.querySelectorAll('.promotion-cms-popup-content input[type="radio"]').forEach((el) => {
		el.addEventListener('click', (e) => {
			e.stopPropagation()
		})

		el.parentElement.style.cursor = 'pointer'
		el.parentElement.addEventListener('click', (e) => {
			e.stopPropagation()
			el.click()
		})
	})

	document.querySelectorAll('.promotion-cms-popup-content input[type="checkbox"]').forEach((el) => {
		el.addEventListener('click', (e) => {
			e.stopPropagation()
		})

		el.parentElement.style.cursor = 'pointer'
		el.parentElement.addEventListener('click', (e) => {
			el.click()
		})
	})

})()
