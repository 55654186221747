import Config from "./modules/Config";
import {makeElement} from "./Utils";

document.addEventListener('DOMContentLoaded', function () {
    console.log('articles')
    fetch(Config.apiUrl + '/articles')
        .then(r => r.json())
        .then((d) => {
            const container = document.querySelector('.b-cards-grid')
            d.forEach((article) => {
                container.appendChild(makeElement(`<div class="b-cards-grid__item">
                    <a href="#sovp_article_${article.id}" class="js-sovp-toggle b-preview-card">
                        <div class="b-preview-card__wrpic b-preview-card__wrpic--ar-2-1">
                            <img loading="lazy" class="b-preview-card__pic" data-src="${article.image_url}">
                        </div>
                        <div class="b-preview-card__body">
                            <div class="b-preview-card__caption">${article.title}</div>
                            <div><span class="b-preview-card__link">Подробнее →</span>
                            </div>
                        </div>
                    </a>
                </div>`))
                document.body.appendChild(makeElement(`<div id="sovp_article_${article.id}" class="js-side-ov-panel b-side-ov-panel" data-sovp-id="333${article.id}">
                    <div class="js-side-ov-panel__body b-side-ov-panel__body">
                        <div class="b-side-ov-panel__header">
                            <button class="js-side-ov-panel__close b-side-ov-panel__close">
                                <span class="b-side-ov-panel__close-txt">Закрыть</span>
            
                                <svg class="b-side-ov-panel__close-icon b-side-ov-panel__close-icon--m" width="30" height="30">
                                    <use xlink:href="#close-circle-m-2"></use>
                                </svg>
            
            
                                <svg class="b-side-ov-panel__close-icon b-side-ov-panel__close-icon--d" width="30" height="30">
                                    <use xlink:href="#close-circle-d-5"></use>
                                </svg>
            
                            </button>
                        </div>
                        <div class="b-side-ov-panel__body-inner">${article.text}</div>
                    </div>
                </div>`))
            });
            dispatchEvent(new Event('activate-sovp'))
        })
})
