import Config from "./modules/Config"
import {getGSessionIds} from "./Utils"


const getCookie = (name) => {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2)
        return decodeURIComponent(parts.pop().split(';').shift())
    return ''
}


const setCookie = (name, value) => {
    value = encodeURIComponent(value)
    const days = 365
    var expires = ""
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/"
}


const getUuid = () => {
    var uuid = getCookie('uuid')
    if (uuid)
        return uuid

    function uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }
    uuid = uuidv4()
    setCookie('uuid', uuid)
    return uuid
}

const getCountry = () => {
    var country = getCookie('country')
    if (country) {
        return country
    }
    return 'Россия'
}

const btn = document.querySelector('#send_btn')
btn.addEventListener('click', () => {
    let phone = document.querySelector('#phone')
    let email = document.querySelector('#email')
    if (!$(phone).val()) {
	phone.classList.add('_danger')
	return
    }
    if (!$(email).val()) {
	email.classList.add('_danger')
	return
    }
    btn.innerHTML = `<img src="/static/img/loading.gif" style="height: 100%;width: auto;">`
    const data = {
	uuid: getUuid(),
	country: getCountry(),
        utm_source: getCookie('utm_source'),
        utm_medium: getCookie('utm_medium'),
        utm_campaign: getCookie('utm_campaign'),
        utm_content: getCookie('utm_content'),
        utm_term: getCookie('utm_term'),
        gClientId: getCookie('gClientId'),
        gSessionId: getGSessionIds(),
        yandex_id: getCookie('_ym_uid'),
	first_name: document.querySelector('#name').value,
	phone: document.querySelector('#phone').value,
	email: document.querySelector('#email').value,
	
    }
    fetch(Config.apiUrl + '/cooperation', {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify(data),
    }).then(r => {
	btn.innerHTML = 'Отправлено'
	btn.style.background = 'linear-gradient(90deg, #a0fd85 0%, #65f6d3 100%)'
	document.querySelector('#name').value = ''
	document.querySelector('#phone').value = ''
	document.querySelector('#email').value = ''
	phone.classList.remove('_danger')
	email.classList.remove('_danger')
    })
})
